import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import { getHPI, updateHPI } from '@/services'
import { type HistoryOfPresentIllness } from '@/types/HistoryOfPresentIllness'

interface HPIState {
  hpi: HistoryOfPresentIllness[] | [];
  setHPI: (hpi: HistoryOfPresentIllness[]) => void;
}

export const useHPIStore = create<HPIState>()(
  devtools((set) => ({
    hpi: [],
    setHPI: (hpi: HistoryOfPresentIllness[]) => set({ hpi }),
  }))
)

export const useQueryGetHPI = (
  vId: number,
  getToken: any
): QueryObserverResult<HistoryOfPresentIllness[], unknown> => {
  const { setHPI } = useHPIStore()

  return useQuery<HistoryOfPresentIllness[]>(
    ['hpi', vId],
    async () => await getHPI(vId, getToken),
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setHPI(data)
      },
      initialData: [],
    }
  )
}

export const useMutateUpdateHPI = (
  getToken: any
): UseMutationResult<
  any,
  unknown,
  { vId: number; updatedHPI: HistoryOfPresentIllness[] }
> => {
  return useMutation<
    any,
    unknown,
    { vId: number; updatedHPI: HistoryOfPresentIllness[] }
  >(async ({ vId, updatedHPI }) => await updateHPI(vId, updatedHPI, getToken), {
    onSuccess: (data) => {
      if (data.status === 200) {
        return data
      } else {
        throw data
      }
    },
  })
}
