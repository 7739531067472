import React from 'react'
import { type AccountUser } from '@/types'
import { isEmpty } from '@/utility'
import { Tooltip } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'

export const getProviderFullName = (
  providerId: number,
  clinicians: AccountUser[]
): string => {
  const provider = clinicians?.find((clinician) => clinician.id === providerId)
  if (isEmpty(provider)) {
    return '-'
  }
  return `${provider?.firstName!} ${provider?.lastName!}`
}

export const gotoProfile = (patientId: number): void => {
  if (isEmpty(patientId) || patientId === 0) return
  window.location.href = `/profile/${patientId}?previousNavOpen=true`
}

export const getStatusIcon = (status: string): JSX.Element => {
  switch (status) {
    case 'Completed':
      return (
        <Tooltip title="Locked" arrow>
          <LockOutlinedIcon />
        </Tooltip>
      )
    case 'Discharged':
      return (
        <Tooltip title="Unlocked" arrow>
          <LockOpenOutlinedIcon />
        </Tooltip>
      )
      case 'Cancelled':
      return (
        <Tooltip title="Unlocked" arrow>
          <LockOpenOutlinedIcon />
        </Tooltip>
      )
    default:
      return <></>
  }
}

export const getStatusString = (status: number): string => {
  switch (status) {
    case 0:
      return 'Pending'
    case 1:
      return 'Confirmed'
    case 2:
      return 'Booked'
    case 3:
      return 'Cancelled'
    case 4:
      return 'No Show'
    default:
      return 'Unknown'
  }
}

export const getAppointmentStatusColor = (status: number): string => {
  switch (status) {
    case 0:
      return '#E5EBFF'
    case 1:
      return '#D8EBDB'
    case 2:
      return '#E5EBFF'
    case 3:
      return 'rgba(237, 108, 2, 0.30)'
    case 4:
      return '#FBE7E7'
    default:
      return '#E5EBFF'
  }
}

export const visitStatus = [
  'Registering',
  'Ready For Triage',
  'Triage Started',
  'Triage Complete',
  'Exam Started',
  'Completed',
  'Pending Results',
  'Check Out',
  'Discharged',
  'Cancelled'
]

export const getVisitStatusString = (visitStatusId: number): string => {
  switch (visitStatusId) {
    case 1:
      return 'Registering'
    case 2:
      return 'Ready For Triage'
    case 3:
      return 'Triage Started'
    case 4:
      return 'Triage Complete'
    case 5:
      return 'Exam Started'
    case 6:
      return 'Completed'
    case 7:
      return 'Pending Results'
    case 8:
      return 'Check Out'
    case 9:
      return 'Discharged'
    case 10:
      return 'Cancelled'
    default:
      return 'Unknown'
  }
}

export const getVisitStatusColor = (status: string): string => {
  switch (status) {
    case 'Exam Started':
      return '#E5EBFF'
    case 'Ready For Triage':
      return '#D8EBDB'
    case 'Triage Started':
      return '#E5EBFF'
    case 'Pending Results':
      return '#FBE7E7'
    case 'Triage Complete':
      return '#E5EBFF'
    case 'Completed':
    case 'Discharged':
    case 'Cancelled':
      return '#E5EBFF'
    case 'Registering':
    case 'Check Out':
      return 'rgba(237, 108, 2, 0.30)'
    default:
      return '#E5EBFF'
  }
}
