import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getChartTemplate,
  getChartTemplates,
  addNewChartTemplate,
  deleteChartTemplate,
  updateChartTemplate,
} from '@/services'
import { type ChartTemplate } from '@/types/ChartTemplate'

interface ChartTemplateState {
  chartTemplate: ChartTemplate | null;
  chartTemplates: ChartTemplate[] | [];
  setChartTemplate: (chartTemplate: ChartTemplate) => void;
  setChartTemplates: (chartTemplates: ChartTemplate[]) => void;
}

export const useChartTemplateStore = create<ChartTemplateState>()(
  devtools((set) => ({
    chartTemplate: null,
    chartTemplates: [],
    setChartTemplate: (template: ChartTemplate) =>
      set({ chartTemplate: template }),
    setChartTemplates: (templates: ChartTemplate[]) =>
      set({ chartTemplates: templates }),
  }))
)

export const useQueryGetChartTemplate = (
  chartTemplateId: number,
  getToken: any
): QueryObserverResult<ChartTemplate, unknown> => {
  const { setChartTemplate } = useChartTemplateStore()

  return useQuery<ChartTemplate>(
    ['chart-template', chartTemplateId],
    async () => await getChartTemplate(chartTemplateId, getToken),
    {
      enabled: !!chartTemplateId,
      onSuccess: (data) => {
        setChartTemplate(data)
      },
    }
  )
}

export const useQueryGetChartTemplates = (
  getToken: any
): QueryObserverResult<ChartTemplate[], unknown> => {
  const { setChartTemplates } = useChartTemplateStore()

  return useQuery<ChartTemplate[]>(
    ['chart-templates'],
    async () => await getChartTemplates(getToken),
    {
      onSuccess: (data) => {
        setChartTemplates(data)
      },
      initialData: [],
    }
  )
}

export const useMutateAddChartTemplate = (
  getToken: any
): UseMutationResult<
  ChartTemplate,
  unknown,
  { chartTemplate: ChartTemplate }
> => {
  const { chartTemplates, setChartTemplates } = useChartTemplateStore()

  return useMutation<ChartTemplate, unknown, { chartTemplate: ChartTemplate }>(
    async ({ chartTemplate }) =>
      await addNewChartTemplate(chartTemplate, getToken),
    {
      onSuccess: (data) => {
        setChartTemplates([...(chartTemplates as ChartTemplate[]), data])
      },
    }
  )
}

export const useMutateDeleteChartTemplate = (
  getToken: any
): UseMutationResult<ChartTemplate, unknown, { chartTemplateId: number }> => {
  const { chartTemplates, setChartTemplates } = useChartTemplateStore()

  return useMutation<ChartTemplate, unknown, { chartTemplateId: number }>(
    async ({ chartTemplateId }) =>
      await deleteChartTemplate(chartTemplateId, getToken),
    {
      onSuccess: (data) => {
        const newChartTemplates = chartTemplates.filter(
          (chartTemplate: ChartTemplate): boolean =>
            chartTemplate?.id !== data?.id
        )
        setChartTemplates(newChartTemplates)
      },
    }
  )
}

export const useMutateUpdateChartTemplate = (
  getToken: any
): UseMutationResult<
  ChartTemplate,
  unknown,
  { chartTemplateId: number; chartTemplate: ChartTemplate }
> => {
  const { chartTemplates, setChartTemplates } = useChartTemplateStore()

  return useMutation<
    ChartTemplate,
    unknown,
    { chartTemplateId: number; chartTemplate: ChartTemplate }
  >(
    async ({ chartTemplateId, chartTemplate }) =>
      await updateChartTemplate(chartTemplateId, chartTemplate, getToken),
    {
      onSuccess: (data) => {
        const newChartTemplates = chartTemplates.map(
          (chartTemplate: ChartTemplate): ChartTemplate => {
            if (chartTemplate?.id === data?.id) {
              return data
            }
            return chartTemplate
          }
        )
        setChartTemplates(newChartTemplates)
      },
    }
  )
}
