import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import { getDoseSpotAlert } from '@/services'

interface DoseSpotAlertState {
  doseSpotAlert: any | null;
  setDoseSpotAlert: (alert: any) => void;
}

export const useDoseSpotAlertStore = create<DoseSpotAlertState>()(
  devtools((set) => ({
    doseSpotAlert: null,
    setDoseSpotAlert: (alert: any) => set({ doseSpotAlert: alert }),
  }))
)

export const useQueryGetDoseSpotAlert = (
  clinicId: number,
  getToken: any
): QueryObserverResult<any, unknown> => {
  const { setDoseSpotAlert } = useDoseSpotAlertStore()

  return useQuery<any>(
    ['dose-spot-alert', clinicId],
    async () => await getDoseSpotAlert(clinicId, getToken),
    {
      enabled: !!clinicId,
      onSuccess: (data) => {
        setDoseSpotAlert(data)
      },
    }
  )
}
