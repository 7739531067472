import { type OnlineAppointment } from '@/types/OnlineAppointment'
import { type paths } from '../generated/schema'
import type { GetToken } from '@clerk/types'
import { Fetcher } from 'openapi-typescript-fetch'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetOnlineAppointments = fetcher
  .path('/api/ClinicCreatedAppointment')
  .method('get')
  .create()

export const getOnlineAppointments = async (
  clinicId: number,
  startDate: string,
  endDate: string,
  getToken: GetToken
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' }))!}`,
      },
    },
  })
  try {
    return (
      (await setupGetOnlineAppointments({ clinicId, startDate, endDate }))
        .data ?? []
    )
  } catch (error) {
    if (error instanceof setupGetOnlineAppointments.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetPatientAppointments = fetcher
  .path('/api/ClinicCreatedAppointment')
  .method('get')
  .create()

export const getPatientAppointments = async (
  patientId: number,
  getToken: GetToken,
  startDate?: string,
  endDate?: string
): Promise<OnlineAppointment[]> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' }))!}`,
      },
    },
  })

  return (
    (await setupGetPatientAppointments({ patientId, startDate, endDate }))
      .data ?? []
  )
}

const setupUpdateOnlineAppointments = fetcher
  .path('/api/ClinicCreatedAppointment/{appointmentId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateOnlineAppointments = async (
  appointmentId: number,
  onlineAppointment: OnlineAppointment,
  getToken: GetToken
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' }))!}`,
      },
    },
  })
  try {
    await setupUpdateOnlineAppointments({
      appointmentId,
      ...onlineAppointment,
    })
    return onlineAppointment
  } catch (error) {
    if (error instanceof setupUpdateOnlineAppointments.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
