import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configurationDoseSpotToken

// create fetch operations

const setupGetDSToken = fetcher
  .path('/api/DoseSpotToken/{clinicId}')
  .method('get')
  .create()

export const getDSToken = async (
  clinicId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetDSToken({ clinicId })).data
  } catch (error) {
    if (error instanceof setupGetDSToken.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
