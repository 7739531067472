import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

const fetcher = Fetcher.for<paths>()

const setupGetTransactionLogs = fetcher
  .path('/api/TransactionLog/{startDate}/{endDate}')
  .method('get')
  .create()

export const getTransactionLogs = async (
  startDate: string,
  endDate: string,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetTransactionLogs({ startDate, endDate })).data
  } catch (error) {
    if (error instanceof setupGetTransactionLogs.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage
      }
    }
  }
}
