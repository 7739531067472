import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type VisitAccountUserPing } from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

const setupUpdateUsersOnChart = fetcher
  .path('/api/VisitAccountUserPing')
  .method('post')
  .create({ 'api-version': undefined })

export const updateUsersOnChart = async (
  visitAccountUserPing: VisitAccountUserPing,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })

  try {
    await setupUpdateUsersOnChart({
      ...visitAccountUserPing,
    })
  } catch (error) {
    if (error instanceof setupFindUsersOnChart.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupFindUsersOnChart = fetcher
  .path('/api/VisitAccountUserPing/Ping/{visitId}')
  .method('get')
  .create()

export const findUsersOnChart = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  const lookbackSec = 20
  try {
    return (
      await setupFindUsersOnChart({
        visitId,
        lookbackSec,
      })
    ).data
  } catch (error) {
    if (error instanceof setupFindUsersOnChart.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
