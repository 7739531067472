import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import { getExams, updateExams } from '@/services/ExamService'
import { type Exam } from '@/types/Exam'

interface ExamState {
  exams: Exam[];
  setExams: (exams: Exam[]) => void;
}

export const useExamStore = create<ExamState>()(
  devtools((set) => ({
    exams: [],
    setExams: (exams: Exam[]) => set({ exams }),
  }))
)

export const useQueryGetExams = (
  vId: number,
  getToken: any
): QueryObserverResult<Exam[], unknown> => {
  const { setExams } = useExamStore()

  return useQuery<Exam[]>(
    ['exams', vId],
    async () => await getExams(vId, getToken),
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setExams(data)
      },
      initialData: [],
    }
  )
}

export const useMutateUpdateExams = (
  getToken: any
): UseMutationResult<Exam[], unknown, { vId: number; exams: Exam[] }> => {
  return useMutation<Exam[], unknown, { vId: number; exams: Exam[] }>(
    async ({ vId, exams }) => await updateExams(vId, exams, getToken),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        throw error
      },
    }
  )
}
