import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type PreventativeMedNotes } from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetPreventativeMedNotes = fetcher
  .path('/api/PreventativeMedNotes/Visit/{vId}')
  .method('get')
  .create()

  export const getPreventativeMedNotes = async (
    vId: number,
    getToken: any
  ): Promise<any> => {
    const token = (await getToken({ template: 'UrgentIQ' })) as string ?? ''
    fetcher.configure({
      baseUrl: process.env.API_URL,
      init: {
        headers: {
          origin: 'null',
          Authorization: `Bearer ${token}`
        }
      }
    })
    try {
      return (await setupGetPreventativeMedNotes({ vId })).data
    } catch (error) {
      if (error instanceof setupGetPreventativeMedNotes.Error) {
        // get discriminated union { status, data }
        const errorType = error.getActualType()
        if (errorType.status === 400) {
          throw errorType.data.validationErrors // 400 response data
        } else if (errorType.status === 500) {
          throw errorType.data.errorMessage // 500 response data
        }
      }
    }
  }

const setupGetPatientPreventativeMedNotes = fetcher
  .path('/api/PreventativeMedNotes/Patient/{patientId}')
  .method('get')
  .create()

export const getPatientPreventativeMedNotes = async (
  patientId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetPatientPreventativeMedNotes({ patientId })).data
  } catch (error) {
    if (error instanceof setupGetPatientPreventativeMedNotes.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 500) {
        throw errorType.data.errorMessage
      }
    }
  }
}

const setupUpdatePreventativeMedNotes = fetcher
  .path('/api/PreventativeMedNotes/{vId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updatePreventativeMedNotes = async (
  vId: number,
  preventativeMedNotes: PreventativeMedNotes,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupUpdatePreventativeMedNotes({ vId, ...preventativeMedNotes })).data
  } catch (error) {
    if (error instanceof setupUpdatePreventativeMedNotes.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage
      }
    }
  }
}