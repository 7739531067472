import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import {
  getAllergen,
  getAllergenReaction,
  getCPTModifiers,
  getCodingModifiers,
  getComplaints,
  getDXCodes,
  getDXModifiers,
  getExamSystem,
  getImmunization,
  getInformationSources,
  getInsurance,
  getInsuranceType,
  getMedication,
  getOrderStatus,
  getOrders,
  getPharmacies,
  getProcedureTypes,
  getRosSymptoms,
  getStates,
  getSymptoms,
  getTimezone,
  getVisitTypes,
  getSurgeryType,
  getMedicalConditionsType,
  getLocationTypes,
  getModifyingFactorTypes,
  getRosSystems,
  getExamSystemComponent,
  getIllnessLocations,
  getIllnessModifyingFactors,
  getRelationships,
  getUserTaskTypes,
  getUserTaskStatus,
  getUserTaskPriority,
  getExaminations,
  getExamResultFactors,
  getRosResultFactors,
  getClinicalProcedureFields,
  getVisitCategories,
  getMeasurementUnits,
  getTemperatureMethods,
  getMenstruationDetails
} from '@/services'
import {
  type Allergen,
  type CPTModifier,
  type CodingModifier,
  type Complaint,
  type DXCode,
  type DXModifiers,
  type ExamSystem,
  type ExamSystemComponent,
  type Immunization,
  type InformationSource,
  type InsuranceType,
  type Medication,
  type Order,
  type OrderStatus,
  type PVerifyInsurance,
  type Pharmacy,
  type ProcedureType,
  type RosSymptom,
  type RosSystem,
  type State,
  type Symptom,
  type TimeZone,
  type VisitCategory,
  type VisitType,
  type SurgeryTypes,
  type MedicationConditionType,
  type IllnessLocation,
  type UserTaskType,
  type UserTaskStatus,
  type UserTaskPriority,
  type ExamNode,
  type ExamResultFactor,
  type RosResultFactor,
  type ClinicalProcedureField,
  type MeasurementUnit,
  type AllergyReaction,
  type TemperatureMethod,
  type MenstruationDetails
} from '@/types'
import { openDB } from 'idb'
import { type Relationship } from '@/types/Relationship'
import { convertToTree } from '@/utility'
import { type IllnessModifyingFactor } from '@/types/IllnessModifyingFactor'

interface ReferenceDataStates {
  states: State[] | [];
  pharmacies: Pharmacy[] | [];
  visitCategories: VisitCategory[];
  visitTypes: VisitType[] | [];
  informationSource: InformationSource[] | [];
  complaints: Complaint[] | [];
  insuranceTypes: InsuranceType[] | [];
  examSystem: ExamSystem[] | [];
  examSystemComponent: ExamSystemComponent[] | [];
  symptoms: Symptom[] | [];
  dxModifiers: DXModifiers[] | [];
  codingModifiers: CodingModifier[] | [];
  orders: Order[] | [];
  orderStatus: OrderStatus[] | [];
  procedureTypes: ProcedureType[] | [];
  rosSystems: RosSystem[] | [];
  rosSymptoms: RosSymptom[] | [];
  timezone: TimeZone[] | [];
  cptModifiers: CPTModifier[] | [];
  insurance: PVerifyInsurance[] | [];
  surgeryTypes: SurgeryTypes[] | [];
  medicationConditionType: MedicationConditionType[] | [];
  locationTypes: IllnessLocation[] | [];
  modifyingFactorTypes: IllnessModifyingFactor[] | [];
  userTaskTypes: UserTaskType[] | [];
  userTaskStatus: UserTaskStatus[] | [];
  userTaskPriority: UserTaskPriority[] | [];
  examinations: ExamNode[];
  examResultFactors: ExamResultFactor[];
  rosResultFactors: RosResultFactor[];
  clinicalProcedureFields: ClinicalProcedureField[];
  temperatureMethods: TemperatureMethod[];
  menstrualDetails: MenstruationDetails[];
  setStates: (states: State[]) => void;
  setPharmacies: (pharmacies: Pharmacy[]) => void;
  setVisitCategories: (visitCategories: VisitCategory[]) => void;
  setVisitTypes: (visitTypes: VisitType[]) => void;
  setInformationSource: (informationSource: InformationSource[]) => void;
  setComplaints: (complaints: Complaint[]) => void;
  setInsuranceTypes: (insuranceTypes: InsuranceType[]) => void;
  setExamSystem: (examSystem: ExamSystem[]) => void;
  setExamSystemComponent: (examSystemComponent: ExamSystemComponent[]) => void;
  setSymptoms: (symptoms: Symptom[]) => void;
  setDXModifiers: (dxModifiers: DXModifiers[]) => void;
  setCodingModifiers: (codingModifiers: CodingModifier[]) => void;
  setOrders: (orders: Order[]) => void;
  setOrderStatus: (orderStatus: OrderStatus[]) => void;
  setProcedureTypes: (procedureTypes: ProcedureType[]) => void;
  setRosSystems: (rosSystems: RosSystem[]) => void;
  setRosSymptoms: (rosSymptoms: RosSymptom[]) => void;
  setTimezone: (timezone: TimeZone[]) => void;
  setCPTModifiers: (cptModifiers: CPTModifier[]) => void;
  setInsurance: (insurance: PVerifyInsurance[]) => void;
  setSurgeryTypes: (surgeryTypes: SurgeryTypes[]) => void;
  setMedicationConditionType: (
    medicationConditionType: MedicationConditionType[]
  ) => void;
  setLocationTypes: (locationTypes: IllnessLocation[]) => void;
  setModifyingFactorTypes: (modifyingFactorTypes: IllnessModifyingFactor[]) => void;
  setUserTaskTypes: (userTaskTypes: UserTaskType[]) => void;
  setUserTaskStatus: (userTaskStatus: UserTaskStatus[]) => void;
  setUserTaskPriority: (userTaskPriority: UserTaskPriority[]) => void;
  setExaminations: (examinations: ExamNode[]) => void;
  setExamResultFactors: (examResultFactors: ExamResultFactor[]) => void;
  setRosResultFactors: (rosResultFactors: RosResultFactor[]) => void;
  setClinicalProcedureFields: (
    clinicalProcedureFields: ClinicalProcedureField[]
  ) => void;
  setTemperatureMethods: (temperatureMethods: TemperatureMethod[]) => void;
  setMenstrualDetails: (menstrualDetails: MenstruationDetails[]) => void;
}

interface MedicationStates {
  medications: Medication[] | [];
  setMedications: (medication: Medication[]) => Promise<void>;
}

interface RelationshipStates {
  relationships: Relationship[] | [];
  setRelationships: (relationships: Relationship[]) => Promise<void>;
}

interface AllergenStates {
  allergens: Allergen[] | [];
  allergyReactions: AllergyReaction[] | [];
  setAllergens: (allergen: Allergen[]) => Promise<void>;
  setAllergyReactions: (allergyReactions: AllergyReaction[]) => void;
}

interface ImmunizationStates {
  immunizations: Immunization[];
  setImmunizations: (immunization: Immunization[]) => Promise<void>;
}

interface DXCodeStates {
  dxCodes: DXCode[];
  setDXCodes: (dxCode: DXCode[]) => Promise<void>;
}

export const useReferenceDataStore = create<ReferenceDataStates>()(
  devtools(
    persist(
      (set) => ({
        states: [],
        pharmacies: [],
        visitCategories: [],
        visitTypes: [],
        informationSource: [],
        complaints: [],
        insuranceTypes: [],
        examSystem: [],
        examSystemComponent: [],
        symptoms: [],
        dxModifiers: [],
        codingModifiers: [],
        orders: [],
        orderStatus: [],
        procedureTypes: [],
        rosSystems: [],
        rosSymptoms: [],
        timezone: [],
        cptModifiers: [],
        insurance: [],
        surgeryTypes: [],
        medicationConditionType: [],
        locationTypes: [],
        modifyingFactorTypes: [],
        userTaskTypes: [],
        userTaskStatus: [],
        userTaskPriority: [],
        examinations: [],
        examResultFactors: [],
        rosResultFactors: [],
        clinicalProcedureFields: [],
        temperatureMethods: [],
        menstrualDetails: [],
        setStates: (states: State[]) => set({ states }),
        setPharmacies: (pharmacies: Pharmacy[]) => set({ pharmacies }),
        setVisitCategories: (visitCategories: VisitCategory[]) =>
          set({ visitCategories }),
        setVisitTypes: (visitTypes: VisitType[]) => {
          set({ visitTypes })
        },
        setInformationSource: (informationSource: InformationSource[]) =>
          set({ informationSource }),
        setComplaints: (complaints: Complaint[]) => set({ complaints }),
        setInsuranceTypes: (insuranceTypes: InsuranceType[]) =>
          set({ insuranceTypes }),
        setExamSystem: (examSystem: ExamSystem[]) => set({ examSystem }),
        setExamSystemComponent: (examSystemComponent: ExamSystemComponent[]) =>
          set({ examSystemComponent }),
        setSymptoms: (symptoms: Symptom[]) => set({ symptoms }),
        setDXModifiers: (dxModifiers: DXModifiers[]) => set({ dxModifiers }),
        setCodingModifiers: (codingModifiers: CodingModifier[]) =>
          set({ codingModifiers }),
        setOrders: (orders: Order[]) => set({ orders }),
        setOrderStatus: (orderStatus: OrderStatus[]) => set({ orderStatus }),
        setProcedureTypes: (procedureTypes: ProcedureType[]) =>
          set({ procedureTypes }),
        setRosSystems: (rosSystems: RosSystem[]) => set({ rosSystems }),
        setRosSymptoms: (rosSymptoms: RosSymptom[]) => set({ rosSymptoms }),
        setTimezone: (timezone: TimeZone[]) => set({ timezone }),
        setCPTModifiers: (cptModifiers: CPTModifier[]) => set({ cptModifiers }),
        setInsurance: (insurance: PVerifyInsurance[]) => set({ insurance }),
        setSurgeryTypes: (surgeryTypes: SurgeryTypes[]) =>
          set({ surgeryTypes }),
        setMedicationConditionType: (
          medicationConditionType: MedicationConditionType[]
        ) => set({ medicationConditionType }),
        setLocationTypes: (locationTypes: IllnessLocation[]) =>
          set({ locationTypes }),
        setModifyingFactorTypes: (modifyingFactorTypes: IllnessModifyingFactor[]) =>
          set({ modifyingFactorTypes }),
        setUserTaskTypes: (userTaskTypes: UserTaskType[]) =>
          set({ userTaskTypes }),
        setUserTaskStatus: (userTaskStatus: UserTaskStatus[]) =>
          set({ userTaskStatus }),
        setUserTaskPriority: (userTaskPriority: UserTaskPriority[]) =>
          set({ userTaskPriority }),
        setExaminations: (examinations: ExamNode[]) => set({ examinations }),
        setExamResultFactors: (examResultFactors: ExamResultFactor[]) =>
          set({ examResultFactors }),
        setRosResultFactors: (rosResultFactors: ExamResultFactor[]) =>
          set({ rosResultFactors }),
        setClinicalProcedureFields: (
          clinicalProcedureFields: ClinicalProcedureField[]
        ) => set({ clinicalProcedureFields }),
        setTemperatureMethods: (temperatureMethods: TemperatureMethod[]) =>
          set({ temperatureMethods }),
        setMenstrualDetails: (menstrualDetails: MenstruationDetails[]) =>
          set({ menstrualDetails })
      }),
      {
        name: 'reference-data',
      }
    )
  )
)

export const useMedicationStore = create<MedicationStates>()(
  devtools((set) => ({
    medications: [],
    setMedications: async (medications: Medication[]): Promise<void> => {
      try {
        const db = await openDB('medication-db', 1, {
          upgrade(db) {
            db.createObjectStore('medication-store')
          },
        })
        const tx = db.transaction('medication-store', 'readonly')
        const existingData = await tx
          .objectStore('medication-store')
          .get('medication')
        if (!existingData) {
          const writeTx = db.transaction('medication-store', 'readwrite')
          await writeTx
            .objectStore('medication-store')
            .put(medications, 'medication')
          await writeTx.done
          set({ medications })
          return
        }
        set({ medications: existingData })
      } catch (error) {
        console.error(error)
      }
    },
  }))
)

export const useRelationshipStore = create<RelationshipStates>()(
  devtools((set) => ({
    relationships: [],
    setRelationships: async (relationships: Relationship[]): Promise<void> => {
      try {
        const db = await openDB('relationship-db', 1, {
          upgrade(db) {
            db.createObjectStore('relationship-store')
          },
        })
        const tx = db.transaction('relationship-store', 'readonly')
        const existingData = await tx
          .objectStore('relationship-store')
          .get('relationship')
        if (!existingData) {
          const writeTx = db.transaction('relationship-store', 'readwrite')
          await writeTx
            .objectStore('relationship-store')
            .put(relationships, 'relationship')
          await writeTx.done
          set({ relationships })
          return
        }
        set({ relationships: existingData })
      } catch (error) {
        console.error(error)
      }
    },
  }))
)

export const useAllergenStore = create<AllergenStates>()(
  devtools((set) => ({
    allergens: [],
    allergyReactions: [], // Changed from allergenReactions to allergyReactions
    setAllergens: async (allergens: Allergen[]): Promise<void> => {
      try {
        const db = await openDB('allergen-db', 1, {
          upgrade(db) {
            db.createObjectStore('allergen-store')
          },
        })
        const tx = db.transaction('allergen-store', 'readonly')
        const existingData = await tx
          .objectStore('allergen-store')
          .get('allergen')
        if (!existingData) {
          const writeTx = db.transaction('allergen-store', 'readwrite')
          await writeTx
            .objectStore('allergen-store')
            .put(allergens, 'allergen')
          await writeTx.done
          set({ allergens })
          return
        }
        set({ allergens: existingData })
      } catch (error) {
        console.error(error)
      }
    },
    setAllergyReactions: (allergyReactions: AllergyReaction[]): void =>
      set({ allergyReactions }),
  }))
)

export const useImmunizationStore = create<ImmunizationStates>()(
  devtools((set) => ({
    immunizations: [],
    setImmunizations: async (immunizations: Immunization[]): Promise<void> => {
      try {
        const db = await openDB('immunization-db', 1, {
          upgrade(db) {
            db.createObjectStore('immunization-store')
          },
        })
        const tx = db.transaction('immunization-store', 'readonly')
        const existingData = await tx
          .objectStore('immunization-store')
          .get('immunization')
        if (!existingData) {
          const writeTx = db.transaction('immunization-store', 'readwrite')
          await writeTx
            .objectStore('immunization-store')
            .put(immunizations, 'immunization')
          await writeTx.done
          set({ immunizations })
          return
        }
        set({ immunizations: existingData })
      } catch (error) {
        console.error(error)
      }
    },
  }))
)

export const useDXCodeStore = create<DXCodeStates>()(
  devtools((set) => ({
    dxCodes: [],
    setDXCodes: async (dxCodes: DXCode[]): Promise<void> => {
      try {
        const db = await openDB('dxcode-db', 1, {
          upgrade(db) {
            db.createObjectStore('dxcode-store')
          },
        })
        const tx = db.transaction('dxcode-store', 'readonly')
        const existingData = await tx.objectStore('dxcode-store').get('dxcode')
        if (!existingData) {
          const writeTx = db.transaction('dxcode-store', 'readwrite')
          await writeTx.objectStore('dxcode-store').put(dxCodes, 'dxcode')
          await writeTx.done
          set({ dxCodes })
          return
        }
        set({ dxCodes: existingData })
      } catch (error) {
        console.error(error)
      }
    },
  }))
)

export const useQueryGetStates = (
  getToken: any
): QueryObserverResult<State[], unknown> => {
  const { states, setStates } = useReferenceDataStore()

  return useQuery<State[]>(
    ['states'],
    async () => {
      if (states.length > 0) return states
      return await getStates(getToken)
    },
    {
      onSuccess: (data) => {
        if (states.length === 0) setStates(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetTaskTypes = (
  getToken: any
): QueryObserverResult<UserTaskType[], unknown> => {
  const { userTaskTypes, setUserTaskTypes } = useReferenceDataStore()

  return useQuery<UserTaskType[]>(
    ['userTaskTypes'],
    async () => {
      if (userTaskTypes.length > 0) return userTaskTypes
      return await getUserTaskTypes(getToken)
    },
    {
      onSuccess: (data) => {
        if (userTaskTypes.length === 0) setUserTaskTypes(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetTaskStatus = (
  getToken: any
): QueryObserverResult<UserTaskStatus[], unknown> => {
  const { userTaskStatus, setUserTaskStatus } = useReferenceDataStore()

  return useQuery<UserTaskStatus[]>(
    ['userTaskStatus'],
    async () => {
      if (userTaskStatus.length > 0) return userTaskStatus
      return await getUserTaskStatus(getToken)
    },
    {
      onSuccess: (data) => {
        if (userTaskStatus.length === 0) setUserTaskStatus(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetTaskPriority = (
  getToken: any
): QueryObserverResult<UserTaskPriority[], unknown> => {
  const { userTaskPriority, setUserTaskPriority } = useReferenceDataStore()

  return useQuery<UserTaskPriority[]>(
    ['userTaskPriority'],
    async () => {
      if (userTaskPriority.length > 0) return userTaskPriority
      return await getUserTaskPriority(getToken)
    },
    {
      onSuccess: (data) => {
        if (userTaskPriority.length === 0) setUserTaskPriority(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetPharmacies = (
  getToken: any
): QueryObserverResult<Pharmacy[], unknown> => {
  const { pharmacies, setPharmacies } = useReferenceDataStore()

  return useQuery<Pharmacy[]>(
    ['pharmacies'],
    async () => {
      if (pharmacies.length > 0) return pharmacies
      return await getPharmacies(getToken)
    },
    {
      onSuccess: (data) => {
        if (pharmacies.length === 0) setPharmacies(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetVisitCategories = (
  clinicId: number,
  getToken: any
): QueryObserverResult<VisitType[], unknown> => {
  const { setVisitCategories } = useReferenceDataStore()

  return useQuery<VisitCategory[]>(
    ['visit-categories', clinicId],
    async () => {
      return await getVisitCategories(clinicId, getToken)
    },
    {
      onSuccess: (data) => {
        setVisitCategories(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetVisitTypes = (
  clinicId: number,
  getToken: any
): QueryObserverResult<VisitType[], unknown> => {
  const { visitTypes, setVisitTypes } = useReferenceDataStore()

  return useQuery<VisitType[]>(
    ['visit-types', clinicId],
    async () => {
      const newVisitTypes = await getVisitTypes(clinicId, getToken)
      setVisitTypes(newVisitTypes)
      return newVisitTypes
    },
    {
      onSuccess: (data) => {
        if (visitTypes.length === 0) setVisitTypes(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetInformationSources = (
  getToken: any
): QueryObserverResult<InformationSource[], unknown> => {
  const { informationSource, setInformationSource } = useReferenceDataStore()

  return useQuery<InformationSource[]>(
    ['information-source'],
    async () => {
      if (informationSource.length > 0) return informationSource
      return await getInformationSources(getToken)
    },
    {
      onSuccess: (data) => {
        if (informationSource.length === 0) setInformationSource(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetComplaints = (
  getToken: any
): QueryObserverResult<Complaint[], unknown> => {
  const { complaints, setComplaints } = useReferenceDataStore()

  return useQuery<Complaint[]>(
    ['complaints'],
    async () => {
      if (complaints.length > 0) return complaints
      return await getComplaints(getToken)
    },
    {
      onSuccess: (data) => {
        if (complaints.length === 0) setComplaints(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetInsuranceType = (
  getToken: any
): QueryObserverResult<InsuranceType[], unknown> => {
  const { insuranceTypes, setInsuranceTypes } = useReferenceDataStore()

  return useQuery<InsuranceType[]>(
    ['insurance-type'],
    async () => {
      if (insuranceTypes.length > 0) return insuranceTypes
      return await getInsuranceType(getToken)
    },
    {
      onSuccess: (data) => {
        if (insuranceTypes.length === 0) setInsuranceTypes(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetExamSystem = (
  getToken: any
): QueryObserverResult<ExamSystem[], unknown> => {
  const { examSystem, setExamSystem } = useReferenceDataStore()

  return useQuery<ExamSystem[]>(
    ['exam-system'],
    async () => {
      if (examSystem.length > 0) return examSystem
      return await getExamSystem(getToken)
    },
    {
      onSuccess: (data) => {
        if (examSystem.length === 0) setExamSystem(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetExamSystemComponent = (
  getToken: any
): QueryObserverResult<ExamSystemComponent[], unknown> => {
  const { examSystemComponent, setExamSystemComponent } =
    useReferenceDataStore()

  return useQuery<ExamSystemComponent[]>(
    ['exam-system-component'],
    async () => {
      if (examSystemComponent.length > 0) return examSystemComponent
      return await getExamSystemComponent(getToken)
    },
    {
      onSuccess: (data) => {
        if (examSystemComponent.length === 0) setExamSystemComponent(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetAllergen = (
  getToken: any
): QueryObserverResult<Allergen[], unknown> => {
  const { allergens, setAllergens } = useAllergenStore()
  return useQuery<Allergen[]>(
    ['allergen'],
    async () => {
      const db = await openDB('allergen-db', 1, {
        upgrade(db) {
          // Create a store of objects
          if (!db.objectStoreNames.contains('allergen-store')) {
            db.createObjectStore('allergen-store')
          }
        },
      })
      const existingData = await db.get('allergen-store', 'allergen')
      if (existingData) {
        setAllergens(existingData).catch((err) => {
          throw err
        })
        return existingData
      } else {
        return await getAllergen(getToken)
      }
    },
    {
      onSuccess: (data) => {
        if (allergens.length === 0) {
          setAllergens(data).catch((err) => {
            throw err
          })
        }
      },
      initialData: allergens,
    }
  )
}

export const useQueryGetAllergyReaction = (
  getToken: any
): QueryObserverResult<AllergyReaction[], unknown> => {
  const { allergyReactions, setAllergyReactions } = useAllergenStore()

  return useQuery<AllergyReaction[]>(
    ['allergy-reaction'],
    async () => {
      if (allergyReactions.length > 0) return allergyReactions
      return await getAllergenReaction(getToken)
    },
    {
      onSuccess: (data) => {
        if (allergyReactions.length === 0) setAllergyReactions(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetMedication = (
  getToken: any
): QueryObserverResult<Medication[], unknown> => {
  const { medications, setMedications } = useMedicationStore()
  return useQuery<Medication[]>(
    ['medication'],
    async () => {
      const db = await openDB('medication-db', 1, {
        upgrade(db) {
          // Create a store of objects
          if (!db.objectStoreNames.contains('medication-store')) {
            db.createObjectStore('medication-store')
          }
        },
      })
      const existingData = await db.get('medication-store', 'medication')
      if (existingData) {
        setMedications(existingData).catch((err) => {
          throw err
        })
        return existingData
      } else {
        return await getMedication(getToken)
      }
    },
    {
      onSuccess: (data) => {
        if (medications.length === 0) {
          setMedications(data).catch((err) => {
            throw err
          })
        }
      },
      initialData: medications,
    }
  )
}

export const useQueryGetRelationships = (
  getToken: any
): QueryObserverResult<Relationship[], unknown> => {
  const { relationships, setRelationships } = useRelationshipStore()
  return useQuery<Relationship[]>(
    ['relationship'],
    async () => {
      const db = await openDB('relationship-db', 1, {
        upgrade(db) {
          // Create a store of objects
          if (!db.objectStoreNames.contains('relationship-store')) {
            db.createObjectStore('relationship-store')
          }
        },
      })
      const existingData = await db.get('relationship-store', 'relationship')
      if (existingData) {
        setRelationships(existingData).catch((err) => {
          throw err
        })
        return existingData
      } else {
        return await getRelationships(getToken)
      }
    },
    {
      onSuccess: (data) => {
        if (relationships.length === 0) {
          setRelationships(data).catch((err) => {
            throw err
          })
        }
      },
      initialData: relationships,
    }
  )
}

export const useQueryGetSymptoms = (
  getToken: any
): QueryObserverResult<Symptom[], unknown> => {
  const { symptoms, setSymptoms } = useReferenceDataStore()

  return useQuery<Symptom[]>(
    ['symptoms'],
    async () => {
      if (symptoms.length > 0) return symptoms
      return await getSymptoms(getToken)
    },
    {
      onSuccess: (data) => {
        if (symptoms.length === 0) setSymptoms(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetDXCodes = (
  getToken: any
): QueryObserverResult<DXCode[], unknown> => {
  const { dxCodes, setDXCodes } = useDXCodeStore()

  return useQuery<DXCode[]>(
    ['dxcode'],
    async () => {
      const db = await openDB('dxcode-db', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('dxcode-store')) {
            db.createObjectStore('dxcode-store')
          }
        },
      })
      const existingData = await db.get('dxcode-store', 'dxcode')

      if (existingData) {
        setDXCodes(existingData).catch((err) => {
          throw err
        })
        return existingData
      } else {
        return await getDXCodes(getToken)
      }
    },
    {
      onSuccess: (data) => {
        if (dxCodes.length === 0) {
          setDXCodes(data).catch((err) => {
            throw err
          })
        }
      },
      initialData: dxCodes ?? [],
    }
  )
}

export const useQueryGetDXModifiers = (
  getToken: any
): QueryObserverResult<DXModifiers[], unknown> => {
  const { dxModifiers, setDXModifiers } = useReferenceDataStore()

  return useQuery<DXModifiers[]>(
    ['dxmodifier'],
    async () => {
      if (dxModifiers.length > 0) return dxModifiers
      return await getDXModifiers(getToken)
    },
    {
      onSuccess: (data) => {
        if (dxModifiers.length === 0) setDXModifiers(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetCodingModifiers = (
  getToken: any
): QueryObserverResult<CodingModifier[], unknown> => {
  const { codingModifiers, setCodingModifiers } = useReferenceDataStore()

  return useQuery<CodingModifier[]>(
    ['coding-modifiers'],
    async () => {
      if (codingModifiers.length > 0) return codingModifiers
      return await getCodingModifiers(getToken)
    },
    {
      onSuccess: (data) => {
        if (codingModifiers.length === 0) setCodingModifiers(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetOrders = (
  getToken: any
): QueryObserverResult<Order[], unknown> => {
  const { orders, setOrders } = useReferenceDataStore()

  return useQuery<Order[]>(
    ['orders'],
    async () => {
      if (orders.length > 0) return orders
      return await getOrders(getToken)
    },
    {
      onSuccess: (data) => {
        if (orders.length === 0) setOrders(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetOrderStatus = (
  getToken: any
): QueryObserverResult<OrderStatus[], unknown> => {
  const { orderStatus, setOrderStatus } = useReferenceDataStore()

  return useQuery<OrderStatus[]>(
    ['order-status'],
    async () => {
      if (orderStatus.length > 0) return orderStatus
      return await getOrderStatus(getToken)
    },
    {
      onSuccess: (data) => {
        if (orderStatus.length === 0) setOrderStatus(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetProcedureTypes = (
  clinicId: number,
  getToken: any
): QueryObserverResult<ProcedureType[], unknown> => {
  const { procedureTypes, setProcedureTypes } = useReferenceDataStore()

  return useQuery<ProcedureType[]>(
    ['procedure-types', clinicId],
    async () => {
      return await getProcedureTypes(clinicId ?? 0, getToken)
    },
    {
      onSuccess: (data) => {
        if (procedureTypes.length === 0) setProcedureTypes(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetRosSystems = (
  getToken: any
): QueryObserverResult<RosSystem[], unknown> => {
  const { rosSystems, setRosSystems } = useReferenceDataStore()

  return useQuery<RosSystem[]>(
    ['ros-system'],
    async () => {
      if (rosSystems.length > 0) return rosSystems
      return await getRosSystems(getToken)
    },
    {
      onSuccess: (data) => {
        if (rosSystems.length === 0) setRosSystems(data)
      },
      initialData: [],
    }
  )
}

// This is unused because the date is usually being fetched from the API
// so
export const useQueryGetRosSymptoms = (
  getToken: any,
  date: Date
): QueryObserverResult<RosSymptom[], unknown> => {
  const { rosSymptoms, setRosSymptoms } = useReferenceDataStore()

  return useQuery<RosSymptom[]>(
    ['ros-symptoms'],
    async () => {
      if (rosSymptoms.length > 0) return rosSymptoms
      return await getRosSymptoms(getToken, date)
    },
    {
      onSuccess: (data) => {
        if (rosSymptoms.length === 0) setRosSymptoms(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetTimezone = (
  getToken: any
): QueryObserverResult<TimeZone[], unknown> => {
  const { timezone, setTimezone } = useReferenceDataStore()

  return useQuery<TimeZone[]>(
    ['timezone'],
    async () => {
      if (timezone.length > 0) return timezone
      return await getTimezone(getToken)
    },
    {
      onSuccess: (data) => {
        if (timezone.length === 0) setTimezone(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetCPTModifiers = (
  getToken: any
): QueryObserverResult<CPTModifier[], unknown> => {
  const { cptModifiers, setCPTModifiers } = useReferenceDataStore()

  return useQuery<CPTModifier[]>(
    ['cpt-modifier'],
    async () => {
      if (cptModifiers.length > 0) return cptModifiers
      return await getCPTModifiers(getToken)
    },
    {
      onSuccess: (data) => {
        if (cptModifiers.length === 0) setCPTModifiers(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetImmunization = (
  getToken: any
): QueryObserverResult<Immunization[], unknown> => {
  const { immunizations, setImmunizations } = useImmunizationStore()

  return useQuery<Immunization[]>(
    ['immunization'],
    async () => {
      const db = await openDB('immunization-db', 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('immunization-store')) {
            db.createObjectStore('immunization-store')
          }
        },
      })
      const existingData = await db.get('immunization-store', 'immunization')

      if (existingData) {
        setImmunizations(existingData).catch((err) => {
          throw err
        })
        return existingData
      } else {
        return await getImmunization(getToken)
      }
    },
    {
      onSuccess: (data) => {
        if (immunizations.length === 0) {
          setImmunizations(data).catch((err) => {
            throw err
          })
        }
      },
      initialData: immunizations,
    }
  )
}

export const useQueryGetInsurance = (
  getToken: any
): QueryObserverResult<PVerifyInsurance[], unknown> => {
  const { insurance, setInsurance } = useReferenceDataStore()

  return useQuery<PVerifyInsurance[]>(
    ['insurance'],
    async () => {
      if (insurance.length > 0) return insurance
      return await getInsurance(getToken)
    },
    {
      onSuccess: (data) => {
        if (insurance.length === 0) setInsurance(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetSurgeryTypes = (
  getToken: any
): QueryObserverResult<SurgeryTypes[], unknown> => {
  const { surgeryTypes, setSurgeryTypes } = useReferenceDataStore()

  return useQuery<SurgeryTypes[]>(
    ['surgery-types'],
    async () => {
      if (surgeryTypes.length > 0) return surgeryTypes
      return await getSurgeryType(getToken)
    },
    {
      onSuccess: (data) => {
        if (surgeryTypes.length === 0) setSurgeryTypes(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetMedicalConditions = (
  getToken: any
): QueryObserverResult<MedicationConditionType[], unknown> => {
  const { medicationConditionType, setMedicationConditionType } =
    useReferenceDataStore()

  return useQuery<MedicationConditionType[]>(
    ['medical-condition'],
    async () => {
      if (medicationConditionType.length > 0) return medicationConditionType
      return await getMedicalConditionsType(getToken)
    },
    {
      onSuccess: (data) => {
        if (medicationConditionType.length === 0) {
          setMedicationConditionType(data)
        }
      },
      initialData: [],
    }
  )
}

export const useQueryGetLocations = (
  getToken: any
): QueryObserverResult<IllnessLocation[], unknown> => {
  const { locationTypes, setLocationTypes } = useReferenceDataStore()

  return useQuery<IllnessLocation[]>(
    ['illness-location'],
    async () => {
      if (locationTypes.length > 0) return locationTypes
      return await getLocationTypes(getToken)
    },
    {
      onSuccess: (data) => {
        if (locationTypes.length === 0) setLocationTypes(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetIllnessLocations = (
  complaintId: number,
  getToken: any
): QueryObserverResult<IllnessLocation[], unknown> => {
  return useQuery<IllnessLocation[]>(
    ['illness-locations', complaintId],
    async () => await getIllnessLocations(complaintId, getToken),
    {
      onSuccess: (data) => {},
      initialData: [],
    }
  )
}

export const useQueryGetModifyingFactors = (
  getToken: any
): QueryObserverResult<IllnessModifyingFactor[], unknown> => {
  const { modifyingFactorTypes, setModifyingFactorTypes } = useReferenceDataStore()

  return useQuery<IllnessModifyingFactor[]>(
    ['illness-modifying-factor'],
    async () => {
      if (modifyingFactorTypes.length > 0) return modifyingFactorTypes
      return await getModifyingFactorTypes(getToken)
    },
    {
      onSuccess: (data) => {
        if (modifyingFactorTypes.length === 0) setModifyingFactorTypes(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetIllnessModifyingFactors = (
  complaintId: number,
  getToken: any
): QueryObserverResult<IllnessModifyingFactor[], unknown> => {
  return useQuery<IllnessModifyingFactor[]>(
    ['illness-modifying-factors', complaintId],
    async () => await getIllnessModifyingFactors(complaintId, getToken),
    {
      onSuccess: (data) => {},
      initialData: [],
    }
  )
}

export const useQueryGetExaminations = (
  getToken: any,
  date: Date
): QueryObserverResult<ExamNode[], unknown> => {
  const { examinations, setExaminations } = useReferenceDataStore()

  return useQuery<ExamNode[]>(
    ['examinations'],
    async () => {
      if (Object.keys(examinations).length) return examinations
      return convertToTree(await getExaminations(getToken, date))
    },
    {
      onSuccess: (data) => {
        if (examinations.length === 0) setExaminations(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetExamResultFactors = (
  getToken: any
): QueryObserverResult<ExamResultFactor[], unknown> => {
  const { examResultFactors, setExamResultFactors } = useReferenceDataStore()

  return useQuery<ExamNode[]>(
    ['examResultFactors'],
    async () => {
      if (examResultFactors.length) return examResultFactors
      return await getExamResultFactors(getToken)
    },
    {
      onSuccess: (data) => {
        if (examResultFactors.length === 0) setExamResultFactors(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetRosResultFactors = (
  getToken: any
): QueryObserverResult<RosResultFactor[], unknown> => {
  const { rosResultFactors, setRosResultFactors } = useReferenceDataStore()

  return useQuery<ExamNode[]>(
    ['rosResultFactors'],
    async () => {
      if (rosResultFactors.length) return rosResultFactors
      return await getRosResultFactors(getToken)
    },
    {
      onSuccess: (data) => {
        if (rosResultFactors.length === 0) setRosResultFactors(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetClinicalProcedureFields = (
  clinicalProcedureId: number,
  getToken: any
): QueryObserverResult<ClinicalProcedureField[], unknown> => {
  const { clinicalProcedureFields, setClinicalProcedureFields } =
    useReferenceDataStore()

  return useQuery<ClinicalProcedureField[]>(
    ['clinicalProcedureFields'],
    async () => {
      if (clinicalProcedureFields.length) return clinicalProcedureFields
      return await getClinicalProcedureFields(clinicalProcedureId, getToken)
    },
    {
      onSuccess: (data) => {
        if (clinicalProcedureFields.length === 0) {
          setClinicalProcedureFields(data)
        }
      },
      initialData: [],
    }
  )
}

export const useQueryGetMeasurementUnits = (
  getToken: any
): QueryObserverResult<MeasurementUnit[], unknown> => {
  return useQuery<MeasurementUnit[]>(
    ['measurement-units'],
    async () => {
      return await getMeasurementUnits(getToken)
    },
    {
      onSuccess: (data) => {},
      initialData: [],
    }
  )
}

export const useQueryGetTemperatureMethods = (
  getToken: any
): QueryObserverResult<TemperatureMethod[], unknown> => {
  const { temperatureMethods, setTemperatureMethods } = useReferenceDataStore()

  return useQuery<TemperatureMethod[]>(
    ['temperature-methods'],
    async () => {
      if (temperatureMethods.length) return temperatureMethods
      return await getTemperatureMethods(getToken)
    },
    {
      onSuccess: (data) => {
        if (temperatureMethods.length === 0) setTemperatureMethods(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetMenstrualDetails = (
  getToken: any
): QueryObserverResult<MenstruationDetails[], unknown> => {
  const { menstrualDetails, setMenstrualDetails } = useReferenceDataStore()

  return useQuery<MenstruationDetails[]>(
    ['menstrual-details'],
    async () => {
      if (menstrualDetails.length) return menstrualDetails
      return await getMenstruationDetails(getToken)
    },
    {
      onSuccess: (data) => {
        if (menstrualDetails.length === 0) setMenstrualDetails(data)
      },
      initialData: [],
    }
  )
}
