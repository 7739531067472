import { type PublicAppointment } from '@/types/PublicAppointment'
import { type paths } from '../generated/schema'
import { Fetcher } from 'openapi-typescript-fetch'
import type { GetToken } from '@clerk/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetPublicAppointments = fetcher
  .path('/api/PublicCreatedAppointment')
  .method('get')
  .create()

export interface Params {
  clinicId: number;
  startDate: string;
  endDate: string;
  getToken: any;
}

export const getPublicAppointments = async ({
  clinicId,
  startDate,
  endDate,
  getToken,
}: Params): Promise<PublicAppointment[]> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (
      (await setupGetPublicAppointments({ clinicId, startDate, endDate }))
        .data ?? []
    )
  } catch (error) {
    if (error instanceof setupGetPublicAppointments.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage
      }
    }
    return []
  }
}

const setupDeletePublicAppointments = fetcher
  .path('/api/PublicCreatedAppointment/{appointmentId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deletePublicAppointments = async (
  appointmentId: number,
  getToken: GetToken
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' }))!}`,
      },
    },
  })
  try {
    await setupDeletePublicAppointments({ appointmentId })
    return true
  } catch (error) {
    if (error instanceof setupDeletePublicAppointments.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
