import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type AccountUser, type Operation } from '../types/AccountUser'
import { type AccountUserPatientVisitUpdate } from '@/types/AccountUserPatientVisitUpdate'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetAccountUser = fetcher
  .path('/api/AccountUser/{accountUserId}')
  .method('get')
  .create()

export const getAccountUser = async (
  accountUserId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetAccountUser({ accountUserId })).data
  } catch (error) {
    if (error instanceof setupGetAccountUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetAccountUserPatientVisitHistory = fetcher
  .path('/api/AccountUser/PatientVisitHistory/{userId}')
  .method('get')
  .create()

export const getAccountUserPatientVisitHistory = async (
  userId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetAccountUserPatientVisitHistory({ userId })).data
  } catch (error) {
    if (error instanceof setupGetAccountUserPatientVisitHistory.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupPutAccountUserPatientVisitHistory = fetcher
  .path('/api/AccountUser/PatientVisitHistory/{userId}')
  .method('put')
  .create({
    'api-version': undefined,
  })

export const putAccountUserPatientVisitHistory = async (
  userId: number,
  accountUserPatientVisitUpdate: AccountUserPatientVisitUpdate,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupPutAccountUserPatientVisitHistory({
      userId,
      ...accountUserPatientVisitUpdate,
    })
  } catch (error) {
    if (error instanceof setupGetAccountUserPatientVisitHistory.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetAccountUsers = fetcher
  .path('/api/AccountUser')
  .method('get')
  .create()

export const getAccountUsers = async (getToken: any): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetAccountUsers({})).data
  } catch (error) {
    if (error instanceof setupGetAccountUsers.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createUser = fetcher
  .path('/api/AccountUser')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewUser = async (
  user: AccountUser,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await createUser(user)
  } catch (error) {
    if (error instanceof createUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data // 500 response data
      }
    }
  }
}

const setupDeleteAccountUser = fetcher
  .path('/api/AccountUser/{accountUserId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteAccountUser = async (
  accountUserId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeleteAccountUser({ accountUserId })
  } catch (error) {
    if (error instanceof setupDeleteAccountUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateAccountUser = fetcher
  .path('/api/AccountUser/{accountUserId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateAccountUser = async (
  accountUserId: number,
  user: AccountUser,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    const res = await setupUpdateAccountUser({ accountUserId, ...user })
    return res
  } catch (error) {
    if (error instanceof setupUpdateAccountUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data // 500 response data
      }
    }
  }
}

export const updateAccountUserSelf = async (
  accountUserId: number,
  operations: Operation[],
  getToken: any
): Promise<any> => {
  const API_URL = process.env.API_URL
  const token = await getToken({ template: 'UrgentIQ' })

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token as string}`,
    Origin: 'null',
  }

  const url = `${API_URL!}/api/AccountUser/${accountUserId}`

  const body = JSON.stringify(operations)
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers,
      body,
    })

    if (!response.ok) {
      const errorData = await response.json()
      if (response.status === 400) {
        throw new Error(`Validation errors: ${JSON.stringify(errorData)}`)
      } else if (response.status === 500) {
        throw new Error(`Server error: ${JSON.stringify(errorData)}`)
      }
    }
    // no data to return on success
  } catch (error) {
    console.error('Error making the PATCH request:', error)
    throw error // Rethrow the error for further handling, if necessary
  }
}
