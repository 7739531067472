export * from './intake'
export * from './procedure'

/**
 * Executes a list of promises in order, waiting for each to complete before beginning the next
 * @param tasks list of async functions to execute one at a time
 * @returns list of the promises' results in order
 */
export const executeSequentially = async (
  tasks: Array<() => Promise<void>>
): Promise<void> => {
  for (const task of tasks) {
    await task()
  }
}
