import { formatISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const formatInUTC = (date: Date | string): string => {
  const dateValue = new Date(date)
  return dateValue.toUTCString()
}
export const formatInLocal = (date: string): string => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return formatISO(utcToZonedTime(date, timezone))
}
