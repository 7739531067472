import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type VisitDischarge } from '../types/VisitDischarge'

const fetcher = Fetcher.for<paths>()

const setupUpdateVisitDischarge = fetcher
  .path('/api/VisitDischarge/{vId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateVisitDischarge = async (
  visitId: number,
  visitDischarge: VisitDischarge,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (
      await setupUpdateVisitDischarge({ vId: visitId, ...visitDischarge })
    ).ok
  } catch (error) {
    if (error instanceof setupUpdateVisitDischarge.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data // 500 response data
      }
      throw errorType.data
    }
  }
}
