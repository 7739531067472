import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import { getAuditTrail } from '@/services'
import { type AuditRecord } from '@/types'

interface AuditTrailState {
  auditTrail: AuditRecord[] | [];
  setAuditTrail: (auditTrail: AuditRecord[]) => void;
}

export const useAuditTrailStore = create<AuditTrailState>()(
  devtools((set) => ({
    auditTrail: [],
    setAuditTrail: (entries: AuditRecord[]) => set({ auditTrail: entries }),
  }))
)

export const useQueryGetAuditTrail = (
  visitId: number,
  patientId: number,
  getToken: any
): QueryObserverResult<AuditRecord[], unknown> => {
  const { setAuditTrail } = useAuditTrailStore()

  return useQuery<AuditRecord[]>(
    ['audit-trail', visitId, patientId],
    async () => await getAuditTrail(visitId, patientId, getToken),
    {
      enabled: !!visitId && !!patientId,
      onSuccess: (data) => {
        setAuditTrail(data)
      },
      initialData: [],
    }
  )
}
