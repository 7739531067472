import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

import { type GetToken } from '@clerk/types'

import {
  type UIQDocument,
  type UrgentIQDocument,
  type UrgentIQDocuments,
} from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetDocument = fetcher
  .path('/api/Document/{documentId}')
  .method('get')
  .create()

export const getDocument = async (
  documentId: number,
  getToken: any
): Promise<UrgentIQDocument> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })

  return (await setupGetDocument({ documentId })).data
}

// same as above, but the returned document is guaranteed to have an ID
export const getDocumentById = async (
  documentId: number,
  getToken: any
): Promise<UIQDocument> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })

  return (await setupGetDocument({ documentId })).data as UIQDocument
}

const setupGetDocuments = fetcher.path('/api/Document').method('get').create()

export const getDocuments = async (
  getToken: any,
  pageNumber?: number,
  pageSize?: number,
  sortColumn?: 0 | 1 | 2 | 3,
  sortAscending?: boolean,
  documentTypeId?: number[],
  accountUserId?: number[],
  documentName?: string,
  patientId?: number,
  clinicId?: number,
  visitId?: number
): Promise<UrgentIQDocuments> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    const response = await setupGetDocuments({
      pageNumber,
      pageSize,
      sortColumn,
      sortAscending,
      documentTypeId,
      accountUserId,
      documentName,
      patientId,
      clinicId,
      visitId,
    })
    return response.data
  } catch (error) {
    if (error instanceof setupGetDocument.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
    return {}
  }
}

const setupGetClinicDocuments = fetcher
  .path('/api/Document/Clinic/{clinicId}')
  .method('get')
  .create()

export const getClinicDocuments = async (
  clinicId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetClinicDocuments({ clinicId })).data
  } catch (error) {
    if (error instanceof setupGetClinicDocuments.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetDocumentsByType = fetcher
  .path('/api/Document/Letter/{clinicId}')
  .method('get')
  .create()

export const getDocumentsByType = async (
  clinicId: number,
  documentTypeId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetDocumentsByType({ clinicId, documentTypeId })).data
  } catch (error) {
    if (error instanceof setupGetDocumentsByType.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetDocumentsByProcedure = fetcher
  .path('/api/Document/VisitClinicalProcedure/{visitClinicalProcedureId}')
  .method('get')
  .create()

export const getDocumentsByProcedure = async (
  visitClinicalProcedureId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetDocumentsByProcedure({ visitClinicalProcedureId }))
      .data
  } catch (error) {
    if (error instanceof setupGetDocumentsByProcedure.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetPatientDocuments = fetcher
  .path('/api/Document/Patient/{patientId}')
  .method('get')
  .create()
export const getPatientDocuments = async (
  patientId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientDocuments({ patientId })).data
  } catch (error) {
    if (error instanceof setupGetPatientDocuments.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetDemoLabel = fetcher
  .path('/api/DocumentId/DemographicLabel/{visitId}')
  .method('get')
  .create()

export const getDemoLabel = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetDemoLabel({ visitId })).data
  } catch (error) {
    if (error instanceof setupGetDemoLabel.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetDemoPrintOff = fetcher
  .path('/api/DocumentId/DemographicPrintOff/{visitId}')
  .method('get')
  .create()

export const getDemoPrintOff = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetDemoPrintOff({ visitId })).data
  } catch (error) {
    if (error instanceof setupGetDemoPrintOff.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetDischargeInstructionsPrintOff = fetcher
  .path('/api/DocumentId/AiVisitSummary/{visitId}')
  .method('get')
  .create()

export const getDischargeInstructionPrintOff = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetDischargeInstructionsPrintOff({ visitId })).data
  } catch (error) {
    if (error instanceof setupGetDischargeInstructionsPrintOff.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetDocumentTypes = fetcher
  .path('/api/ReferenceData/DocumentType')
  .method('get')
  .create()

export const getDocumentTypes = async (getToken: any): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetDocumentTypes({})).data
  } catch (error) {
    if (error instanceof setupGetDocumentTypes.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetVistSummary = fetcher
  .path('/api/DocumentId/VisitSummary/{visitId}')
  .method('get')
  .create()

export const getVisitSummary = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetVistSummary({ visitId })).data
  } catch (error) {
    if (error instanceof setupGetDocumentTypes.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupSummaryResponse = fetcher
  .path('/api/DocumentId/AiVisitSummary/ResponseText/{visitId}')
  .method('get')
  .create()

export const getSummaryText = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupSummaryResponse({ visitId })).data
  } catch (error) {
    if (error instanceof setupGetDocumentTypes.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateDocument = fetcher
  .path('/api/Document/{documentId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateDocument = async (
  documentId: number,
  document: UrgentIQDocument,
  getToken: any
): Promise<any> => {
  const authString = `Bearer ${
    (await getToken({ template: 'UrgentIQ' })) as string
  }`
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: authString,
      },
    },
  })
  try {
    const res = await setupUpdateDocument({ documentId, ...document })
    return res
  } catch (error) {
    if (error instanceof setupUpdateDocument.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data // 500 response data
      }
    }
  }
}

const setupDeleteDocument = fetcher
  .path('/api/Document/{documentId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteDocument = async (
  documentId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  return await setupDeleteDocument({ documentId })
}

export const uploadDocument = async (
  getToken: GetToken,
  document: FormData
): Promise<any> => {
  const res = await fetch(`${process.env.API_URL ?? ''}/api/Document`, {
    method: 'POST',
    headers: {
      origin: 'null',
      Authorization: `Bearer ${(await getToken({
        template: 'UrgentIQ',
      }))!}`,
    },
    body: document,
  })
  const data = await res.json()
  return data
}
