import * as React from 'react'
import type { AppProps } from 'next/app'
import { CacheProvider, type EmotionCache } from '@emotion/react'
import {
  ThemeProvider,
  type Theme,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import '@mobiscroll/react/dist/css/mobiscroll.scss'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/lato/400.css'
import '@fontsource/lato/700.css'
import 'styles/globals.css'
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from '@clerk/nextjs'
import { usePathname } from 'next/navigation'
import createEmotionCache from '@/utility/createEmotionCache'
import theme from '@/styles/theme/theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { type AccountUser } from '@/types'
import ErrorBoundary from '@/components/ErrorBoundary'
import Head from 'next/head'
import { useGlobalStore } from '@/hook'
import { Toast } from '@/components/Toast/Toast'
import { ScheduleProvider } from '@/context/SchedulingContext'
import { isEmpty } from '@/utility'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}
export const AccountUserContext = React.createContext<AccountUser>({})
const clientSideEmotionCache = createEmotionCache()

const MyApp: React.FunctionComponent<MyAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const accountUser = {}
  const pathname = usePathname()
  const publicRoutes = ['online-appointment', 'confirm-appointment']
  const [isPublicRoute, setIsPublicRoute] = React.useState<boolean>(false)
  const {
    setIsEditing,
    setNextLocation,
    setIsUnsavedDataPopupOpen,
    isEditing,
  } = useGlobalStore()

  React.useEffect(() => {
    if (isEmpty(pathname)) return
    const isPublicRoute = publicRoutes.some((route) =>
      pathname?.includes(route)
    )
    setIsPublicRoute(isPublicRoute)
  }, [pathname])

  React.useEffect(() => {
    setIsEditing(false)
    setNextLocation(null)
    setIsUnsavedDataPopupOpen(false)
    useGlobalStore.getState().updateDatesAtMidnight()
  }, [])

  React.useEffect(() => {
    const handleBeforeUnload = (e: any): void => {
      if (isEditing) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isEditing])

  const renderInWrapper = (): JSX.Element => (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AccountUserContext.Provider value={accountUser}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CacheProvider value={emotionCache}>
              <ScheduleProvider>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <>
                      <Component {...pageProps} />
                      <Toast />
                    </>
                  </ThemeProvider>
                </StyledEngineProvider>
              </ScheduleProvider>
            </CacheProvider>
          </LocalizationProvider>
        </AccountUserContext.Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
  return (
    <>
      <Head>
        <link
          rel="UrgentIQ Icon"
          href="/UrgentIQ_handover_white_bacground_bigger_stethascope.jpg"
        />
      </Head>
      <ClerkProvider {...pageProps}>
        <SignedIn>{renderInWrapper()}</SignedIn>
        <SignedOut>
          {isPublicRoute ? renderInWrapper() : <RedirectToSignIn />}
        </SignedOut>
      </ClerkProvider>
    </>
  )
}
export default MyApp
