export const ADMIN = 6
export const URGENT_IQ_EMPLOYEE = 5

export const EXAM_NORMAL = 'Normal'
export const EXAM_ABNORMAL = 'Abnormal'
export const EXAM_EMPTY = 'Empty'

export const ROS_EMPTY = 'Empty'
export const ROS_REPORTS = 'Patient Reports'
export const ROS_DENIES = 'Patient Denies'

export const specialties = [
  {
    name: 'EPCS',
    value: 2048,
  },
  {
    name: 'TwentyFourHourPharmacy',
    value: 64,
  },
  {
    name: 'LongTermCarePharmacy',
    value: 32,
  },
  {
    name: 'MailOrder',
    value: 1,
  },
  {
    name: 'Retail',
    value: 8,
  },
  {
    name: 'SpecialtyPharmacy',
    value: 16,
  },
]

export const IS_METRIC_STORAGE_KEY = 'charts-use-metric-weight'
