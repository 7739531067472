import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { useQuery, type QueryObserverResult } from '@tanstack/react-query'
import { getDailyPatientSummary } from '@/services'

interface DailyPatientSummaryState {
  dailyPatientSummary: any | null;
  setDailyPatientSummary: (dailyPatientSummary: any) => void;
}

export const useDailyPatientSummaryStore = create<DailyPatientSummaryState>()(
  devtools(
    persist(
      (set) => ({
        dailyPatientSummary: null,
        setDailyPatientSummary: (summary: any) =>
          set({ dailyPatientSummary: summary }),
      }),
      {
        name: 'daily-patient-summary',
      }
    )
  )
)

export const useQueryGetDailyPatientSummary = (
  summaryDate: string,
  clinicId: number,
  getToken: any
): QueryObserverResult<any, unknown> => {
  const { setDailyPatientSummary } = useDailyPatientSummaryStore()

  return useQuery<any>(
    ['daily-patient-summary', clinicId, summaryDate],
    async () => await getDailyPatientSummary(summaryDate, clinicId, getToken),
    {
      enabled: !!clinicId,
      onSuccess: (data) => {
        setDailyPatientSummary(data)
      },
    }
  )
}
