import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getExternalOrder,
  updateExternalOrders,
  deleteExternalOrders,
} from '@/services'
import { type ExternalOrder } from '@/types/ExternalOrder'

interface ExternalOrdersState {
  hpi: ExternalOrder[] | [];
  setExternalOrders: (hpi: ExternalOrder[]) => void;
}

export const useExternalOrdersStore = create<ExternalOrdersState>()(
  devtools((set) => ({
    hpi: [],
    setExternalOrders: (hpi: ExternalOrder[]) => set({ hpi }),
  }))
)

export const useQueryGetExternalOrders = (
  vId: number,
  getToken: any
): QueryObserverResult<ExternalOrder[], unknown> => {
  const { setExternalOrders } = useExternalOrdersStore()

  return useQuery<ExternalOrder[]>(
    ['externalOrders', vId],
    async () => await getExternalOrder(vId, getToken),
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setExternalOrders(data)
      },
      initialData: [],
    }
  )
}

export const useMutateUpdateExternalOrders = (
  getToken: any
): UseMutationResult<
  any,
  unknown,
  { updatedExternalOrders: ExternalOrder }
> => {
  return useMutation<any, unknown, { updatedExternalOrders: ExternalOrder }>(
    async ({ updatedExternalOrders }) =>
      await updateExternalOrders(updatedExternalOrders, getToken),
    {
      onSuccess: (data) => {},
    }
  )
}

export const useMutateDeleteExternalOrders = (
  getToken: any
): UseMutationResult<any, unknown, { id: number }> => {
  return useMutation<any, unknown, { id: number }>(
    async ({ id }) => await deleteExternalOrders(id, getToken),
    {
      onSuccess: (data) => {},
    }
  )
}
