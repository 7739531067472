import { getClinicalProcedureFields } from '@/services'
import {
  type ProcedureCPTCode,
  type ProcedureType,
  type SubsectionValue,
} from '@/types'
import { dateOnlyToDate, parseDateToDateOnly, isEmpty } from '@/utility'
import { format } from 'date-fns'

export const generateSchemas = (
  objects: any[],
  topName: string,
  topDescription: string
): any => {
  const jsonSchema: any = {
    type: 'object',
    properties: {},
    required: [],
  }

  const uiSchema: any = {
    'ui:order': [], // Here we'll keep track of the order of properties
  }

  const sortedObjects = [...objects].sort((a, b) => a.sortIndex - b.sortIndex)

  sortedObjects.forEach((obj) => {
    const propertyKey = obj.id.toString()
    // Add to the 'ui:order' array to maintain the sort order in the UI
    uiSchema['ui:order'].push(propertyKey)

    const dataType = obj.dataType.toLowerCase()

    jsonSchema.properties[propertyKey] = {
      title: obj.name,
      type: getJsonSchemaType(obj.dataType),
      enum: ['singleenum', 'singlesmallenum', 'multienum'].includes(dataType)
        ? obj.dataTypeValue
        : undefined,
      default:
        Array.isArray(obj.dataTypeValue) && obj.dataTypeValue.length > 0
          ? ['singleenum', 'singlesmallenum', 'multienum'].includes(
              obj.dataType.toLowerCase()
            )
            ? undefined
            : obj.dataTypeValue[0]
          : undefined,
    }

    if (obj.required) {
      jsonSchema.required.push(propertyKey)
    }

    uiSchema[propertyKey] = getUiWidget(obj.dataType, obj.dataTypeValue)
  })

  // Add asterisk at the end of 'ui:order' array to include any properties not explicitly mentioned
  uiSchema['ui:order'].push('*')

  return { jsonSchema, uiSchema }
}

const getJsonSchemaType = (dataType?: string): string => {
  switch (dataType?.toLowerCase()) {
    case 'boolean':
    case 'positivenegative':
      return 'boolean'
    case 'string':
    case 'singlesmallenum': // Assuming 'SingleSmallEnum' is represented as a string in JSON Schema
    case 'singleenum': // Assuming 'SingleEnum' is represented as a string in JSON Schema
    case 'header': // 'Header' is represented as a string in JSON Schema
      return 'string'
    case 'int':
      return 'integer'
    case 'float':
      return 'number'
    case 'date':
      return 'string' // JSON Schema represents dates as strings
    case 'multienum':
      return 'string' // 'MultiEnum' is an array of strings
    case 'timestamp': // Assuming 'Timestamp' is represented as a string in JSON Schema
    default:
      return 'string' // Default to 'string' for any unspecified or unknown types
  }
}

const getUiWidget = (dataType?: string, dataTypeValue?: any[]): any => {
  switch (dataType?.toLowerCase()) {
    case 'boolean':
    case 'positivenegative':
      return { 'ui:widget': 'checkbox' }
    case 'string':
      return { 'ui:widget': 'text' }
    case 'integer':
      return { 'ui:widget': 'updown' }
    case 'singleenum':
      // Return a select widget configuration, use dataTypeValue for 'ui:enum'
      return {
        'ui:widget': 'select',
        'ui:options': {
          enumOptions: dataTypeValue?.map((value) => ({ label: value, value })),
        },
      }
    case 'singlesmallenum':
      return { 'ui:widget': 'radio' }
    case 'date':
      return {
        'ui:widget': 'DatePickerWidget',
        'ui:options': { format: 'date' },
      }
    case 'timestamp':
      return {
        'ui:widget': 'DateTimePickerWidget',
        'ui:options': { format: 'timestamp' },
      }
    case 'header':
      return { 'ui:widget': 'text', 'ui:disabled': true }
    case 'multienum':
      return {
        'ui:widget': 'MultiselectWidget',
        'ui:options': {
          enumOptions: dataTypeValue?.map((value) => ({ label: value, value })),
          multiple: true,
          value: [],
        },
      }
    default:
      console.error(`Unknown data type: ${dataType ?? ''}`)
      return {}
  }
}

// Maps formData to an array of VisitClinicalProcedureFieldDTO objects
export const mapFormDataToDTO = (
  formData: any,
  mappingObject: any,
  clinicalProcedureId: number
): any =>
  Object.entries(formData).map(([key, value]) => {
    const field = mappingObject.find((m: any) => m.id.toString() === key)
    let valueType
    /**
     * The value to be formatted.
     */
    let formattedValue: any = value

    switch (field.dataType.toLowerCase()) {
      case 'positivenegative':
      case 'boolean':
        valueType = 'valueBoolean'
        if (value === 'Yes') {
          formattedValue = true
        } else if (value === 'No') {
          formattedValue = false
        }
        break
      case 'int':
        valueType = 'valueInt'
        formattedValue =
          typeof value === 'string' ? parseInt(value, 10) : value
        break
      case 'float':
        valueType = 'valueFloat'
        formattedValue = typeof value === 'string' ? parseFloat(value) : value
        break
      case 'date': // Assuming 'date' is used for date fields
        valueType = 'valueDate'
        const date = new Date(value as string)
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
        formattedValue = parseDateToDateOnly(date)
        break
      case 'timestamp': // Assuming 'timestamp' is used for date-time fields
        valueType = 'valueTimestamp'
        formattedValue = new Date(value as string).toISOString()
        break
      case 'string':
        valueType = 'valueString'
        break
      case 'singlesmallenum':
      case 'singleenum':
        valueType = 'valueEnum'
        break
      case 'multienum':
        valueType = 'valueEnumList'
        break
      default:
        throw new Error(`Unknown data type: ${field.dataType as string}`)
    }

    return {
      clinicalProcedureFieldId: field.id,
      [valueType]: formattedValue,
      clinicalProcedureId,
    }
  })

// Maps an array of VisitClinicalProcedureFieldDTO objects to formData
export const mapDTOToFormData = (dtoObjects: any): any =>
  dtoObjects.reduce((formData: any, dto: any) => {
    const {
      clinicalProcedureFieldId,
      valueBoolean,
      valueInt,
      valueFloat,
      valueDate,
      valueString,
      valueEnum,
      valueTimestamp,
      valueEnumList,
    } = dto

    formData[clinicalProcedureFieldId] =
      valueBoolean ??
      valueInt ??
      valueFloat ??
      dateOnlyToDate(valueDate) ??
      valueString ??
      valueEnum ??
      valueTimestamp ??
      valueEnumList

    return formData
  }, {})

export const getProcedureValue = (obj: any): string => {
  if (obj.valueBoolean === true || obj.valueBoolean === 'Yes') return 'Yes'
  if (obj.valueBoolean === false || obj.valueBoolean === 'No') return 'No'
  if (obj.valueDate) {
    return format(
      new Date(
        obj.valueDate.year ?? 0,
        (obj.valueDate.month ?? 0) - 1,
        obj.valueDate.day ?? 0
      ),
      'MM/dd/yyyy'
    )
  }
  if (obj.valueTimestamp) {
    return format(new Date(obj.valueTimestamp), 'MM/dd/yyyy hh:mm')
  }
  if (obj.valueFloat) return parseFloat(obj.valueFloat.toFixed(2)).toString()
  if (!isEmpty(obj.valueEnum)) return obj.valueEnum as string
  if (!isEmpty(obj.valueString)) return obj.valueString as string
  if (Array.isArray(obj.valueEnumList) && obj.valueEnumList.length > 0) {
    return obj.valueEnumList.join(', ')
  }
  return (obj.valueInt ?? 0).toString()
}

export const buildCPTSections = async (
  cptCodes: ProcedureCPTCode[],
  sections: any[],
  getToken: any,
  procedureTypes: any,
  CPTModifiers: any
): Promise<SubsectionValue[]> => {
  let previousId = 0
  for (const visitProcedure of cptCodes) {
    const pt: ProcedureType = procedureTypes?.find(
      (type: any) => type.id === visitProcedure?.clinicalProcedureId
    ) ?? {
      name: '',
      code: '',
    }
    const cptCode: string = visitProcedure?.cptCodeOther ?? ''
    const modifier: string =
      visitProcedure?.visitClinicalProcedureCptModifierCodeList
        ?.map((mods: any) => {
          return (
            CPTModifiers?.find((mod: any) => mod.id === mods.cptModifierCodeId)
              ?.code ?? ''
          )
        })
        .join(', ') ?? ''

    if (visitProcedure.id !== previousId) {
      if (visitProcedure?.clinicalProcedureId === null) {
        sections.push({
          Procedure: `E\\M Code ${cptCode} ${modifier}`,
        })
      } else {
        let formValue = ''
        const fields = await getClinicalProcedureFields(
          visitProcedure.clinicalProcedureId ?? 0,
          getToken
        )
        // Using for...of instead of forEach to handle async/await
        for (const field of visitProcedure?.visitClinicalProcedureFieldList ??
          []) {
          const fieldData = fields.find(
            (f: any) => f.id === field.clinicalProcedureFieldId
          )
          formValue += `${
            (fieldData?.name ?? '') as string
          }: ${getProcedureValue(field)}\n`
        }

        sections.push({
          Procedure:
            `${pt.name ?? ''} - ${pt.category ?? ''} - ` +
            `${cptCode}${modifier ? ` - ${modifier}` : ''}` +
            `${formValue ? `\n${formValue}` : '\n'}` +
            `${
              visitProcedure?.textResult
                ? `Note - ${visitProcedure.textResult}\n`
                : ''
            }`,
        })
      }
      previousId = visitProcedure.id ?? 0
    }
  }

  return sections
}
