import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type ExternalProvider } from '../types/ExternalProvider'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetExternalProvider = fetcher
  .path('/api/ExternalProvider/{externalProviderId}')
  .method('get')
  .create()

export const getExternalProvider = async (
  externalProviderId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetExternalProvider({ externalProviderId })).data
  } catch (error) {
    if (error instanceof setupGetExternalProvider.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetExternalProviders = fetcher
  .path('/api/ExternalProvider')
  .method('get')
  .create()

export const getExternalProviders = async (getToken: any): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetExternalProviders({})).data
  } catch (error) {
    if (error instanceof setupGetExternalProviders.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createExternalProvider = fetcher
  .path('/api/ExternalProvider')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewExternalProvider = async (
  provider: ExternalProvider,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await createExternalProvider(provider)).data
  } catch (error) {
    if (error instanceof createExternalProvider.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeleteExternalProvider = fetcher
  .path('/api/ExternalProvider/{externalProviderId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteExternalProvider = async (
  externalProviderId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeleteExternalProvider({ externalProviderId })
  } catch (error) {
    if (error instanceof setupGetExternalProvider.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateExternalProvider = fetcher
  .path('/api/ExternalProvider/{externalProviderId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateExternalProvider = async (
  externalProviderId: number,
  ExternalProvider: ExternalProvider,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupUpdateExternalProvider({
      externalProviderId,
      ...ExternalProvider,
    })
  } catch (error) {
    if (error instanceof setupGetExternalProvider.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupSearchExternalProviders = fetcher
  .path('/api/ExternalProvider/Search')
  .method('get')
  .create()

export const searchExternalProviders = async (
  query: string,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupSearchExternalProviders({ query })).data
  } catch (error) {
    if (error instanceof setupSearchExternalProviders.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
