import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import { getWorkersComp, addWorkersComp } from '@/services'
import { type WorkersComp } from '@/types'
import { isEmpty } from '@/utility'

interface WorkersCompState {
  workersComp: WorkersComp | null;
  setWorkersComp: (workersComp: WorkersComp) => void;
}

export const useWorkersCompStore = create<WorkersCompState>()(
  devtools((set) => ({
    workersComp: null,
    setWorkersComp: (workersComp: WorkersComp) => set({ workersComp }),
  }))
)

export const useQueryGetWorkersComp = (
  visitId: number,
  getToken: any
): QueryObserverResult<WorkersComp, unknown> => {
  const { setWorkersComp } = useWorkersCompStore()

  return useQuery<WorkersComp>(
    ['workers-comp'],
    async () => await getWorkersComp(visitId, getToken),
    {
      enabled: !!visitId,
      onSuccess: (data) => {
        setWorkersComp(data)
        return data
      },
    }
  )
}

export const useMutateAddWorkersComp = (
  getToken: any
): UseMutationResult<
  WorkersComp,
  unknown,
  { visitId: number; workersComp: WorkersComp }
> => {
  const { setWorkersComp } = useWorkersCompStore()

  return useMutation<
    WorkersComp,
    unknown,
    { visitId: number; workersComp: WorkersComp }
  >(
    async ({ visitId, workersComp }) => {
      if (!visitId || isEmpty(workersComp)) return
      return await addWorkersComp(visitId, workersComp, getToken)
    },
    {
      onSuccess: (data) => {
        setWorkersComp(data)
        return data
      },
    }
  )
}
