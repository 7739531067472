import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getPreventativeMedNotes,
  getPatientPreventativeMedNotes,
  updatePreventativeMedNotes,
} from '@/services'
import { type PreventativeMedNotes } from '@/types'

interface PreventativeMedNotesState {
  preventativeMedNotes: PreventativeMedNotes | undefined;
  setPreventativeMedNotes: (
    preventativeMedNotes: PreventativeMedNotes | undefined
  ) => void;
  preventativeMedNotesAll: PreventativeMedNotes[] | [];
  setPreventativeMedNotesAll: (
    preventativeMedNotes: PreventativeMedNotes[]
  ) => void;
}

export const usePreventativeMedNotesStore = create<PreventativeMedNotesState>()(
  devtools((set) => ({
    preventativeMedNotes: undefined,
    setPreventativeMedNotes: (
      preventativeMedNotes: PreventativeMedNotes | undefined
    ) => set({ preventativeMedNotes }),
    preventativeMedNotesAll: [],
    setPreventativeMedNotesAll: (
      preventativeMedNotes: PreventativeMedNotes[]
    ) => set({ preventativeMedNotesAll: preventativeMedNotes }),
  }))
)

export const useQueryGetPreventativeMedNotes = (
  vId: number,
  getToken: any
): QueryObserverResult<PreventativeMedNotes, unknown> => {
  const { setPreventativeMedNotes } = usePreventativeMedNotesStore()
  const key = ['preventative-med-notes-', vId]
  return useQuery<PreventativeMedNotes>(key, async () =>
    await getPreventativeMedNotes(vId, getToken)
  , {
    enabled: !!vId,
    onSuccess: (data) => {
      setPreventativeMedNotes(data)
    },
    initialData: undefined
  })
}

export const useQueryGetPatientPreventativeMedNotes = (
  patientId: number,
  getToken: any
): QueryObserverResult<PreventativeMedNotes, unknown> => {
  return useQuery<PreventativeMedNotes, unknown>(
    ['preventativeMedNotes', patientId],
    async () => {
      return await getPatientPreventativeMedNotes(patientId, getToken)
    }
  )
}

export const useMutateUpdatePreventativeMedNotes = (
  patientId: number,
  getToken: any,
  visitId: number
): UseMutationResult<
  PreventativeMedNotes,
  unknown,
  PreventativeMedNotes,
  unknown
> => {
  const { setPreventativeMedNotes } = usePreventativeMedNotesStore()
  return useMutation<PreventativeMedNotes, unknown, PreventativeMedNotes, unknown>(
    async (preventativeMedNotes) => {
      return await updatePreventativeMedNotes(visitId, { ...preventativeMedNotes, patientId }, getToken)
    },
    {
      onSuccess: (data) => {
        setPreventativeMedNotes(data)
      },
      onError: (error) => {
        console.error('Error updating preventative med notes:', error)
      },
    }
  )
}
