import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type RecurringAppointment } from '../types/RecurringAppointment'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetAppointmentsByGroupId = fetcher
  .path('/api/ClinicCreatedAppointment/Group/{groupId}')
  .method('get')
  .create()

export const getAppointmentsByGroupId = async (
  groupId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetAppointmentsByGroupId({ groupId })).data
  } catch (error) {
    if (error instanceof setupGetAppointmentsByGroupId.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetAllAppointments = fetcher
  .path('/api/ClinicCreatedAppointment')
  .method('get')
  .create()

export const getAllAppointments = async (
  getToken: any,
  startDate?: string,
  endDate?: string,
  clinicId?: number,
  patientId?: number
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (
      await setupGetAllAppointments({ startDate, endDate, clinicId, patientId })
    ).data
  } catch (error) {
    if (error instanceof setupGetAllAppointments.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetAppointmentById = fetcher
  .path('/api/ClinicCreatedAppointment/{appointmentId}')
  .method('get')
  .create()

export const getAppointmentById = async (
  appointmentId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetAppointmentById({ appointmentId })).data
  } catch (error) {
    if (error instanceof setupGetAppointmentById.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupCreateRecurringAppointment = fetcher
  .path('/api/ClinicCreatedAppointment')
  .method('post')
  .create({ 'api-version': undefined })

export const createRecurringAppointment = async (
  appointment: RecurringAppointment,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupCreateRecurringAppointment(appointment)).data
  } catch (error) {
    if (error instanceof setupCreateRecurringAppointment.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateRecurringAppointment = fetcher
  .path('/api/ClinicCreatedAppointment/{appointmentId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateRecurringAppointment = async (
  appointmentId: number,
  appointment: RecurringAppointment,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  return await setupUpdateRecurringAppointment({
    appointmentId,
    ...appointment,
  })
}

const setupDeleteAppointmentsByGroupId = fetcher
  .path('/api/ClinicCreatedAppointment/Group/{groupId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteAppointmentsByGroupId = async (
  groupId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeleteAppointmentsByGroupId({ groupId })
  } catch (error) {
    if (error instanceof setupDeleteAppointmentsByGroupId.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeleteAppointmentById = fetcher
  .path('/api/ClinicCreatedAppointment/{appointmentId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteAppointmentById = async (
  appointmentId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeleteAppointmentById({ appointmentId })
  } catch (error) {
    if (error instanceof setupDeleteAppointmentById.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
