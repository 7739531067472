import { useToastStore } from '@/hook/useToast'
import { Alert, Badge, Snackbar } from '@mui/material'
import React from 'react'

export enum ToastSeverity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export const Toast = (): JSX.Element | null => {
  const { currentToast, pendingToasts, nextToast } = useToastStore()
  if (!currentToast) return null

  const onClose = (): void => {
    nextToast()
  }

  return (
    <Badge
      badgeContent={
        pendingToasts.length > 0 ? `+${pendingToasts.length}` : null
      }
    >
      <Snackbar open autoHideDuration={4000} onClose={onClose}>
        <Alert onClose={onClose} severity={currentToast.status}>
          {currentToast.message}
        </Alert>
      </Snackbar>
    </Badge>
  )
}
