import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getPatientSurgery,
  addNewPatientSurgery,
  deletePatientSurgery,
  updatePatientSurgery,
} from '@/services'
import { type PatientSurgery } from '@/types/PatientSurgery'

interface PatientSurgeryState {
  patientSurgeries: PatientSurgery[] | [];
  setPatientSurgeries: (patientSurgeries: PatientSurgery[]) => void;
}

export const usePatientSurgeryStore = create<PatientSurgeryState>()(
  devtools((set) => ({
    patientSurgeries: [],
    setPatientSurgeries: (surgeries: PatientSurgery[]) =>
      set({ patientSurgeries: surgeries }),
  }))
)

export const useQueryGetPatientSurgery = (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): QueryObserverResult<PatientSurgery[], unknown> => {
  const { setPatientSurgeries } = usePatientSurgeryStore()
  const key = visitId
    ? ['patient-surgery', patientId, visitId]
    : ['patient-surgery', patientId]
  return useQuery<PatientSurgery[]>(
    key,
    async () => await getPatientSurgery(patientId, getToken, visitId),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        setPatientSurgeries(data)
      },
      initialData: [],
    }
  )
}

export const useMutateAddPatientSurgery = (
  getToken: any
): UseMutationResult<PatientSurgery, unknown, { surgery: PatientSurgery }> => {
  const { patientSurgeries, setPatientSurgeries } = usePatientSurgeryStore()

  return useMutation<PatientSurgery, unknown, { surgery: PatientSurgery }>(
    async ({ surgery }) => await addNewPatientSurgery(surgery, getToken),
    {
      onSuccess: (data) => {
        setPatientSurgeries([...(patientSurgeries as PatientSurgery[]), data])
      },
    }
  )
}

export const useMutateDeletePatientSurgery = (
  getToken: any
): UseMutationResult<PatientSurgery, unknown, { patientSurgeryId: number }> => {
  const { patientSurgeries, setPatientSurgeries } = usePatientSurgeryStore()

  return useMutation<PatientSurgery, unknown, { patientSurgeryId: number }>(
    async ({ patientSurgeryId }) =>
      await deletePatientSurgery(patientSurgeryId, getToken),
    {
      onSuccess: (data) => {
        const newPatientSurgeries = patientSurgeries.filter(
          (patientSurgery: PatientSurgery): boolean =>
            patientSurgery?.id !== data?.id
        )
        setPatientSurgeries(newPatientSurgeries)
      },
    }
  )
}

export const useMutateUpdatePatientSurgery = (
  getToken: any
): UseMutationResult<
  PatientSurgery,
  unknown,
  { patientSurgeryId: number; surgery: PatientSurgery }
> => {
  const { patientSurgeries, setPatientSurgeries } = usePatientSurgeryStore()

  return useMutation<
    PatientSurgery,
    unknown,
    { patientSurgeryId: number; surgery: PatientSurgery }
  >(
    async ({ patientSurgeryId, surgery }) =>
      await updatePatientSurgery(patientSurgeryId, surgery, getToken),
    {
      onSuccess: (data) => {
        const newPatientSurgeries = patientSurgeries.map(
          (patientSurgery: PatientSurgery): PatientSurgery => {
            if (patientSurgery?.id === data?.id) {
              return data
            }
            return patientSurgery
          }
        )
        setPatientSurgeries(newPatientSurgeries)
      },
    }
  )
}
