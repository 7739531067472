import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
} from '@tanstack/react-query'
import { type VitalDetail, type Vital } from '@/types/Vital'
import { getVital, updateVital } from '@/services'
import { dateOnlyToDate, isDateOnlyValid, isEmpty } from '@/utility'
import { format } from 'date-fns'
import { type TemperatureMethod } from '@/types'
import { type GetToken } from '@clerk/types'

export const mergeDetails = (current: VitalDetail[], updated: VitalDetail[]): VitalDetail[] => {
  const updatedExisting = current.map((detail, index) => {
    const updatedDetail = updated[index]
    return {
      ...detail,
      ...updatedDetail
    }
  })

  const newDetails = updated.slice(current.length)
  return [...updatedExisting, ...newDetails]
}
interface VitalsState {
  chiefVital: Vital | null
  outOfRanges: Record<string, boolean>
  pendingMutation: Partial<Vital>
  isMutating: boolean
  setChiefVital: (chiefVital: Vital | null) => void
  setOutOfRanges: (outOfRanges: Record<string, boolean>) => void
  mutateChiefVital: (visitId: number, chiefVital: Partial<Vital>, getToken: GetToken) => Promise<void>
  executeMutation: (visitId: number, update: Partial<Vital>, getToken: GetToken) => Promise<void>
  buildVitalsSummary: (lmpLabel: string, temperatureMethods: TemperatureMethod[]) => any
  buildOOR: () => Record<string, boolean>
  requiresLMP: () => boolean
}

export const useVitalsStore = create<VitalsState>()(
  devtools((set, get) => ({
    chiefVital: null,
    outOfRanges: {},
    pendingMutation: {},
    isMutating: false,
    setChiefVital: (chiefVital: Vital | null) => set({ chiefVital }),
    setOutOfRanges: (outOfRanges: Record<string, boolean>) => set({ outOfRanges }),
    executeMutation: async (visitId: number, update: Partial<Vital>, getToken: GetToken) => {
      const newDetails: Array<Partial<VitalDetail>> = mergeDetails(get().chiefVital?.vitalDetails ?? [], update.vitalDetails ?? [])
      const payload = {
        ...get().chiefVital,
        ...update,
        vitalDetails: newDetails
      }
      await updateVital(visitId, payload, getToken)
    },
    mutateChiefVital: async (visitId: number, updatedVital: Partial<Vital>, getToken: GetToken) => {
      if (get().isMutating) {
        set({
          pendingMutation: {
            ...get().pendingMutation,
            ...updatedVital,
            vitalDetails: mergeDetails(get().pendingMutation.vitalDetails ?? [], updatedVital.vitalDetails ?? [])
          }
        })
        return
      }
      set({ isMutating: true })

      await get().executeMutation(visitId, updatedVital, getToken)
      const refreshedVital = await getVital(visitId, getToken)
      set({ chiefVital: refreshedVital })
      set({ outOfRanges: get().buildOOR() })

      while (!isEmpty(get().pendingMutation)) {
        const pending = get().pendingMutation
        set({ pendingMutation: {} })
        await get().executeMutation(visitId, pending, getToken)
        const vital = await getVital(visitId, getToken)
        set({ chiefVital: vital })
        set({ outOfRanges: get().buildOOR(), isMutating: isEmpty(get().pendingMutation)  })
      }

      set({ isMutating: false })
    },
    buildVitalsSummary: (lmpLabel: string, temperatureMethods: TemperatureMethod[]) => {
      const { chiefVital: vitals } = get()
      const vitalsSummary: any[] = []

      if (!vitals) return vitalsSummary

      if ((vitals.weight ?? 0) > 0) {
        vitalsSummary.push({
          Weight: `${
            vitals.isMetricWeight
              ? `${vitals.weight} kg (${(vitals.weight ?? 0) > 0 ? ((vitals.weight ?? 0.0) / 0.453592).toFixed(0) : 0} lb)`
              : `${vitals.weight} lb (${(vitals.weight ?? 0) > 0 ? ((vitals.weight ?? 0) * 0.453592).toFixed(2) : 0} kg)`
          }`
        })
      }

      if ((vitals.heightFeet ?? 0) > 0 || (vitals.heightInches ?? 0) > 0) {
        vitalsSummary.push({
          Height: `${vitals.heightFeet ? `${vitals.heightFeet}'` : ''} ${vitals.heightInches ? `${vitals.heightInches}"` : ''}`
        })
      }

      if ((vitals.bmi ?? 0) > 0) {
        vitalsSummary.push({ BMI: `${vitals.bmi?.toFixed(2)}` })
      }

      vitals.vitalDetails?.forEach((vitalDetail, index) => {
        if ((vitalDetail.bpDiastolic ?? 0) > 0 && (vitalDetail.bpSystolic ?? 0) > 0) {
          vitalsSummary.push({
            [`Blood Pressure (Set ${index + 1})`]: `${vitalDetail.bpSystolic}/${vitalDetail.bpDiastolic} mmHg`
          })
        }
        if ((vitalDetail.pulse ?? 0) > 0) {
          vitalsSummary.push({ [`Pulse (Set ${index + 1})`]: `${vitalDetail.pulse} BPM` })
        }
        if (vitalDetail.respiratoryRate) {
          vitalsSummary.push({
            [`Resp. Rate (Set ${index + 1})`]: `${vitalDetail.respiratoryRate} per min`
          })
        }
        if ((vitalDetail.temperature ?? 0) > 0) {
          const method = vitalDetail.temperatureMethodId && vitalDetail.temperatureMethodId > 0
            ? temperatureMethods?.find((method) => method?.id === vitalDetail?.temperatureMethodId)?.displayValue ?? ''
            : ''
          const temperatureEntry = `${vitalDetail.temperature}°F${method ? ` (${method})` : ''}`
          vitalsSummary.push({
            [`Temp. (Set ${index + 1})`]: temperatureEntry
          })
        }
        if (vitalDetail.oxygenSaturation) {
          vitalsSummary.push({ [`Oxygen (Set ${index + 1})`]: `${vitalDetail.oxygenSaturation}%` })
        }
        if (vitalDetail.visualAcuityLeft) {
          vitalsSummary.push({
            [`Visual Acuity, L (Set ${index + 1})`]: `20/${vitalDetail.visualAcuityLeft ?? ''}`
          })
        }
        if (vitalDetail.visualAcuityRight) {
          vitalsSummary.push({
            [`Visual Acuity, R (Set ${index + 1})`]: `20/${vitalDetail.visualAcuityRight ?? ''}`
          })
        }
        if (!isEmpty(vitalDetail.vitalNote)) {
          vitalsSummary.push({ [`Note (Set ${index + 1})`]: vitalDetail.vitalNote })
        }
        if (vitalDetail.vitalDateTime) {
          vitalsSummary.push({
            [`Vitals Taken (Set ${index + 1})`]: (vitalDetail.vitalDateTime
              ? format(new Date(vitalDetail.vitalDateTime), 'M-dd-yyyy h:mm a') : '')
          })
        }
      })

      if (lmpLabel.length > 0) {
        vitalsSummary.push({
          'Last Menstrual Period': lmpLabel
        })
      }
      vitalsSummary.push({
        'Patient Air Source': vitals.needsSupplementalOxygen ? 'Supplemental O2 tank' : 'Room Air'
      })
      return [{ value: vitalsSummary }]
    },
    buildOOR: () => {
      const { chiefVital: vitals } = get()
      const newOutOfRanges: Record<string, boolean> = {}
      if (vitals !== null) {
        const lmp: Date = isDateOnlyValid(vitals?.lastMenstrualPeriodDate)
          ? dateOnlyToDate(vitals?.lastMenstrualPeriodDate)!
          : new Date(vitals.lastMenstrualPeriod ?? new Date()) ?? new Date()
        const differenceInMs: number =
            Math.abs((new Date()).getTime() - lmp.getTime())
        const millisecondsInDay: number = 1000 * 60 * 60 * 24
        const differenceInDays: number =
            Math.floor(differenceInMs / millisecondsInDay)
        newOutOfRanges['Last Menstrual Period'] = differenceInDays > 21

        vitals?.vitalDetails?.forEach((vitalDetail, index) => {
          newOutOfRanges[`Blood Pressure (Set ${index + 1})`] = vitalDetail?.bloodPressureOutOfRange ?? false
          newOutOfRanges[`Pulse (Set ${index + 1})`] = vitalDetail?.pulseOutOfRange ?? false
          newOutOfRanges.Weight = vitals?.weightOutOfRange ?? false
          newOutOfRanges.BMI = vitals?.bmiOutOfRange ?? false
          newOutOfRanges[`Temp. (Set ${index + 1})`] = vitalDetail?.temperatureOutOfRange ?? false
          newOutOfRanges[`Resp. Rate (Set ${index + 1})`] = vitalDetail?.respiratoryRateOutOfRange ?? false
          newOutOfRanges[`Oxygen (Set ${index + 1})`] = vitalDetail?.oxygenSaturationOutOfRange ?? false
        })
      }
      return newOutOfRanges
    },
    requiresLMP: () => {
      const { chiefVital } = get()
      return isEmpty(chiefVital?.lastMenstrualPeriod) &&
        isEmpty(chiefVital?.lastMenstrualPeriodDate) &&
        isEmpty(chiefVital?.menstruationDetailsId)
    }
  }))
)

export const useQueryGetVital = (
  vId: number,
  getToken: any
): QueryObserverResult<Vital, unknown> => {
  const { setChiefVital, setOutOfRanges, buildOOR } = useVitalsStore()

  return useQuery<Vital>(
    ['vital', vId],
    async () => {
      return await getVital(vId, getToken)
    },
    {
      enabled: !!vId,
      onSuccess: (data: Vital) => {
        setChiefVital(data)
        setOutOfRanges(buildOOR())
      }
    }
  )
}
