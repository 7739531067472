import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { updateVisitDischarge } from '@/services'
import { type VisitDischarge } from '@/types/VisitDischarge'

interface VisitDischargeState {
  visitDischarge: VisitDischarge | null;
  setVisitDischarge: (visitDischarge: VisitDischarge) => void;
}

export const useVisitDischargeStore = create<VisitDischargeState>()(
  devtools(
    persist(
      (set) => ({
        visitDischarge: null,
        setVisitDischarge: (visitDischarge: VisitDischarge) =>
          set({ visitDischarge }),
      }),
      {
        name: 'visit-Discharge',
      }
    )
  )
)

export const useMutateUpdateVisitDischarge = (
  getToken: any
): UseMutationResult<
  any,
  unknown,
  { visitId: number; visitDischarge: VisitDischarge }
> => {
  const { setVisitDischarge } = useVisitDischargeStore()

  return useMutation<
    any,
    unknown,
    { visitId: number; visitDischarge: VisitDischarge }
  >(
    async ({ visitId, visitDischarge }) =>
      await updateVisitDischarge(visitId, visitDischarge, getToken),
    {
      onSuccess: (data) => {
        setVisitDischarge(data)
      },
    }
  )
}
