import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  type UseMutationResult,
  useMutation,
} from '@tanstack/react-query'
import { getAssessmentPlan, updateAssessmentPlan } from '@/services'
import { type AssessmentPlan } from '@/types/AssessmentPlan'

interface AssessmentPlanState {
  assessmentPlan: AssessmentPlan | null;
  setAssessmentPlan: (assessmentPlan: AssessmentPlan) => void;
}

export const useAssessmentPlanStore = create<AssessmentPlanState>()(
  devtools((set) => ({
    assessmentPlan: null,
    setAssessmentPlan: (plan: AssessmentPlan) => set({ assessmentPlan: plan }),
  }))
)

export const useQueryGetAssessmentPlan = (
  vId: number,
  getToken: any
): QueryObserverResult<AssessmentPlan, unknown> => {
  const { setAssessmentPlan } = useAssessmentPlanStore()

  return useQuery<AssessmentPlan>(
    ['assessment-plan', vId],
    async () => await getAssessmentPlan(vId, getToken),
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setAssessmentPlan(data)
      },
    }
  )
}

export const useMutateUpdateAssessmentPlan = (
  getToken: any
): UseMutationResult<
  AssessmentPlan,
  unknown,
  { vId: number; assessmentPlan: AssessmentPlan }
> => {
  const { setAssessmentPlan } = useAssessmentPlanStore()

  return useMutation<
    AssessmentPlan,
    unknown,
    { vId: number; assessmentPlan: AssessmentPlan }
  >(
    async ({ vId, assessmentPlan }) =>
      await updateAssessmentPlan(vId, assessmentPlan, getToken),
    {
      onSuccess: (data) => {
        setAssessmentPlan(data)
      },
    }
  )
}
