import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type PatientFamilyHistory } from '../types/PatientFamilyHistory'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetPatientFamilyHistory = fetcher
  .path('/api/PatientFamilyHistory/Patient/{patientId}')
  .method('get')
  .create()

export const getPatientFamilyHistory = async (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientFamilyHistory({ patientId, visitId })).data
  } catch (error) {
    if (error instanceof setupGetPatientFamilyHistory.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createUser = fetcher
  .path('/api/PatientFamilyHistory')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewPatientFamilyHistory = async (
  user: PatientFamilyHistory,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await createUser(user)).data
  } catch (error) {
    if (error instanceof createUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeletePatientFamilyHistory = fetcher
  .path('/api/PatientFamilyHistory/{patientFamilyHistoryId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deletePatientFamilyHistory = async (
  patientFamilyHistoryId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeletePatientFamilyHistory({ patientFamilyHistoryId })
  } catch (error) {
    if (error instanceof setupGetPatientFamilyHistory.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdatePatientFamilyHistory = fetcher
  .path('/api/PatientFamilyHistory/{patientFamilyHistoryId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updatePatientFamilyHistory = async (
  patientFamilyHistoryId: number,
  patientFamilyHistory: PatientFamilyHistory,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupUpdatePatientFamilyHistory({
      patientFamilyHistoryId,
      ...patientFamilyHistory,
    })
  } catch (error) {
    if (error instanceof setupGetPatientFamilyHistory.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
