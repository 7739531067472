import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import { getInsurancePayers } from '@/services'
import { type PVerifyInsurance } from '@/types'
interface InsurancePayerState {
  insurancePayers: PVerifyInsurance[] | [];
  setInsurancePayers: (insurancePayers: PVerifyInsurance[]) => void;
}

export const useInsurancePayerStore = create<InsurancePayerState>()(
  devtools((set) => ({
    insurancePayers: [],
    setInsurancePayers: (payers: PVerifyInsurance[]) =>
      set({ insurancePayers: payers }),
  }))
)

export const useQueryGetInsurancePayers = (
  getToken: any
): QueryObserverResult<PVerifyInsurance[], unknown> => {
  const { setInsurancePayers } = useInsurancePayerStore()

  return useQuery<PVerifyInsurance[]>(
    ['insurance-payer'],
    async () => await getInsurancePayers(getToken),
    {
      onSuccess: (data) => {
        setInsurancePayers(data)
      },
      initialData: [],
    }
  )
}
