import { type paths } from '@/generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

const setupGetIQSearch = fetcher
  .path('/api/IqBar/Search')
  .method('get')
  .create()

export const getIQSearch = async (
  query: string,
  getToken: any,
  fieldName?: string | undefined
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetIQSearch({ query, fieldName })).data
  } catch (error) {
    if (error instanceof setupGetIQSearch.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
