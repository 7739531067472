import { getPatientDocuments, getUrgentIQPharmacy } from '@/services'
import {
  type PatientRegistrationCreate,
  type Insurance,
  type ChiefComplaint,
  type FinancialResponsibleParty,
  type Visit,
  type WorkersComp,
  type OccMed,
  type State,
  type Patient,
} from '@/types'
import {
  formatInUTC,
  isEmpty,
  getSexId,
  getGenderBySexId,
  parseDateToDateOnly,
  isDateOnlyValid,
  dateOnlyToDate,
} from '@/utility'
import { formatISO, isDate } from 'date-fns'

// Helper functions for creating objects
export const createPatientObject = (
  data: any,
  existingPatientId: number,
  initialDOB: any,
  states: State[],
  insuranceIDs: number[],
  insuranceCount: number
): PatientRegistrationCreate => {
  const insuranceList = createInsuranceList(
    data,
    existingPatientId,
    insuranceIDs,
    states,
    insuranceCount
  )
  const patient: PatientRegistrationCreate = {
    ...(existingPatientId > 0 && { id: existingPatientId }),
    firstName: data.firstName,
    lastName: data.lastName,
    // TODO: Figure out why we need to add 100 minutes to the date
    dateOfBirth: parseDateToDateOnly(new Date(data.birthDate ?? '')),
    socialSecurityNumber: data.ssn,
    street1: data.demographicsstreet1,
    street2: data.demographicsstreet2,
    city: data.demographicscity,
    stateId: states?.find((state) => state.code === data.demographicsstate)?.id,
    zipcode: data.demographicszip,
    pharmacyId: data.pharmacyId,
    primaryCarePhysician: data.pcp,
    ...(!isEmpty(data.pCPPhoneNumber) && {
      primaryCarePhysicianContact: data.pCPPhoneNumber,
    }),
    ...(!isEmpty(data.pCPFaxPhoneNumber) && {
      primaryCareFaxNumber: data.pCPFaxPhoneNumber,
    }),
    email: data.email,
    employer: data.employer,
    guarantorName: data.guarantor,
    emergencyContactName: data.emergencyContactName,
    phoneNumber: data.patientPhoneNumber,
    leaveMessage: data.leaveMessage,
    ...(!isEmpty(data.emergencyContactPhoneNumber) && {
      emergencyContactPhone: data.emergencyContactPhoneNumber,
    }),
    emergencyContactRelation: data.emergencyContactRelationship,
    ethnicity: data.ethnicity,
    sex: data.sex,
    race: data.race,
    country: data.country ?? 'US',
    stickyNote: data.stickyNote,
    ...(insuranceList.length > 0 && { patientInsuranceList: insuranceList }),
  }
  return patient
}

export const createInsuranceList = (
  data: any,
  existingPatientId: number,
  insuranceIDs: number[],
  states: State[],
  insuranceCount: number,
  documentIds?: number[]
): Insurance[] => {
  const insuranceList: Insurance[] = []
  if (data.PrimaryinsuranceNumber !== '' && data.Primarycompany !== undefined) {
    insuranceList.push({
      id: insuranceIDs?.[0] > 0 ? insuranceIDs[0] : undefined,
      patientId: existingPatientId,
      insuranceLevel: 1,
      firstName: data.PrimaryFirstNameOfHolder,
      lastName: data.PrimaryLastNameOfHolder,
      insuranceNumber: data.PrimaryinsuranceNumber,
      companyName: data.Primarycompany.label,
      ...(parseInt(data.Primarycompany.id) !== 0 && {
        pVerifyInsuranceCompanyId: data.Primarycompany.id,
      }),
      ...(isDate(data.PrimaryeffectiveDate) && {
        effectiveDate: parseDateToDateOnly(
          isDate(data.PrimaryeffectiveDate)
            ? data.PrimaryeffectiveDate
            : new Date(data.PrimaryeffectiveDate)
        ),
      }),
      groupNumber: data.PrimarygroupNumber,
      plan: data.Primaryplan,
      rxBinNumber: data.PrimaryRxBinNumber,
      typeId: data.PrimaryInsuranceType,
      payerId: data.PrimarypayerId,
      sexId: getSexId(data.PrimarySex),
      // insuranceHolder: `${data.PrimaryFirstNameOfHolder as string} ${data.PrimaryLastNameOfHolder as string}`,
      relationToInsured: data.PrimaryInsuranceHolder,
      ...(isDate(data.PrimaryInsuranceBirthDate) && {
        dateOfBirth: parseDateToDateOnly(
          isDate(data.PrimaryInsuranceBirthDate)
            ? data.PrimaryInsuranceBirthDate
            : new Date(data.PrimaryInsuranceBirthDate)
        ),
      }),
      addressLine1: data.Primarystreet1,
      addressLine2: data.Primarystreet2,
      city: data.Primarycity,
      stateId: states?.find((state) => state.code === data.Primarystate)?.id,
      zip: data.Primaryzip,
      copay: data.PrimaryCopay,
      deductible: data.PrimaryDeductible,
      leftToMeet: data.PrimaryLeftToMeet,
      afterDeductible: data.PrimaryAfterDeductible,
      oopMax: data.PrimaryOOPMax,
      documentId: documentIds?.[0] ?? data.primaryDocumentId,
    })
  }
  if (insuranceCount > 1) {
    insuranceList.push({
      id: insuranceIDs?.[1] > 0 ? insuranceIDs[1] : undefined,
      patientId: existingPatientId,
      insuranceLevel: 2,
      insuranceNumber: data.SecondaryinsuranceNumber,
      firstName: data.SecondaryFirstNameOfHolder,
      lastName: data.SecondaryLastNameOfHolder,
      companyName: data.Secondarycompany.label,
      ...(parseInt(data.Secondarycompany.id) !== 0 && {
        pVerifyInsuranceCompanyId: data.Secondarycompany.id,
      }),
      ...(isDate(data.SecondaryeffectiveDate) && {
        effectiveDate: parseDateToDateOnly(
          isDate(data.SecondaryeffectiveDate)
            ? data.SecondaryeffectiveDate
            : new Date(data.SecondaryeffectiveDate)
        ),
      }),
      groupNumber: data.SecondarygroupNumber,
      plan: data.Secondaryplan,
      rxBinNumber: data.SecondaryRxBinNumber,
      typeId: data.SecondaryInsuranceType,
      payerId: data.SecondarypayerId,
      // insuranceHolder: data.SecondaryNameOfHolder,
      relationToInsured: data.SecondaryInsuranceHolder,
      ...(isDate(data.SecondaryInsuranceBirthDate) && {
        dateOfBirth: parseDateToDateOnly(
          isDate(data.SecondaryInsuranceBirthDate)
            ? data.SecondaryInsuranceBirthDate
            : new Date(data.SecondaryInsuranceBirthDate)
        ),
      }),
      addressLine1: data.Secondarystreet1,
      addressLine2: data.Secondarystreet2,
      city: data.Secondarycity,
      stateId: states?.find((state) => state.code === data.Secondarystate)?.id,
      zip: data.Secondaryzip,
      sexId: getSexId(data.SecondarySex),
      copay: data.SecondaryCopay,
      deductible: data.SecondaryDeductible,
      leftToMeet: data.SecondaryLeftToMeet,
      afterDeductible: data.SecondaryAfterDeductible,
      oopMax: data.SecondaryOOPMax,
      documentId: documentIds?.[1] ?? data.secondaryDocumentId,
    })
  }
  if (insuranceCount > 2) {
    insuranceList.push({
      id: insuranceIDs?.[2] > 0 ? insuranceIDs[2] : undefined,
      patientId: existingPatientId,
      insuranceLevel: 3,
      insuranceNumber: data.TertiaryinsuranceNumber,
      firstName: data.TertiaryFirstNameOfHolder,
      lastName: data.TertiaryLastNameOfHolder,
      companyName: data.Tertiarycompany.label,
      ...(parseInt(data.Tertiarycompany.id) !== 0 && {
        pVerifyInsuranceCompanyId: data.Tertiarycompany.id,
      }),
      ...(isDate(data.TertiaryeffectiveDate) && {
        effectiveDate: parseDateToDateOnly(
          isDate(data.TertiaryeffectiveDate)
            ? data.TertiaryeffectiveDate
            : new Date(data.TertiaryeffectiveDate)
        ),
      }),
      groupNumber: data.TertiarygroupNumber,
      plan: data.Tertiaryplan,
      rxBinNumber: data.TertiaryRxBinNumber,
      typeId: data.TertiaryInsuranceType,
      payerId: data.TertiarypayerId,
      relationToInsured: data.TertiaryInsuranceHolder,
      ...(isDate(data.TertiaryInsuranceBirthDate) && {
        dateOfBirth: parseDateToDateOnly(
          isDate(data.TertiaryInsuranceBirthDate)
            ? data.TertiaryInsuranceBirthDate
            : new Date(data.TertiaryInsuranceBirthDate)
        ),
      }),
      // insuranceHolder: data.TertiaryNameOfHolder,
      addressLine1: data.Tertiarystreet1,
      addressLine2: data.Tertiarystreet2,
      city: data.Tertiarycity,
      stateId: states?.find((state) => state.code === data.Tertiarystate)?.id,
      zip: data.Tertiaryzip,
      sexId: getSexId(data.TertiarySex),
      copay: data.TertiaryCopay,
      deductible: data.TertiaryDeductible,
      leftToMeet: data.TertiaryLeftToMeet,
      afterDeductible: data.TertiaryAfterDeductible,
      oopMax: data.TertiaryOOPMax,
      documentId: documentIds?.[2] ?? data.tertiaryDocumentId,
    })
  }
  return insuranceList
}

const createChiefComplaintObject = (
  data: any,
  visitTypeId: number,
  notes: string,
  visitCategoryId?: number
): ChiefComplaint => {
  const complaints = data?.complaint?.map((c: any) => c.id)
  const chiefComplaint: ChiefComplaint = {
    complaintList: complaints ?? [],
    visitTypeId,
    visitCategoryId,
    description: data.symptoms ?? '',
    sourceOther: data.sourceOfInformationName ?? 0,
    informationSourceId: data.sourceOfInformation ?? 0,
    notes,
  }
  return chiefComplaint
}

const createFinancialResponsiblePartyObject = (
  data: any,
  states: State[],
  isWorkersComp: boolean,
  isOccMed: boolean
): FinancialResponsibleParty => {
  const fpc: FinancialResponsibleParty = {
    financialRPFirstName: data.financialRPFirstName,
    financialRPLastName: data.financialRPLastName,
    financialRPAddressLine: data.financialRPstreet1,
    financialRPAddressLine2: data.financialRPstreet2,
    financialRPCity: data.financialRPcity,
    financialRPStateId: states?.find(
      (state) => state.code === data.financialRPstate
    )?.id,
    financialRPZip: data.financialRPzip,
    ...(!isEmpty(data.financialRPPhoneNumber) && {
      financialRPPhone: data.financialRPPhoneNumber,
    }),
    financialRPEmail: data.financialRPEmail,
    financialRPRelationToPatient: data.financialRPRelationToPatient,
    financialRPRelationToPatientOther: data.financialRPRelationToPatientOther,
    ...(!isEmpty(data.financialRPBirthDate) && {
      financialRPDateOfBirth: data.financialRPBirthDate,
    }),
    financialRPSex: data.financialRPSex,
    ...(isWorkersComp && {
      financialRpWorkCompInsuranceNumber:
        data.financialRpWorkCompInsuranceNumber,
      financialRpWorkCompPolicyNumber: data.financialRpWorkCompPolicyNumber,
    }),
    ...(isOccMed && {
      financialRPOccMedGuarantor: data.financialRPOccMedGuarantor,
      financialRPOccMedGuarantorPhone:
        data.financialRPOccMedGuarantorPhoneNumber,
      financialRPOccMedGuarantorFax: data.financialRPOccMedGuarantorFax,
    }),
  }
  return fpc
}

export const createVisitObject = (
  data: any,
  clinicId: number,
  selfPay: boolean,
  medicationHistoryConsent: boolean,
  visitType: number,
  states: State[],
  notes: string,
  isWorkersComp: boolean,
  isOccMed: boolean,
  visitCategory?: number,
  patientType?: number
): Visit => {
  const visit: Visit = {
    arrivalDateTime: formatInUTC(formatISO(new Date())),
    clinicId,
    chiefComplaint: createChiefComplaintObject(
      data,
      visitType,
      notes,
      visitCategory
    ),
    selfPay,
    ...(!isEmpty(
      data.financialRPFirstName ||
        data.financialRPOccMedGuarantor ||
        data.financialRpWorkCompInsuranceNumber
    ) && {
      visitFinancialResponsibleParty: createFinancialResponsiblePartyObject(
        data,
        states,
        isWorkersComp,
        isOccMed
      ),
    }),
    medicationHistoryConsent,
    patientTypeId: patientType,
  }
  return visit
}

export const createWorkerCompObject = (
  visitId: number,
  data: any,
  states: State[]
): WorkersComp => {
  const workersComp: WorkersComp = {
    workersCompClaimNumber: data.workersCompClaimNumber,
    workersCompAccidentDate: parseDateToDateOnly(
      new Date(data.workersCompAccidentDate)
    ),
    workersCompAccidentStateId: data.workersCompAccidentStateId,
    workersCompEmployerName: data.workersCompEmployerName,
    workersCompEmployerPhone: data.workersCompEmployerPhoneNumber,
    workersCompEmployerContactName: data.workersCompEmployerContactName,
    workersCompEmployerEmail: data.workersCompEmployerEmail,
    workersCompEmployerAddressStreet1: data.workersCompEmployerAddressstreet1,
    workersCompEmployerAddressStreet2: data.workersCompEmployerAddressstreet2,
    workersCompEmployerAddressCity: data.workersCompEmployerAddresscity,
    workersCompEmployerAddressStateId: states?.find(
      (state) => state.code === data.workersCompEmployerAddressstate
    )?.id,
    workersCompEmployerAddressZipcode: data.workersCompEmployerAddresszip,
  }
  return {
    visitId,
    ...workersComp,
  }
}

export const createOCCMedObject = (
  visitId: number,
  data: any,
  states: State[]
): OccMed => {
  const occMed: OccMed = {
    occMedEmployer: data.occMedEmployer,
    occMedEmployerPhone: data.occMedEmployerPhoneNumber,
    occMedContactName: data.occMedContactName,
    occMedEmployerEmail: data.occMedEmployerEmail,
    occMedAddressLine: data.occMedAddressstreet1,
    occMedAddressLine2: data.occMedAddressstreet2,
    occMedCity: data.occMedAddresscity,
    occMedStateId: states?.find(
      (state) => state.code === data.occMedAddressstate
    )?.id,
    occMedZip: data.occMedAddresszip,
  }
  return {
    visitId,
    ...occMed,
  }
}

// Helper function for uploading files
export const uploadFile = async (
  file: string | Blob | undefined,
  patientId: number | undefined,
  documentTypeId: number,
  visitClinicalProcedureId: number | undefined,
  visitId: number | undefined,
  fileName: string | undefined,
  currentUser: number | undefined,
  getToken: GetToken,
  setToastMessage?: any
): Promise<any> => {
  if (file) {
    const formData = new FormData()
    formData.append('file', file)
    if (patientId) formData.append('patientId', patientId.toString())
    if (patientId) formData.append('AccountUserId', `${currentUser!}`)
    if (visitClinicalProcedureId) {
      formData.append(
        'visitClinicalProcedureId',
        visitClinicalProcedureId.toString()
      )
    }
    if (visitId) formData.append('visitId', visitId.toString())
    if (fileName) formData.append('visibleDocumentName', fileName)
    formData.append('documentTypeId', documentTypeId.toString())
    const res = await fetch(`${process.env.API_URL ?? ''}/api/Document`, {
      method: 'POST',
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) ?? ''
        }`,
      },
      body: formData,
    })
    if (!res.ok) {
      if (setToastMessage) {
        setToastMessage(
          `Server responded with ${res.status}: ${res.statusText}`
        )
      } else {
        throw new Error(
          `Server responded with ${res.status}: ${res.statusText}`
        )
      }
    }
    const result = await res.json()
    return result
  }
}

type GetToken = (options?: any) => Promise<string | null>;

export const selectPatient = (
  patient: Patient,
  reset: () => void,
  setPatientId: any,
  setValue: any,
  states: State[],
  setInsuranceIds: any,
  getToken: any,
  setPatientDocuments: any,
  setIsResultsVisible: any,
  setInsuranceCount: any,
  setImportedLastVisitDate: any
): void => {
  reset()
  const insuranceCompany = (index: number): { id: number; label: string } => {
    if (patient === undefined || patient === null) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (
      patient?.patientInsuranceList === undefined ||
      patient?.patientInsuranceList === null
    ) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (patient.patientInsuranceList.length === 0) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (
      patient.patientInsuranceList[index] === undefined ||
      patient.patientInsuranceList[index] === null
    ) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (
      patient.patientInsuranceList[index].companyName === undefined ||
      patient.patientInsuranceList[index].companyName === null
    ) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (
      patient.patientInsuranceList[index].companyName !== undefined &&
      patient.patientInsuranceList[index].companyName !== null
    ) {
      return {
        id: patient.patientInsuranceList[index].pVerifyInsuranceCompanyId ?? 0,
        label: patient.patientInsuranceList[index].companyName ?? '',
      }
    }
    return { id: 0, label: 'Select Insurance Company' }
  }

  setPatientId(patient.id)
  setImportedLastVisitDate(patient.readOnlyImportedPatientLastVisitDate)
  setValue('firstName', patient.firstName)
  setValue('lastName', patient.lastName)
  setValue('birthDate', dateOnlyToDate(patient.dateOfBirth))
  setValue('race', patient.race)
  setValue('sex', patient.sex)
  setValue('ssn', patient.socialSecurityNumber)
  setValue('ethnicity', patient.ethnicity)
  setValue('demographicsstreet1', patient.street1)
  setValue('demographicsstreet2', patient.street2)
  setValue('demographicscity', patient.city)
  setValue(
    'demographicsstate',
    states.find((state) => state.id === patient.stateId)?.code
  )
  setValue('demographicszip', patient.zipcode)
  setValue('country', patient.country)
  if (!isEmpty(patient.phoneNumber)) {
    setValue('email', patient.email)
  }
  setValue('patientPhoneNumber', patient.phoneNumber)
  setValue('leaveMessage', patient.leaveMessage)
  if (!isEmpty(patient.pharmacyId)) {
    setValue('pharmacy', patient.pharmacyId)
  }
  setValue('pcp', patient.primaryCarePhysician)
  setValue('pCPPhoneNumber', patient.primaryCarePhysicianContact)
  setValue('pCPFaxPhoneNumber', patient.primaryCareFaxNumber)
  setValue('employer', patient.employer)
  setValue('guarantor', patient.guarantorName)
  setValue('emergencyContactName', patient.emergencyContactName)
  setValue('emergencyContactPhoneNumber', patient.emergencyContactPhone)
  setValue('emergencyContactRelationship', patient.emergencyContactRelation)
  setValue('stickyNote', patient?.stickyNote)
  setInsurance(patient, setValue, insuranceCompany)
  setInsuranceCount(
    patient.patientInsuranceList == null
      ? 0
      : patient.patientInsuranceList.length
  )

  if (patient.pharmacyId !== 0) {
    const pharmacyPromise = getUrgentIQPharmacy(patient.pharmacyId!, getToken)
    pharmacyPromise
      .then((res) => {
        if (res !== undefined) {
          setValue('pharmacy', res.storeName)
        }
      })
      .catch((err) => {
        throw err
      })
  } else {
    setValue('pharmacy', '')
  }

  const documentsPromise = getPatientDocuments(patient?.id ?? 0, getToken)
  documentsPromise
    .then((res) => {
      if (res !== undefined) {
        res.forEach((document: any) => {
          setPatientDocuments(document, patient)
        })
      }
    })
    .catch((err) => {
      throw err
    })

  setIsResultsVisible(false)
}

export const setInsurance = (
  patient: Patient,
  setValue: any,
  insuranceCompany: any
): void => {
  const insuranceLevels = ['Primary', 'Secondary', 'Tertiary']

  patient?.patientInsuranceList?.forEach((insurance, index) => {
    if (index < 3) {
      // Considering only up to Tertiary insurance
      const level = insuranceLevels[index]
      setValue(`${level}insuranceNumber`, insurance?.insuranceNumber)
      setValue(`${level}company`, insuranceCompany(index))
      if (insurance.sexId) {
        setValue(`${level}Sex`, getGenderBySexId(insurance.sexId))
      }
      if (isDateOnlyValid(insurance.effectiveDate)) {
        if (insurance.effectiveDate?.year === 1) {
          setValue(`${level}effectiveDate`, null)
        } else {
          setValue(
            `${level}effectiveDate`,
            dateOnlyToDate(insurance.effectiveDate)
          )
        }
      }
      [
        'groupNumber',
        'plan',
        'rxBinNumber',
        'InsuranceType',
        'Pcn',
        'InsuranceHolder',
        'FirstNameOfHolder',
        'LastNameOfHolder',
        'payerId',
      ].forEach((field) => {
        let formattedField = field
        if (field === 'FirstNameOfHolder') {
          formattedField = 'firstName'
        }
        if (field === 'LastNameOfHolder') {
          formattedField = 'lastName'
        }
        let value = insurance[formattedField as keyof typeof insurance]
        if (field === 'InsuranceHolder') {
          formattedField = 'relationToInsured'
          value = insurance[formattedField as keyof typeof insurance]
          if (
            value !== 'self' &&
            value !== 'spouse' &&
            value !== 'mother' &&
            value !== 'father' &&
            value !== 'child'
          ) {
            value = 'other'
            setValue(`${level}relationToInsured`, insurance.relationToInsured)
          }
        }
        setValue(`${level}${field}`, value)
      })
      if (isDateOnlyValid(insurance.dateOfBirth)) {
        setValue(
          `${level}InsuranceBirthDate`,
          dateOnlyToDate(insurance.dateOfBirth)
        )
      }
    }
  })
}

export const resetInsuranceField = (
  setValue: any,
  insuranceLevel: 'Primary' | 'Secondary' | 'Tertiary'
): void => {
  const prefix = insuranceLevel // This will be 'Primary', 'Secondary', or 'Tertiary'
  setValue(`${prefix}insuranceNumber`, null)
  setValue(`${prefix}company`, null)
  setValue(`${prefix}effectiveDate`, null)
  setValue(`${prefix}groupNumber`, null)
  setValue(`${prefix}plan`, null)
  setValue(`${prefix}rxBinNumber`, null)
  setValue(`${prefix}InsuranceType`, null)
  setValue(`${prefix}Pcn`, null)
  setValue(`${prefix}InsuranceHolder`, null)
  setValue(`${prefix}FirstNameOfHolder`, null)
  setValue(`${prefix}LastNameOfHolder`, null)
  setValue(`${prefix}InsuranceBirthDate`, null)
  setValue(`${prefix}payerId`, null)
}
