import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import { getRolePermissions } from '@/services'
import { type UserRolePermissions } from '@/types/UserRoles'

interface RolePermissionState {
  rolePermissions: UserRolePermissions[] | [];
  setRolePermissions: (rolePermissions: UserRolePermissions[]) => void;
}

export const useRolePermissionStore = create<RolePermissionState>()(
  devtools(
    persist(
      (set) => ({
        rolePermissions: [],
        setRolePermissions: (permissions: UserRolePermissions[]) =>
          set({ rolePermissions: permissions }),
      }),
      {
        name: 'role-permission',
      }
    )
  )
)

export const useQueryGetRolePermissions = (
  getToken: any
): QueryObserverResult<UserRolePermissions[], unknown> => {
  const { setRolePermissions } = useRolePermissionStore()

  return useQuery<UserRolePermissions[]>(
    ['role-permissions'],
    async () => await getRolePermissions(getToken),
    {
      onSuccess: (data) => {
        setRolePermissions(data)
      },
      initialData: [],
    }
  )
}
