import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getPatientMedication,
  addNewPatientMedication,
  deletePatientMedication,
  updatePatientMedication,
} from '@/services'
import { type PatientMedication } from '@/types/PatientMedication'

interface PatientMedicationState {
  patientMedications: PatientMedication[] | [];
  setPatientMedications: (patientMedications: PatientMedication[]) => void;
}

export const usePatientMedicationStore = create<PatientMedicationState>()(
  devtools((set) => ({
    patientMedications: [],
    setPatientMedications: (patientMedications: PatientMedication[]) =>
      set({ patientMedications }),
  }))
)

export const useQueryGetPatientMedication = (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): QueryObserverResult<PatientMedication[], unknown> => {
  const { setPatientMedications } = usePatientMedicationStore()
  const key = visitId
    ? ['patient-medication', patientId, visitId]
    : ['patient-medication', patientId]
  return useQuery<PatientMedication[]>(
    key,
    async () => await getPatientMedication(patientId, getToken, visitId),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        setPatientMedications(data ?? [])
      },
      initialData: [],
    }
  )
}

export const useMutateAddPatientMedication = (
  getToken: any
): UseMutationResult<
  PatientMedication,
  unknown,
  { patientMedication: PatientMedication }
> => {
  const { patientMedications, setPatientMedications } =
    usePatientMedicationStore()

  return useMutation<
    PatientMedication,
    unknown,
    { patientMedication: PatientMedication }
  >(
    async ({ patientMedication }) =>
      await addNewPatientMedication(patientMedication, getToken),
    {
      onSuccess: (data) => {
        setPatientMedications([
          ...(patientMedications as PatientMedication[]),
          data,
        ])
      },
    }
  )
}

export const useMutateDeletePatientMedication = (
  getToken: any
): UseMutationResult<
  PatientMedication,
  unknown,
  { patientMedicationId: number }
> => {
  const { patientMedications, setPatientMedications } =
    usePatientMedicationStore()

  return useMutation<
    PatientMedication,
    unknown,
    { patientMedicationId: number }
  >(
    async ({ patientMedicationId }) =>
      await deletePatientMedication(patientMedicationId, getToken),
    {
      onSuccess: (data) => {
        const newPatientMedications = patientMedications.filter(
          (patientMedication: PatientMedication): boolean =>
            patientMedication?.id !== data?.id
        )
        setPatientMedications(newPatientMedications)
      },
    }
  )
}

export const useMutateUpdatePatientMedication = (
  getToken: any
): UseMutationResult<
  PatientMedication,
  unknown,
  { patientMedicationId: number; patientMedication: PatientMedication }
> => {
  const { patientMedications, setPatientMedications } =
    usePatientMedicationStore()

  return useMutation<
    PatientMedication,
    unknown,
    { patientMedicationId: number; patientMedication: PatientMedication }
  >(
    async ({ patientMedicationId, patientMedication }) =>
      await updatePatientMedication(
        patientMedicationId,
        patientMedication,
        getToken
      ),
    {
      onSuccess: (data) => {
        const newPatientMedications = patientMedications.map(
          (patientMedication: PatientMedication): PatientMedication =>
            patientMedication?.id === data?.id ? data : patientMedication
        )
        setPatientMedications(newPatientMedications)
      },
    }
  )
}
