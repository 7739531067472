import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getExternalProvider,
  getExternalProviders,
  addNewExternalProvider,
  deleteExternalProvider,
  updateExternalProvider,
  searchExternalProviders,
} from '@/services'
import { type ExternalProvider } from '@/types/ExternalProvider'

interface ExternalProviderState {
  ExternalProvider: ExternalProvider | null;
  ExternalProviders: ExternalProvider[] | [];
  setExternalProvider: (ExternalProvider: ExternalProvider) => void;
  setExternalProviders: (ExternalProviders: ExternalProvider[]) => void;
}

export const useExternalProviderStore = create<ExternalProviderState>()(
  devtools((set) => ({
    ExternalProvider: null,
    ExternalProviders: [],
    setExternalProvider: (provider: ExternalProvider) =>
      set({ ExternalProvider: provider }),
    setExternalProviders: (providers: ExternalProvider[]) =>
      set({ ExternalProviders: providers }),
  }))
)

export const useQueryGetExternalProvider = (
  ExternalProviderId: number,
  getToken: any
): QueryObserverResult<ExternalProvider, unknown> => {
  const { setExternalProvider } = useExternalProviderStore()

  return useQuery<ExternalProvider>(
    ['external-provider', ExternalProviderId],
    async () => await getExternalProvider(ExternalProviderId, getToken),
    {
      enabled: !!ExternalProviderId,
      onSuccess: (data) => {
        setExternalProvider(data)
      },
    }
  )
}

export const useQueryGetExternalProviders = (
  getToken: any
): QueryObserverResult<ExternalProvider[], unknown> => {
  const { setExternalProviders } = useExternalProviderStore()

  return useQuery<ExternalProvider[]>(
    ['external-provider'],
    async () => await getExternalProviders(getToken),
    {
      onSuccess: (data) => {
        setExternalProviders(data)
      },
      initialData: [],
    }
  )
}

export const useMutateAddExternalProvider = (
  getToken: any
): UseMutationResult<
  ExternalProvider,
  unknown,
  { ExternalProvider: ExternalProvider }
> => {
  const { ExternalProviders, setExternalProviders } =
    useExternalProviderStore()

  return useMutation<
    ExternalProvider,
    unknown,
    { ExternalProvider: ExternalProvider }
  >(
    async ({ ExternalProvider }) =>
      await addNewExternalProvider(ExternalProvider, getToken),
    {
      onSuccess: (data, variables) => {
        const newExternalProvider = {
          ...variables.ExternalProvider, // Spread in the original data used for mutation
          id: data.id, // Override the id with the returned id from the server
        }
        setExternalProviders([
          ...(ExternalProviders as ExternalProvider[]),
          newExternalProvider,
        ])
      },
    }
  )
}

export const useMutateDeleteExternalProvider = (
  getToken: any
): UseMutationResult<
  ExternalProvider,
  unknown,
  { ExternalProviderId: number }
> => {
  const { ExternalProviders, setExternalProviders } =
    useExternalProviderStore()

  return useMutation<ExternalProvider, unknown, { ExternalProviderId: number }>(
    async ({ ExternalProviderId }) =>
      await deleteExternalProvider(ExternalProviderId, getToken),
    {
      onSuccess: (data) => {
        const newExternalProviders = ExternalProviders.filter(
          (ExternalProvider: ExternalProvider): boolean =>
            ExternalProvider?.id !== data?.id
        )
        setExternalProviders(newExternalProviders)
      },
    }
  )
}

export const useMutateUpdateExternalProvider = (
  getToken: any
): UseMutationResult<
  ExternalProvider,
  unknown,
  { ExternalProviderId: number; ExternalProvider: ExternalProvider }
> => {
  const { ExternalProviders, setExternalProviders } =
    useExternalProviderStore()

  return useMutation<
    ExternalProvider,
    unknown,
    { ExternalProviderId: number; ExternalProvider: ExternalProvider }
  >(
    async ({ ExternalProviderId, ExternalProvider }) =>
      await updateExternalProvider(
        ExternalProviderId,
        ExternalProvider,
        getToken
      ),
    {
      onSuccess: (data) => {
        const newExternalProviders = ExternalProviders.map(
          (ExternalProvider: ExternalProvider): ExternalProvider => {
            if (ExternalProvider?.id === data?.id) {
              return data
            }
            return ExternalProvider
          }
        )
        setExternalProviders(newExternalProviders)
      },
    }
  )
}

export const useQuerySearchExternalProvider = (
  query: string | undefined,
  getToken: any
): QueryObserverResult<ExternalProvider[], unknown> => {
  const { setExternalProviders } = useExternalProviderStore()

  return useQuery<ExternalProvider[]>(
    ['query', query],
    async () => {
      if (!query) return []
      return await searchExternalProviders(query, getToken)
    },
    {
      onSuccess: (data) => {
        setExternalProviders(data)
      },
      initialData: [],
    }
  )
}
