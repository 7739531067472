import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type PatientMedicalCondition } from '../types/PatientMedicalCondition'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetPatientMedicalCondition = fetcher
  .path('/api/PatientMedicalCondition/Patient/{patientId}')
  .method('get')
  .create()

export const getPatientMedicalCondition = async (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientMedicalCondition({ patientId, visitId })).data
  } catch (error) {
    if (error instanceof setupGetPatientMedicalCondition.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createUser = fetcher
  .path('/api/PatientMedicalCondition')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewPatientMedicalCondition = async (
  user: PatientMedicalCondition,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await createUser(user)).data
  } catch (error) {
    if (error instanceof createUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeletePatientMedicalCondition = fetcher
  .path('/api/PatientMedicalCondition/{patientMedicalConditionId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deletePatientMedicalCondition = async (
  patientMedicalConditionId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeletePatientMedicalCondition({ patientMedicalConditionId })
  } catch (error) {
    if (error instanceof setupGetPatientMedicalCondition.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdatePatientMedicalCondition = fetcher
  .path('/api/PatientMedicalCondition/{patientMedicalConditionId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updatePatientMedicalCondition = async (
  patientMedicalConditionId: number,
  patientMedicalCondition: PatientMedicalCondition,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupUpdatePatientMedicalCondition({
      patientMedicalConditionId,
      ...patientMedicalCondition,
    })
  } catch (error) {
    if (error instanceof setupGetPatientMedicalCondition.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
