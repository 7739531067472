import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getPatient,
  getPatients,
  addNewPatient,
  searchPatient,
  updatePatient,
  patchPatient
} from '@/services'
import { type Patient } from '@/types/Patient'
import { type Operation } from '@/types'

interface PatientRegisterationState {
  patient: Patient | null;
  patients: Patient[] | [];
  setPatient: (accountUser: Patient) => void;
  setPatients: (accountUsers: Patient[]) => void;
}

export const usePatientStore = create<PatientRegisterationState>()(
  devtools((set) => ({
    patient: null,
    patients: [],
    setPatient: (patient: Patient) => set({ patient }),
    setPatients: (patients: Patient[]) => set({ patients }),
  }))
)

export const useQueryGetPatient = (
  patientId: number,
  getToken: any
): QueryObserverResult<Patient, unknown> => {
  const { setPatient } = usePatientStore()

  return useQuery<Patient>(
    ['patient-id', patientId],
    async () => await getPatient(patientId, getToken),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        setPatient(data)
      },
      onError: (err) => err,
    }
  )
}

export const useQueryGetPatients = (
  clinicId: number,
  getToken: any
): QueryObserverResult<Patient[], unknown> => {
  const { setPatients } = usePatientStore()

  return useQuery<Patient[]>(
    ['clinic-id', clinicId],
    async () => await getPatients(clinicId, getToken),
    {
      enabled: !!clinicId,
      onSuccess: (data) => {
        setPatients(data)
      },
      initialData: [],
    }
  )
}

export const useQuerySearchPatient = (
  query: string,
  getToken: any
): QueryObserverResult<Patient[], unknown> => {
  const { setPatients } = usePatientStore()

  return useQuery<Patient[]>(
    ['query', query],
    async () => await searchPatient(query, getToken),
    {
      onSuccess: (data) => {
        setPatients(data)
      },
      initialData: [],
    }
  )
}

export const useMutateAddNewPatient = (
  getToken: any
): UseMutationResult<Patient, unknown, Patient> => {
  const { patients, setPatients } = usePatientStore()

  return useMutation<Patient, unknown, Patient>(
    async (patient) => await addNewPatient(patient, getToken),
    {
      onSuccess: (data) => {
        setPatients([...(patients as Patient[]), data])
      },
    }
  )
}

export const useMutateUpdatePatient = (
  getToken: any
): UseMutationResult<
  Patient,
  unknown,
  { patientId: number; patient: Patient }
> => {
  const { patients, setPatients } = usePatientStore()

  return useMutation<Patient, unknown, { patientId: number; patient: Patient }>(
    async ({ patientId, patient }) =>
      await updatePatient(patientId, patient, getToken),
    {
      onSuccess: (data) => {
        const newPatients = patients.map((patient: Patient): Patient => {
          if (patient?.id === data?.id) {
            return data
          }
          return patient
        })
        setPatients(newPatients)
      },
    }
  )
}

export const useMutatePatchPatient = (
  getToken: any
): UseMutationResult<
  Patient,
  unknown,
  { patientId: number; denies: boolean; path: string }
> => {
  return useMutation<
    Patient,
    unknown,
    { patientId: number, denies: boolean; path: string }
  >(
    async ({ patientId, denies, path }) =>
      await patchPatient(
        patientId,
        [{ value: denies, op: 'replace', path: '/' + path }] as Operation,
        getToken
      ),
    {
      onError: (error) => {
        throw error
      },
    }
  )
}
