import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { useOnlineAppointmentStore } from './useOnlineAppointment'
import { type UseMutationResult, useMutation } from '@tanstack/react-query'
import { type OnlineAppointment } from '@/types'
import { updateOnlineAppointments } from '@/services/OnlineAppointments'
import { getAppointmentById } from '@/services'

interface AppointmentDTO {
  id: number;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  patientId?: number;
  dateOfBirth: Date;
  appointmentStartDate: Date;
  appointmentEndDate: Date;
  clinicId: number;
  status: AppointmentStatusEnum;
  appointmentTypeId?: number;
  statusString?: string;
  skipEmail: boolean;
  readOnlyCreatedTime: Date;
  groupId?: number;
  recurrenceRule?: string;
  notes?: string;
}

enum AppointmentStatusEnum {
  Pending,
  Confirmed,
  Cancelled,
}

interface AppointmentState {
  appointments: AppointmentDTO[] | [];
  addAppointment: (appointment: AppointmentDTO) => void;
  removeAppointment: (id: number, removeGroup?: boolean) => void;
  getAppointmentsByGroupId: (groupId: number) => AppointmentDTO[];
  setAppointments: (appointments: AppointmentDTO[]) => void;
}

export const useAppointmentStore = create<AppointmentState>()(
  devtools(
    persist(
      (set, get) => ({
        appointments: [],
        addAppointment: (appointment: AppointmentDTO) =>
          set({ appointments: [...get().appointments, appointment] }),
        removeAppointment: (id: number, removeGroup?: boolean) => {
          let updatedAppointments

          if (removeGroup) {
            const groupIdToDelete = get().appointments.find(
              (apt) => apt.id === id
            )?.groupId
            updatedAppointments = get().appointments.filter(
              (apt) => apt.groupId !== groupIdToDelete
            )
          } else {
            updatedAppointments = get().appointments.filter(
              (apt) => apt.id !== id
            )
          }

          set({ appointments: updatedAppointments })
        },
        getAppointmentsByGroupId: (groupId: number) => {
          return get().appointments.filter(
            (appointment) => appointment.groupId === groupId
          )
        },
        setAppointments: (appointments: AppointmentDTO[]) =>
          set({ appointments }),
      }),
      {
        name: 'appointments',
      }
    )
  )
)

export const useMutateUpdateAppointmentStatus = (
  getToken: any
): UseMutationResult<
  OnlineAppointment,
  Error,
  { onlineAppointmentId: number; statusId: number },
  unknown
> => {
  const { schedules, setSchedules } = useOnlineAppointmentStore()

  return useMutation<
    OnlineAppointment,
    Error,
    { onlineAppointmentId: number; statusId: number }
  >(
    async ({ onlineAppointmentId, statusId }) => {
      const onlineAppointment = await getAppointmentById(
        onlineAppointmentId,
        getToken
      )
      const newAppointment = {
        ...onlineAppointment,
        status: statusId,
        patient: null,
      }
      return await updateOnlineAppointments(
        onlineAppointmentId,
        newAppointment,
        getToken
      )
    },
    {
      onSuccess: (data) => {
        const newSchedules: OnlineAppointment[] = schedules.map(
          (existingOnlineAppointment: OnlineAppointment) => {
            if (existingOnlineAppointment.id === data.id) {
              return data
            }
            return existingOnlineAppointment
          }
        )
        setSchedules(newSchedules)
      },
      onError: (error) => {
        console.error('Error updating online appointment:', error)
      },
    }
  )
}
