import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

const fetcher = Fetcher.for<paths>()

const setupGetPatientBilling = fetcher
  .path('/api/AdvancedMdDemographic/Balance/{patientId}')
  .method('get')
  .create()

export const getPatientBilling = async (
  patientId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientBilling({ patientId })).data
  } catch (error) {
    if (error instanceof setupGetPatientBilling.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetPatientBillingMemo = fetcher
  .path('/api/AdvancedMdDemographic/Memo/{patientId}')
  .method('get')
  .create()

export const getPatientBillingMemo = async (
  patientId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientBillingMemo({ patientId })).data
  } catch (error) {
    if (error instanceof setupGetPatientBillingMemo.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
