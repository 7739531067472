import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type WorkersComp } from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetWokersComp = fetcher
  .path('/api/VisitWorkersComp/{vId}')
  .method('get')
  .create()

export const getWorkersComp = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetWokersComp({ vId: visitId })).data
  } catch (error) {
    if (error instanceof setupGetWokersComp.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupAddWorkersComp = fetcher
  .path('/api/VisitWorkersComp/{vId}')
  .method('put')
  .create({ 'api-version': undefined })

export const addWorkersComp = async (
  visitId: number,
  workersComp: WorkersComp,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupAddWorkersComp({ vId: visitId, ...workersComp })
  } catch (error) {
    if (error instanceof setupAddWorkersComp.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
