import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type HistoryOfPresentIllness } from '../types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations
const setupGetVital = fetcher
  .path('/api/Visit/{vId}/VisitHistoryOfPresentIllness')
  .method('get')
  .create()

export const getHPI = async (vId: number, getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetVital({ vId })).data
  } catch (error) {
    if (error instanceof setupGetVital.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

export const updateHPI = async (
  vId: number,
  hpi: HistoryOfPresentIllness[],
  getToken: any
): Promise<any> => {
  const url = `${
    process.env.API_URL ?? ''
  }/api/Visit/${vId}/VisitHistoryOfPresentIllness`
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  const response = fetch(url, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(hpi),
  })
  return await response
}
