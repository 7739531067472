import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getPatientMedicalCondition,
  addNewPatientMedicalCondition,
  deletePatientMedicalCondition,
  updatePatientMedicalCondition,
} from '../services'
import { type PatientMedicalCondition } from '../types'

interface PatientMedicalConditionState {
  patientMedicalConditions: PatientMedicalCondition[] | [];
  setPatientMedicalConditions: (
    patientMedicalConditions: PatientMedicalCondition[]
  ) => void;
}

export const usePatientMedicalConditionStore =
  create<PatientMedicalConditionState>()(
    devtools((set) => ({
      patientMedicalConditions: [],
      setPatientMedicalConditions: (conditions: PatientMedicalCondition[]) =>
        set({ patientMedicalConditions: conditions }),
    }))
  )

export const useQueryGetPatientMedicalCondition = (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): QueryObserverResult<PatientMedicalCondition[], unknown> => {
  const { setPatientMedicalConditions } = usePatientMedicalConditionStore()
  const key = visitId
    ? ['patient-medical-condition', patientId, visitId]
    : ['patient-medical-condition', patientId]
  return useQuery<PatientMedicalCondition[]>(
    key,
    async () => await getPatientMedicalCondition(patientId, getToken, visitId),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        setPatientMedicalConditions(data)
      },
      initialData: [],
    }
  )
}

export const useMutateAddPatientMedicalCondition = (
  getToken: any
): UseMutationResult<
  PatientMedicalCondition,
  unknown,
  { condition: PatientMedicalCondition }
> => {
  const { patientMedicalConditions, setPatientMedicalConditions } =
    usePatientMedicalConditionStore()

  return useMutation<
    PatientMedicalCondition,
    unknown,
    { condition: PatientMedicalCondition }
  >(
    async ({ condition }) =>
      await addNewPatientMedicalCondition(condition, getToken),
    {
      onSuccess: (data) => {
        setPatientMedicalConditions([
          ...(patientMedicalConditions as PatientMedicalCondition[]),
          data,
        ])
      },
    }
  )
}

export const useMutateDeletePatientMedicalCondition = (
  getToken: any
): UseMutationResult<
  PatientMedicalCondition,
  unknown,
  { patientMedicalConditionId: number }
> => {
  const { patientMedicalConditions, setPatientMedicalConditions } =
    usePatientMedicalConditionStore()

  return useMutation<
    PatientMedicalCondition,
    unknown,
    { patientMedicalConditionId: number }
  >(
    async ({ patientMedicalConditionId }) =>
      await deletePatientMedicalCondition(patientMedicalConditionId, getToken),
    {
      onSuccess: (data) => {
        const newPatientMedicalConditions = patientMedicalConditions.filter(
          (condition: PatientMedicalCondition): boolean =>
            condition?.id !== data?.id
        )
        setPatientMedicalConditions(newPatientMedicalConditions)
      },
    }
  )
}

export const useMutateUpdatePatientMedicalCondition = (
  getToken: any
): UseMutationResult<
  PatientMedicalCondition,
  unknown,
  { patientMedicalConditionId: number; condition: PatientMedicalCondition }
> => {
  const { patientMedicalConditions, setPatientMedicalConditions } =
    usePatientMedicalConditionStore()

  return useMutation<
    PatientMedicalCondition,
    unknown,
    { patientMedicalConditionId: number; condition: PatientMedicalCondition }
  >(
    async ({ patientMedicalConditionId, condition }) =>
      await updatePatientMedicalCondition(
        patientMedicalConditionId,
        condition,
        getToken
      ),
    {
      onSuccess: (data) => {
        const newPatientMedicalConditions = patientMedicalConditions.map(
          (
            patientCondition: PatientMedicalCondition
          ): PatientMedicalCondition => {
            if (patientCondition?.id === data?.id) {
              return data
            }
            return patientCondition
          }
        )
        setPatientMedicalConditions(newPatientMedicalConditions)
      },
    }
  )
}
