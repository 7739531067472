import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type Pharmacy } from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

const setupGetDSPharmacies = fetcher
  .path('/api/DoseSpotPharmacy/{clinicId}/Search')
  .method('get')
  .create()
export const getDSPharmacies = async (
  name: string,
  city: string,
  state: string,
  zip: string,
  address: string,
  phoneOrFax: string,
  specialtyId: number,
  clinicId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (
      await setupGetDSPharmacies({
        name,
        city,
        state,
        zip,
        address,
        phoneOrFax,
        specialtyId,
        clinicId,
      })
    ).data
  } catch (error) {
    if (error instanceof setupGetDSPharmacies.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdatePharmacy = fetcher
  .path('/api/Pharmacy')
  .method('post')
  .create({ 'api-version': undefined })
export const updatePharmacy = async (
  pharmacy: Pharmacy | undefined,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupUpdatePharmacy(pharmacy!)).data
  } catch (error) {
    if (error instanceof setupUpdatePharmacy.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetUrgentIQPharmacy = fetcher
  .path('/api/Pharmacy/UrgentIqPharmacy/{pharmacyId}')
  .method('get')
  .create()
export const getUrgentIQPharmacy = async (
  pharmacyId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetUrgentIQPharmacy({ pharmacyId })).data
  } catch (error) {
    if (error instanceof setupGetUrgentIQPharmacy.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
