import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { updateVisitLock } from '@/services'
import { type VisitLock } from '@/types/VisitLock'

interface VisitLockState {
  visitLock: VisitLock | null;
  setVisitLock: (visitLock: VisitLock) => void;
}

export const useVisitLockStore = create<VisitLockState>()(
  devtools(
    persist(
      (set) => ({
        visitLock: null,
        setVisitLock: (visitLock: VisitLock) => set({ visitLock }),
      }),
      {
        name: 'visit-lock',
      }
    )
  )
)

export const useMutateUpdateVisitLock = (
  getToken: any
): UseMutationResult<
  any,
  unknown,
  { visitId: number; visitLock: VisitLock }
> => {
  const { setVisitLock } = useVisitLockStore()

  return useMutation<any, unknown, { visitId: number; visitLock: VisitLock }>(
    async ({ visitId, visitLock }) =>
      await updateVisitLock(visitId, visitLock, getToken),
    {
      onSuccess: (data) => {
        setVisitLock(data)
      },
    }
  )
}
