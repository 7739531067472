import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import { getIQSuggestion } from '@/services/IQSuggestionService'
import { type IQSuggestion } from '@/types/IQSuggestion'

interface IQSuggestionsState {
  iqSuggestions: IQSuggestion[] | [];
  setIQSuggestions: (iqSuggestions: IQSuggestion[]) => void;
}

export const useIQSuggestionStore = create<IQSuggestionsState>()(
  devtools((set) => ({
    iqSuggestions: [],
    setIQSuggestions: (results: IQSuggestion[]) =>
      set({ iqSuggestions: results }),
  }))
)

export const useQueryGetIQSuggestions = (
  visitId: number,
  getToken: any,
  hpiComplaintId?: number,
  hpiSymptomId?: number,
  hpiLocationId?: number,
  hpiSeverity?: number
): QueryObserverResult<IQSuggestion[], unknown> => {
  const { setIQSuggestions } = useIQSuggestionStore()

  return useQuery<IQSuggestion[]>(
    [
      'iq-suggestions',
      visitId,
      hpiComplaintId,
      hpiSymptomId,
      hpiLocationId,
      hpiSeverity,
    ],
    async () =>
      await getIQSuggestion(
        visitId,
        getToken,
        hpiComplaintId,
        hpiSymptomId,
        hpiLocationId,
        hpiSeverity
      ),
    {
      onSuccess: (data) => {
        setIQSuggestions(data ?? [])
      },
      initialData: [],
    }
  )
}
