import { type PublicAppointment } from '../types/PublicAppointment'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  type UseMutationResult,
  useQuery,
  useMutation,
} from '@tanstack/react-query'
import {
  getPublicAppointments,
  deletePublicAppointments,
} from '@/services/PublicAppointments'
import { getOnlineAppointments } from '@/services/OnlineAppointments'
import { type OnlineAppointment } from '@/types'

export interface TaggedAppointment
  extends PublicAppointment,
    OnlineAppointment {
  appointmentType: 'clinic' | 'public';
}
interface PublicAppointmentState {
  schedules: PublicAppointment[] | [];
  dashboardAppointments: TaggedAppointment[];
  setSchedules: (schedules: PublicAppointment[]) => void;
  setDashboardAppointments: (apps: TaggedAppointment[]) => void;
}

export const usePublicAppointmentStore = create<PublicAppointmentState>()(
  devtools((set) => ({
    schedules: [],
    dashboardAppointments: [],
    setSchedules: (schedules: PublicAppointment[]) => set({ schedules }),
    setDashboardAppointments: (dashboardAppointments: TaggedAppointment[]) =>
      set({ dashboardAppointments }),
  }))
)

export const useQueryGetPublicAppointments = (
  clinicId: number,
  startDate: string,
  endDate: string,
  getToken: any
): QueryObserverResult<PublicAppointment[], unknown> => {
  const { setSchedules } = usePublicAppointmentStore()

  return useQuery<PublicAppointment[]>(
    ['public-appointment', clinicId, startDate, endDate],
    async () =>
      await getPublicAppointments({ clinicId, startDate, endDate, getToken }),
    {
      onSuccess: (data) => {
        setSchedules(data ?? [])
      },
      onError: (error) => {
        throw error
      },
    }
  )
}

export const useQueryGetDashboardAppointments = (
  clinicId: number,
  startDate: string,
  endDate: string,
  getToken: any
): QueryObserverResult<TaggedAppointment[], unknown> => {
  const { setDashboardAppointments } = usePublicAppointmentStore()

  return useQuery<TaggedAppointment[]>(
    ['dashboard-appointments', clinicId, startDate, endDate],
    async () => {
      const publicAppointments = await getPublicAppointments({
        clinicId,
        startDate,
        endDate,
        getToken,
      })
      const onlineAppointments = await getOnlineAppointments(
        clinicId,
        startDate,
        endDate,
        getToken
      )

      const onlineAppointmentsWithFlag = onlineAppointments.map((app: any) => ({
        ...app,
        appointmentType: 'clinic',
      }))
      const publicAppointmentsWithFlag = publicAppointments.map((app: any) => ({
        ...app,
        appointmentType: 'public',
      }))
      return [...onlineAppointmentsWithFlag, ...publicAppointmentsWithFlag]
    },
    {
      onSuccess: (data) => {
        setDashboardAppointments(data ?? [])
      },
      onError: (error) => {
        throw error
      },
    }
  )
}

export const useQueryDeletePublicAppointments = (
  getToken: any
): UseMutationResult<boolean, unknown, number> => {
  const { setSchedules } = usePublicAppointmentStore()

  return useMutation<boolean, unknown, number>(
    async (appointmentId) =>
      await deletePublicAppointments(appointmentId, getToken),
    {
      onSuccess: (data) => {
        setSchedules([])
      },
    }
  )
}
