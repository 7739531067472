import React from 'react'
import { createTheme, useTheme, type Theme } from '@mui/material'

export const useTableTheme = (): Theme => {
  const theme = useTheme()

  const tableTheme = React.useMemo(
    () =>
      createTheme({
        ...theme,
        typography: {
          ...theme.typography,
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
        components: {
          ...theme.components,
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: 'white',
                boxShadow: 'unset',
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              list: {
                border: '1px solid #DDDFE8',
                borderRadius: '3px',
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                cursor: 'pointer',
                width: '17px',
                height: '17px',
                fill: 'rgb(38, 47, 98)',
              },
            },
          },
        },
      }),
    [theme]
  )

  return tableTheme
}

export const useSecondaryTableTheme = (): Theme => {
  const theme = useTheme()

  const tableTheme = React.useMemo(
    () =>
      createTheme({
        ...theme,
        typography: {
          ...theme.typography,
          button: {
            textTransform: 'none',
            fontSize: '1.5rem',
          },
        },
        components: {
          ...theme.components,
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: 'transparent',
                boxShadow: 'unset',
              },
            },
          },
          MuiToolbar: {
            styleOverrides: {
              root: {
                backgroundColor: 'transparent !important',
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              list: {
                border: '1px solid #DDDFE8',
                borderRadius: '3px',
              },
              paper: {
                backgroundColor: 'white',
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                cursor: 'pointer',
                width: '17px',
                height: '17px',
                fill: 'rgb(38, 47, 98)',
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                fontSize: '1rem',
              },
            },
          },
          MuiTableContainer: {
            styleOverrides: {
              root: {
                height: '70vh',
              },
            },
          },
        },
      }),
    [theme]
  )

  return tableTheme
}
