import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type PatientSurgery } from '../types/PatientSurgery'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetPatientSurgery = fetcher
  .path('/api/PatientSurgery/Patient/{patientId}')
  .method('get')
  .create()

export const getPatientSurgery = async (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientSurgery({ patientId, visitId })).data
  } catch (error) {
    if (error instanceof setupGetPatientSurgery.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createUser = fetcher
  .path('/api/PatientSurgery')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewPatientSurgery = async (
  user: PatientSurgery,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await createUser(user)).data
  } catch (error) {
    if (error instanceof createUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeletePatientSurgery = fetcher
  .path('/api/PatientSurgery/{patientSurgeryId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deletePatientSurgery = async (
  patientSurgeryId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeletePatientSurgery({ patientSurgeryId })
  } catch (error) {
    if (error instanceof setupGetPatientSurgery.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdatePatientSurgery = fetcher
  .path('/api/PatientSurgery/{patientSurgeryId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updatePatientSurgery = async (
  patientSurgeryId: number,
  patientSurgery: PatientSurgery,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupUpdatePatientSurgery({ patientSurgeryId, ...patientSurgery })
  } catch (error) {
    if (error instanceof setupGetPatientSurgery.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
