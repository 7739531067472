import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getVisit,
  getVisits,
  getVisitsByPatient,
  addNewVisit,
  deleteVisit,
  updateVisit,
  getSchedule,
  getVisitAssessmentPlan,
  getAllAppointments,
  updateAddendum,
} from '@/services'
import {
  type Visit,
  type AssessmentPlan,
  type RecurringAppointment,
} from '@/types'

interface VisitState {
  visit: Visit | null;
  visits: Visit[] | [];
  schedules: Visit[] | [];
  assessmentPlans: AssessmentPlan[] | [];
  appointments: RecurringAppointment[] | [];
  setVisit: (visit: Visit) => void;
  setVisits: (visits: Visit[]) => void;
  setSchedules: (visits: Visit[]) => void;
  setAssessmentPlans: (assessmentPlans: AssessmentPlan[]) => void;
  setAppointments: (appointments: RecurringAppointment[]) => void;
}

export const useVisitStore = create<VisitState>()(
  devtools((set) => ({
    visit: null,
    visits: [],
    schedules: [],
    assessmentPlans: [],
    appointments: [],
    setVisit: (visit: Visit) => set({ visit }),
    setVisits: (visits: Visit[]) => set({ visits }),
    setSchedules: (visits: Visit[]) => set({ schedules: visits }),
    setAssessmentPlans: (assessmentPlans: AssessmentPlan[]) =>
      set({ assessmentPlans }),
    setAppointments: (appointments: RecurringAppointment[]) =>
      set({ appointments }),
  }))
)

export const useQueryGetVisit = (
  visitId: number,
  getToken: any
): QueryObserverResult<Visit, unknown> => {
  const { setVisit } = useVisitStore()

  return useQuery<Visit>(
    ['visit', visitId],
    async () => await getVisit(visitId, getToken),
    {
      enabled: !!visitId,
      onSuccess: (data) => {
        setVisit(data)
      },
    }
  )
}

export const useQueryGetVisits = (
  getToken: any
): QueryObserverResult<Visit[], unknown> => {
  const { setVisits } = useVisitStore()

  return useQuery<Visit[]>(['visits'], async () => await getVisits(getToken), {
    onSuccess: (data) => {
      setVisits(data)
    },
    initialData: [],
  })
}

export const useQueryGetVisitsByPatient = (
  patientId: number,
  getToken: any
): QueryObserverResult<Visit[], unknown> => {
  const { setVisits } = useVisitStore()

  return useQuery<Visit[]>(
    ['visits', patientId],
    async () => await getVisitsByPatient(patientId, getToken),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        setVisits(data)
      },
      initialData: [],
    }
  )
}

export const useMutateAddNewVisit = (
  getToken: any
): UseMutationResult<Visit, unknown, { visit: Visit }> => {
  const { visits, setVisits } = useVisitStore()

  return useMutation<Visit, unknown, { visit: Visit }>(
    async ({ visit }) => await addNewVisit(visit, getToken),
    {
      onSuccess: (data) => {
        setVisits([...(visits as Visit[]), data])
        return data
      },
    }
  )
}

export const useMutateDeleteVisit = (
  getToken: any
): UseMutationResult<Visit, unknown, { visitId: number }> => {
  const { visits, setVisits } = useVisitStore()

  return useMutation<Visit, unknown, { visitId: number }>(
    async ({ visitId }) => await deleteVisit(visitId, getToken),
    {
      onSuccess: (data) => {
        const newVisits = visits?.filter(
          (visit: Visit): boolean => visit?.id !== data?.id
        )
        setVisits(newVisits)
      },
    }
  )
}

export const useMutateUpdateVisit = (
  getToken: any
): UseMutationResult<Visit, unknown, { visitId: number; visit: Visit }> => {
  return useMutation<Visit, unknown, { visitId: number; visit: Visit }>(
    async ({ visitId, visit }) => await updateVisit(visitId, visit, getToken),
    {
      onError: (err) => {
        throw err
      },
    }
  )
}

export const useQueryGetSchedule = (
  visitDate: string,
  clinicId: number,
  getToken: any
): QueryObserverResult<Visit[], unknown> => {
  return useQuery<any, unknown>(
    ['schedule', visitDate, clinicId],
    async () => {
      return await getSchedule(visitDate, clinicId, getToken)
    },
    {
      enabled: !!clinicId,
    }
  )
}

export const useQueryGetVisitAssessmentPlan = (
  vId: number,
  getToken: any
): QueryObserverResult<AssessmentPlan, unknown> => {
  const { setAssessmentPlans } = useVisitStore()
  return useQuery<any, unknown>(
    ['visitAssessmentPlan', vId],
    async () => await getVisitAssessmentPlan(vId, getToken),
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setAssessmentPlans(data)
      },
    }
  )
}

export const useQueryGetAppointments = (
  getToken: any,
  patientId?: number,
  clinicId?: number,
  startDate?: string,
  endDate?: string
): QueryObserverResult<RecurringAppointment[], unknown> => {
  const { setAppointments } = useVisitStore()
  return useQuery<any, unknown>(
    ['appointments', clinicId, startDate, endDate],
    async () =>
      await getAllAppointments(
        getToken,
        startDate,
        endDate,
        clinicId,
        patientId
      ),
    {
      enabled: !!clinicId,
      onSuccess: (data) => {
        setAppointments(data)
      },
    }
  )
}

export const useMutateUpdateVisitStatus = (
  getToken: any
): UseMutationResult<
  Visit,
  unknown,
  { visitId: number; visitStatusId: number }
> => {
  const { visits, setVisits } = useVisitStore()

  return useMutation<
    Visit,
    unknown,
    { visitId: number; visitStatusId: number }
  >(
    async ({ visitId, visitStatusId }) => {
      const visit = await getVisit(visitId, getToken)
      const newVisit = { ...visit, visitStatusId }
      return await updateVisit(visitId, newVisit, getToken)
    },
    {
      onSuccess: (data) => {
        const newVisits: Visit[] = visits.map((existingVisit: Visit) => {
          if (existingVisit.id === data.id) {
            return data
          }
          return existingVisit
        })
        setVisits(newVisits)
      },
      onError: (error) => {
        console.error('Error updating visit status:', error)
      },
    }
  )
}
export const useMutateUpdateVisitAddendum = (
  getToken: any
): UseMutationResult<
  Visit,
  unknown,
  { visitId: number; addendum: string }
> => {
  return useMutation<Visit, unknown, { visitId: number; addendum: string }>(
    async ({ visitId, addendum }) => {
      const visit = await getVisit(visitId, getToken)
      const newVisit = { ...visit, addendum }
      return await updateAddendum(visitId, newVisit, getToken)
    },
    {
      onError: (error) => {
        console.error('Error updating visit addendum:', error)
      },
    }
  )
}
