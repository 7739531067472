import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type VisitLock } from '../types/VisitLock'

const fetcher = Fetcher.for<paths>()

const setupUpdateVisitLock = fetcher
  .path('/api/VisitLock/{vId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateVisitLock = async (
  visitId: number,
  visitLock: VisitLock,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupUpdateVisitLock({ vId: visitId, ...visitLock })).ok
  } catch (error) {
    if (error instanceof setupUpdateVisitLock.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data // 500 response data
      }
      throw errorType.data
    }
  }
}
