import { type OnlineAppointment } from '../types/OnlineAppointment'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  type UseMutationResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import {
  getOnlineAppointments,
  updateOnlineAppointments,
} from '@/services/OnlineAppointments'
import type { GetToken } from '@clerk/types'

interface OnlineAppointmentState {
  schedules: OnlineAppointment[] | [];
  setSchedules: (schedules: OnlineAppointment[]) => void;
}

export const useOnlineAppointmentStore = create<OnlineAppointmentState>()(
  devtools((set) => ({
    schedules: [],
    setSchedules: (schedules: OnlineAppointment[]) => set({ schedules }),
  }))
)

export const useQueryGetOnlineAppointments = (
  clinicId: number,
  startDate: string,
  endDate: string,
  getToken: GetToken
): QueryObserverResult<OnlineAppointment[], unknown> => {
  const { setSchedules } = useOnlineAppointmentStore()

  return useQuery<OnlineAppointment[]>(
    ['online-appointment', clinicId, startDate, endDate],
    async () =>
      await getOnlineAppointments(clinicId, startDate, endDate, getToken),
    {
      onSuccess: (data) => {
        setSchedules(data ?? [])
      },
      onError: (error) => {
        throw error
      },
    }
  )
}

export const useMutateUpdateOnlineAppointment = (
  getToken: any
): UseMutationResult<
  OnlineAppointment,
  unknown,
  { onlineAppointmentId: number; onlineAppointment: OnlineAppointment }
> => {
  const { schedules, setSchedules } = useOnlineAppointmentStore()

  return useMutation<
    OnlineAppointment,
    unknown,
    { onlineAppointmentId: number; onlineAppointment: OnlineAppointment }
  >(
    async ({ onlineAppointmentId, onlineAppointment }) =>
      await updateOnlineAppointments(
        onlineAppointmentId,
        onlineAppointment,
        getToken
      ),
    {
      onSuccess: (data) => {
        const newSchedules: OnlineAppointment[] = schedules.map(
          (existingOnlineAppointment: OnlineAppointment): OnlineAppointment => {
            if (existingOnlineAppointment?.id === data?.id) {
              return data
            }
            return existingOnlineAppointment
          }
        )
        setSchedules(newSchedules)
      },
    }
  )
}
