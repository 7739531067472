import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  type UseMutationResult,
  useMutation,
} from '@tanstack/react-query'
import {
  getDocument,
  getDocuments,
  getPatientDocuments,
  getDemoLabel,
  getDemoPrintOff,
  getDocumentTypes,
  deleteDocument,
  getVisitSummary,
  updateDocument,
  uploadDocument,
} from '@/services'
import { useTasksStore } from './useTasks'
import { type UrgentIQDocument, type UrgentIQDocuments } from '@/types'

const LETTER_TEMPLATE_ID = 13

interface DocumentState {
  document: any | null;
  documents: UrgentIQDocuments;
  clinicLetters: UrgentIQDocuments;
  documentTypes: any[];
  patientDocuments: UrgentIQDocument[];
  setDocument: (document: any) => void;
  setDocuments: (documents: UrgentIQDocuments) => void;
  setDocumentTypes: (documentTypes: any[]) => void;
  getDocumentsByPatientId: (patientId: number) => UrgentIQDocument[];
  setPatientDocuments: (patientDocuments: UrgentIQDocument[]) => void;
  setClinicLetters: (clinicLetters: UrgentIQDocuments) => void;
}

export const useDocumentStore = create<DocumentState>()(
  devtools(
    persist(
      (set, get) => ({
        document: null,
        documents: { documentPageList: [] },
        clinicLetters: { documentPageList: [] },
        documentTypes: [],
        patientDocuments: [],
        setDocument: (document) => set({ document }),
        setDocuments: (documents) => set({ documents }),
        setDocumentTypes: (documentTypes) => set({ documentTypes }),
        getDocumentsByPatientId: (patientId) => {
          const allDocuments = get().documents.documentPageList ?? []
          return allDocuments.filter((doc) => doc.patientId === patientId)
        },
        setPatientDocuments: (patientDocuments) => set({ patientDocuments }),
        setClinicLetters: (clinicLetters) => set({ clinicLetters }),
      }),
      {
        name: 'document',
      }
    )
  )
)

export const useQueryGetDocument = (
  documentId: number,
  getToken: any
): QueryObserverResult<any, unknown> => {
  const { setDocument } = useDocumentStore()

  return useQuery<any>(
    ['document', documentId],
    async () => await getDocument(documentId, getToken),
    {
      enabled: !!documentId,
      onSuccess: (data) => {
        setDocument(data)
      },
    }
  )
}
export const useQueryGetClinicLetter = (
  getToken: any
): QueryObserverResult<UrgentIQDocuments, unknown> => {
  const { setClinicLetters } = useDocumentStore()

  return useQuery<UrgentIQDocuments>(
    ['clinic-letters'],
    async () =>
      await getDocuments(
        getToken,
        undefined,
        undefined,
        undefined,
        undefined,
        [LETTER_TEMPLATE_ID],
        undefined,
        undefined
      ),
    {
      onSuccess: (data) => {
        setClinicLetters(data)
      },
      initialData: {},
    }
  )
}

export const useQueryGetDocuments = (
  getToken: any,
  pageNumber?: number,
  pageSize?: number,
  sortColumn?: 0 | 1 | 2 | 3,
  sortAscending?: boolean,
  documentTypeId?: number[],
  accountUserId?: number[],
  documentName?: string,
  patientId?: number,
  clinicId?: number,
  visitId?: number
): QueryObserverResult<UrgentIQDocuments, unknown> => {
  const { setDocuments } = useDocumentStore()

  return useQuery<UrgentIQDocuments>(
    [
      'documents',
      pageNumber,
      pageSize,
      sortColumn,
      sortAscending,
      documentTypeId,
      accountUserId,
      documentName,
      patientId,
      clinicId,
      visitId,
    ],
    async () =>
      await getDocuments(
        getToken,
        pageNumber,
        pageSize,
        sortColumn,
        sortAscending,
        documentTypeId,
        accountUserId,
        documentName,
        patientId,
        clinicId,
        visitId
      ),
    {
      onSuccess: (data) => {
        setDocuments(data)
      },
      initialData: {},
    }
  )
}

export const useQueryGetPatientDocuments = (
  patientId: number,
  getToken: any
): QueryObserverResult<any[], unknown> => {
  const { setPatientDocuments, patientDocuments } = useDocumentStore()

  return useQuery<any[]>(
    ['patient-documents', patientId],
    async () => await getPatientDocuments(patientId, getToken),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        const otherPatientDocs = patientDocuments.filter(
          (doc) => doc.patientId !== patientId
        )
        const newDocs = [...otherPatientDocs, ...data]
        setPatientDocuments(newDocs)
      },
      initialData: [],
    }
  )
}

export const useQueryGetDocumentTypes = (
  getToken: any
): QueryObserverResult<any[], unknown> => {
  const { setDocumentTypes } = useDocumentStore()

  return useQuery<any[]>(
    ['patient-document-types'],
    async () => await getDocumentTypes(getToken),
    {
      onSuccess: (data) => {
        setDocumentTypes(data)
      },
      initialData: [],
    }
  )
}

export const useQueryGetDemoLabel = (
  visitId: number,
  getToken: any
): QueryObserverResult<any, unknown> => {
  const { setDocument } = useDocumentStore()

  return useQuery<any>(
    ['demo-label', visitId],
    async () => await getDemoLabel(visitId, getToken),
    {
      enabled: !!visitId,
      onSuccess: (data) => {
        setDocument(data)
      },
    }
  )
}

export const useQueryGetDemoPrintOff = (
  visitId: number,
  getToken: any
): QueryObserverResult<any, unknown> => {
  return useQuery(['demo-print-off', visitId], async () => {
    return await getDemoPrintOff(visitId, getToken)
  })
}

export const useMutateDeleteDocument = (
  getToken: any
): UseMutationResult<any, unknown, number> => {
  return useMutation(async (documentId: number) => {
    return await deleteDocument(documentId, getToken)
  })
}

export const useQueryGetVisitSummary = (
  visitId: number,
  getToken: any
): QueryObserverResult<any, unknown> => {
  return useQuery(
    ['visit-summary', visitId],
    async () => {
      return await getVisitSummary(visitId, getToken)
    },
    {
      enabled: !!visitId,
    }
  )
}

export const useMutateUpdateDocument = (
  getToken: any
): UseMutationResult<
  any,
  unknown,
  { documentId: number; document: UrgentIQDocument & { file?: any } }
> => {
  const { tasks, setTasks } = useTasksStore()
  return useMutation<any, unknown, { documentId: number; document: any }>(
    async ({ documentId, document }) => {
      await updateDocument(documentId, document, getToken)
      const newTasks = tasks.map((task) => {
        if (task.id === document.userTaskId) {
          return {
            ...task,
            documentId: [...(task.documentId ?? []), documentId],
          }
        }

        return task
      })

      setTasks(newTasks)
    },
    {
      onSuccess: () => {},
    }
  )
}

export const useMutateUploadNewDocument = (
  getToken: any
): UseMutationResult<any, unknown, { document: FormData }> => {
  const { documents, setDocuments } = useDocumentStore()
  const { tasks, setTasks } = useTasksStore()

  return useMutation<any, unknown, { document: FormData }>(
    async ({ document }) => {
      const res = await uploadDocument(getToken, document)
      const fullDoc = await getDocument(res.id, getToken)
      const newDocuments = {
        ...documents,
        documentPageList: [fullDoc, ...(documents?.documentPageList ?? [])],
      }
      setDocuments(newDocuments)
      const taskId: number | null = +(document.get('userTaskId') ?? '0')
      if (taskId) {
        const newTasks = tasks.map((task) => {
          if (task.id === taskId) {
            return {
              ...task,
              documentId: [...(task.documentId ?? []), res.id],
            }
          }
          return task
        })
        setTasks(newTasks)
      }
      return res
    }
  )
}
