import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getPatientSocialHistory,
  addNewPatientSocialHistory,
  deletePatientSocialHistory,
  updatePatientSocialHistory,
} from '@/services'
import { type PatientSocialHistory } from '@/types/PatientSocialHistory'

interface PatientSocialHistoryState {
  patientSocialHistories: PatientSocialHistory[] | [];
  setPatientSocialHistories: (socialHistories: PatientSocialHistory[]) => void;
}

export const usePatientSocialHistoryStore = create<PatientSocialHistoryState>()(
  devtools((set) => ({
    patientSocialHistories: [],
    setPatientSocialHistories: (histories: PatientSocialHistory[]) =>
      set({ patientSocialHistories: histories }),
  }))
)

export const useQueryGetPatientSocialHistory = (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): QueryObserverResult<PatientSocialHistory[], unknown> => {
  const { setPatientSocialHistories } = usePatientSocialHistoryStore()
  const key = visitId
    ? ['patient-social-history', patientId, visitId]
    : ['patient-social-history', patientId]
  return useQuery<PatientSocialHistory[]>(
    key,
    async () => await getPatientSocialHistory(patientId, getToken, visitId),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        const socialHistoryTypes = [
          { id: 1, name: 'Alcohol' },
          { id: 2, name: 'Tobacco' },
          { id: 3, name: 'Drugs' },
          { id: 4, name: 'Other' },
        ]

        const newData = data.map((history) => {
          const sType = socialHistoryTypes?.find(
            (type) => type.name === history.otherSocialHistory
          )
          return {
            ...history,
            type: sType?.name ?? history.otherSocialHistory,
          }
        })
        setPatientSocialHistories(newData)
      },
      initialData: [],
    }
  )
}

export const useMutateAddPatientSocialHistory = (
  getToken: any
): UseMutationResult<
  PatientSocialHistory,
  unknown,
  { history: PatientSocialHistory }
> => {
  const { patientSocialHistories, setPatientSocialHistories } =
    usePatientSocialHistoryStore()

  return useMutation<
    PatientSocialHistory,
    unknown,
    { history: PatientSocialHistory }
  >(
    async ({ history }) => await addNewPatientSocialHistory(history, getToken),
    {
      onSuccess: (data) => {
        setPatientSocialHistories([
          ...(patientSocialHistories as PatientSocialHistory[]),
          data,
        ])
      },
    }
  )
}

export const useMutateDeletePatientSocialHistory = (
  getToken: any
): UseMutationResult<
  PatientSocialHistory,
  unknown,
  { patientSocialHistoryId: number }
> => {
  const { patientSocialHistories, setPatientSocialHistories } =
    usePatientSocialHistoryStore()

  return useMutation<
    PatientSocialHistory,
    unknown,
    { patientSocialHistoryId: number }
  >(
    async ({ patientSocialHistoryId }) =>
      await deletePatientSocialHistory(patientSocialHistoryId, getToken),
    {
      onSuccess: (data) => {
        const newPatientSocialHistories = patientSocialHistories.filter(
          (history: PatientSocialHistory): boolean => history?.id !== data?.id
        )
        setPatientSocialHistories(newPatientSocialHistories)
      },
    }
  )
}

export const useMutateUpdatePatientSocialHistory = (
  getToken: any
): UseMutationResult<
  PatientSocialHistory,
  unknown,
  { patientSocialHistoryId: number; history: PatientSocialHistory }
> => {
  const { patientSocialHistories, setPatientSocialHistories } =
    usePatientSocialHistoryStore()

  return useMutation<
    PatientSocialHistory,
    unknown,
    { patientSocialHistoryId: number; history: PatientSocialHistory }
  >(
    async ({ patientSocialHistoryId, history }) =>
      await updatePatientSocialHistory(
        patientSocialHistoryId,
        history,
        getToken
      ),
    {
      onSuccess: (data) => {
        const newPatientSocialHistories = patientSocialHistories.map(
          (socialHistory: PatientSocialHistory): PatientSocialHistory => {
            if (socialHistory?.id === data?.id) {
              return data
            }
            return socialHistory
          }
        )
        setPatientSocialHistories(newPatientSocialHistories)
      },
    }
  )
}
