import React, { useContext } from 'react'
import { type MbscCalendarEvent, formatDate } from '@mobiscroll/react'
import { type RecurringAppointment, type Patient } from '@/types'
import { formatISO, isAfter, isSameDay } from 'date-fns'
import { formatInUTC, isEmpty, stringToColor } from '@/utility'
import { getStatusString } from '@/components/Columns/ColumnUtils'
import {
  ErrorMessage,
  days,
  getWeekDayNum,
  months,
  ordinalList,
} from '@/components/AppointmentsCalendar/AppointmentUtils'
import {
  createRecurringAppointment,
  deleteAppointmentById,
  deleteAppointmentsByGroupId,
  getAppointmentsByGroupId,
  updateRecurringAppointment,
} from '@/services'
import { RRule } from 'rrule'
import { useAuth } from '@clerk/nextjs'
import { useClinicStore } from '@/hook'

const ScheduleContext = React.createContext<ScheduleContextState>({
  isOpen: false,
  setOpen: () => {},
  isEdit: false,
  setEdit: () => {},
  currentPatient: undefined,
  submitError: null,
  setCurrentPatient: () => {},
  anchor: null,
  setAnchor: () => {},
  originalRecurringEvent: {},
  setOriginalRecurringEvent: () => {},
  recurringEditMode: 'current',
  setRecurringEditMode: () => {},
  editFromPopup: false,
  setEditFromPopup: () => {},
  recurringText: undefined,
  setRecurringText: () => {},
  recurringDelete: false,
  setRecurringDelete: () => {},
  isRecurringEditOpen: false,
  setRecurringEditOpen: () => {},
  mySelectedDate: new Date(),
  setSelectedDate: () => {},
  popupEventVisitType: undefined,
  setVisitType: () => {},
  popupEventProvider: undefined,
  setProvider: () => {},
  popupEventStatus: undefined,
  setStatus: () => {},
  popupEventTitle: undefined,
  setTitle: () => {},
  popupEventNotes: '',
  setNotes: () => {},
  popupEventDate: [],
  setDate: () => {},
  popupEventId: undefined,
  setId: () => {},
  tempEvent: {},
  setTempEvent: () => {},
  selectedRepeat: 'norepeat',
  setSelectedRepeat: () => {},
  repeatType: 'daily',
  setRepeatType: () => {},
  repeatNr: '1',
  setRepeatNr: () => {},
  selectedMonth: 1,
  setMonth: () => {},
  monthlyDays: [1],
  setMonthlyDays: () => {},
  monthlyDay: 1,
  setMonthlyDay: () => {},
  yearlyDays: [1],
  setYearlyDays: () => {},
  yearlyDay: 1,
  setYearlyDay: () => {},
  weekDays: ['SU'],
  setWeekDays: () => {},
  condition: 'never',
  setCondition: () => {},
  untilDate: '',
  setUntilDate: () => {},
  occurrences: '10',
  setOccurrences: () => {},
  popupEventOpenQRCode: false,
  setOpenQRCode: () => {},
  popupEventHasQRCode: false,
  setHasQRCode: () => {},
  start: null,
  startRef: () => {},
  end: null,
  endRef: () => {},
  myEvents: [],
  setMyEvents: () => {},
  eventOccurrence: {},
  setEventOccurrence: () => {},
  newEvent: {},
  setNewEvent: () => {},
  toastOpen: false,
  setToastOpen: () => {},
  toastMessage: '',
  setToastMessage: () => {},
  descriptionChange: () => {},
  visitTypeChange: () => {},
  providerChange: () => {},
  statusChange: () => {},
  repeatData: [],
  setRepeatData: () => {},
  updateOptionDates: () => {},
  dateChange: () => {},
  repeatChange: () => {},
  repeatTypeChange: () => {},
  repeatNrChange: () => {},
  conditionChange: () => {},
  untilDateChange: () => {},
  occurrencesChange: () => {},
  populateMonthDays: () => {},
  controls: [],
  monthsChange: () => {},
  monthlyDayChange: () => {},
  yearlyDayChange: () => {},
  weekDayChange: () => {},
  respSetting: {},
  deleteEvent: async () => {},
  onDeleteClick: () => {},
  headerText: '',
  deleteRecurringEvent: () => {},
  getCustomRule: () => {},
  generateRRuleString: () => '',
  navigateTo: () => {},
  saveEvent: () => {},
  popupButtons: [],
  onPopupClose: () => {},
  resetCustomValues: () => {},
  loadPopupForm: () => {},
})

interface ScheduleProviderProps {
  children: React.ReactNode;
}

export const ScheduleProvider: React.FC<ScheduleProviderProps> = ({
  children,
}) => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const [isOpen, setOpen] = React.useState<boolean>(false)
  const [isEdit, setEdit] = React.useState<boolean>(false)
  const [currentPatient, setCurrentPatient] = React.useState<Patient>()
  const [anchor, setAnchor] = React.useState<any>(null)
  const [submitError, setSubmitError] = React.useState<string | null>(null)
  const [originalRecurringEvent, setOriginalRecurringEvent] =
    React.useState<any>()
  const [recurringEditMode, setRecurringEditMode] =
    React.useState<RecurringEditMode>('current')
  const [editFromPopup, setEditFromPopup] = React.useState<boolean>(false)
  const [recurringText, setRecurringText] = React.useState<string>()
  const [recurringDelete, setRecurringDelete] = React.useState<boolean>()
  const [isRecurringEditOpen, setRecurringEditOpen] = React.useState<boolean>()
  const [mySelectedDate, setSelectedDate] = React.useState<any>(new Date())
  const [popupEventVisitType, setVisitType] = React.useState<number>()
  const [popupEventProvider, setProvider] = React.useState<number>()
  const [popupEventStatus, setStatus] = React.useState<number>()
  const [popupEventTitle, setTitle] = React.useState<string | undefined>('')
  const [popupEventNotes, setNotes] = React.useState<string>('')
  const [popupEventDate, setDate] = React.useState<any>([])
  const [popupEventId, setId] = React.useState<number>()
  const [tempEvent, setTempEvent] = React.useState<any>(null)

  const [selectedRepeat, setSelectedRepeat] =
    React.useState<string>('norepeat')
  const [repeatType, setRepeatType] = React.useState<string>('daily')
  const [repeatNr, setRepeatNr] = React.useState<string>('1')
  const [selectedMonth, setMonth] = React.useState<number>(1)
  const [monthlyDays, setMonthlyDays] = React.useState<number[]>([1])
  const [monthlyDay, setMonthlyDay] = React.useState<number>(1)
  const [yearlyDays, setYearlyDays] = React.useState<number[]>([1])
  const [yearlyDay, setYearlyDay] = React.useState<number>(1)
  const [weekDays, setWeekDays] = React.useState<any>(['SU'])

  const [condition, setCondition] = React.useState<string>('never')
  const [untilDate, setUntilDate] = React.useState<string>()
  const [occurrences, setOccurrences] = React.useState<string>('10')
  const [popupEventOpenQRCode, setOpenQRCode] = React.useState<boolean>(false)
  const [popupEventHasQRCode, setHasQRCode] = React.useState<boolean>(false)
  const [start, startRef] = React.useState<any>(null)
  const [end, endRef] = React.useState<any>(null)
  const [myEvents, setMyEvents] = React.useState<MbscCalendarEvent[]>([])
  const [eventOccurrence, setEventOccurrence] = React.useState<any>()
  const [newEvent, setNewEvent] = React.useState<MbscCalendarEvent>()
  const [toastOpen, setToastOpen] = React.useState<boolean>(false)
  const [toastMessage, setToastMessage] = React.useState<string>('')

  React.useEffect(() => {
    if (currentPatient && !isEmpty(currentPatient)) {
      setSubmitError(null)
    }
  }, [currentPatient])

  type RecurringEditMode = 'all' | 'current' | 'following';

  const descriptionChange = React.useCallback((ev: any) => {
    setNotes(ev.target.value)
  }, [])

  const visitTypeChange = React.useCallback((ev: any) => {
    setVisitType(ev.value)
  }, [])

  const providerChange = React.useCallback((ev: any) => {
    setProvider(ev.value)
  }, [])

  const statusChange = React.useCallback((ev: any) => {
    setStatus(ev.value)
  }, [])

  const [repeatData, setRepeatData] = React.useState([
    {
      value: 'norepeat',
      text: 'Does not repeat',
    },
    {
      value: 'daily',
      text: 'Daily',
    },
    {
      value: 'weekly',
      text: 'Weekly',
    },
    {
      value: 'monthly',
      text: 'Monthly',
    },
    {
      value: 'monthly-pos',
      text: 'Monthly',
    },
    {
      value: 'yearly',
      text: 'Yearly',
    },
    {
      value: 'yearly-pos',
      text: 'Yearly',
    },
    {
      value: 'weekday',
      text: 'Every weekday (Monday to Friday)',
    },
    {
      value: 'custom',
      text: 'Custom',
    },
  ])

  const updateOptionDates = React.useCallback(
    (d: Date) => {
      const weekday = d.getDay()
      const monthday = d.getDate()
      const newData = repeatData.slice(0)
      const weekNr = getWeekDayNum(d)

      for (let i = 0; i < newData.length; ++i) {
        const item = newData[i]
        switch (item.value) {
          case 'weekly':
            item.text = 'Weekly on ' + days[weekday].name
            break
          case 'monthly':
            item.text = 'Monthly on day ' + `${monthday}`
            break
          case 'monthly-pos':
            item.text =
              'Monthly on the ' +
              `${ordinalList[weekNr as keyof typeof ordinalList]}` +
              ' ' +
              days[weekday].name
            break
          case 'yearly':
            item.text =
              'Annually on ' + months[d.getMonth()].text + ' ' + `${monthday}`
            break
          case 'yearly-pos':
            item.text =
              'Annually on the ' +
              ordinalList[weekNr as keyof typeof ordinalList] +
              ' ' +
              days[weekday].name +
              ' of ' +
              `${months[d.getMonth()].text}`
            break
          default:
        }
      }
      setRepeatData(newData)
    },
    [repeatData]
  )

  const dateChange = React.useCallback(
    (args: any) => {
      const d = args.value
      setDate(d)
      updateOptionDates(d[0])
    },
    [updateOptionDates]
  )

  const repeatChange = React.useCallback((ev: any) => {
    setSelectedRepeat(ev.value)
  }, [])

  const repeatTypeChange = React.useCallback((ev: any) => {
    setRepeatType(ev.target.value)
  }, [])

  const repeatNrChange = React.useCallback((ev: any) => {
    setRepeatNr(ev.target.value)
  }, [])

  const conditionChange = React.useCallback((ev: any) => {
    setCondition(ev.target.value)
  }, [])

  const untilDateChange = React.useCallback((ev: any) => {
    setUntilDate(ev.value)
  }, [])

  const occurrencesChange = React.useCallback((ev: any) => {
    setOccurrences(ev.target.value)
  }, [])

  const populateMonthDays = React.useCallback(
    (month: any, type: any) => {
      const day30 = [2, 4, 6, 9, 11]
      const newValues: any = []

      for (let i = 1; i <= 31; i++) {
        if (
          !(i === 31 && day30.includes(month)) &&
          !(i === 30 && month === 2)
        ) {
          newValues.push(i.toString())
        }
      }

      if (type === 'monthly') {
        setMonthlyDays(newValues)
        setMonthlyDay(1)
      } else {
        setYearlyDays(newValues)
        setYearlyDay(1)
      }
    },
    [setMonthlyDays, setYearlyDays]
  )

  const controls = React.useMemo<any>(() => ['calendar', 'time'], [])

  const monthsChange = React.useCallback(
    (ev: any) => {
      setMonth(ev.value)
      populateMonthDays(ev.value, 'yearly')
    },
    [populateMonthDays]
  )

  const monthlyDayChange = React.useCallback((ev: any) => {
    setMonthlyDay(ev.value)
  }, [])

  const yearlyDayChange = React.useCallback((ev: any) => {
    setYearlyDay(ev.value)
  }, [])

  const weekDayChange = React.useCallback(
    (ev: any) => {
      const value = ev.target.value
      if (ev.target.checked) {
        setWeekDays([...weekDays, ev.target.value])
      } else {
        setWeekDays(weekDays.filter((item: any) => item !== value))
      }
    },
    [weekDays]
  )

  const respSetting = React.useMemo(() => {
    return {
      xsmall: {
        controls: ['datetime'],
      },
      medium: {
        controls: ['calendar', 'time'],
        touchUi: false,
      },
    }
  }, [])

  const deleteEvent = React.useCallback(
    async (event: any) => {
      try {
        const appts = await getAppointmentsByGroupId(
          event?.id as number,
          getToken
        )
        const appointment = appts?.find((item: any) =>
          isSameDay(
            new Date(item?.appointmentStartDate),
            new Date(event?.start)
          )
        )
        if (appointment) {
          await deleteAppointmentById(appointment?.id, getToken)
          setMyEvents((prevEvents) =>
            prevEvents.filter((item) => item.id !== event.id)
          )
        } else {
          console.log('No appointment found to delete')
        }
      } catch (error) {
        console.error('Failed to delete event:', error)
        throw error
      }
    },
    [myEvents, getToken]
  )

  const onDeleteClick = React.useCallback(() => {
    if (tempEvent.recurring) {
      setRecurringText('Delete')
      setRecurringDelete(true)
      setRecurringEditOpen(true)
    } else {
      deleteEvent(tempEvent).catch((err) => {
        throw err
      })
      setOpen(false)
    }
  }, [deleteEvent, tempEvent])

  const headerText = React.useMemo(() => {
    if (isEdit && currentPatient?.firstName && currentPatient?.lastName) {
      return `${currentPatient.firstName} ${currentPatient.lastName}`
    }
    return 'New Appointment'
  }, [isEdit, currentPatient])

  const deleteRecurringEvent = React.useCallback(() => {
    switch (recurringEditMode) {
      case 'current': {
        let currentExceptions = tempEvent.recurringException || []
        currentExceptions = [...currentExceptions, tempEvent.start]

        const newEv = {
          ...originalRecurringEvent,
          recurringException: currentExceptions,
        }
        const index = myEvents.findIndex(
          (x) => x.id === originalRecurringEvent.id
        )
        const newEventList = [...myEvents]

        getAppointmentsByGroupId(tempEvent.id, getToken)
          .then((res) => {
            const filtered = res?.filter(
              (item: { appointmentStartDate: string | number | Date }) => {
                return isSameDay(
                  new Date(item.appointmentStartDate),
                  tempEvent.start
                )
              }
            )
            if (filtered.length === 0) {
              setToastMessage(ErrorMessage.ApptNotExisting)
              setToastOpen(true)
            } else {
              Promise.all(
                filtered?.map(
                  async (item: any) =>
                    await updateRecurringAppointment(
                      item.id,
                      {
                        ...item,
                        patient: null,
                        patientId: null,
                        isExcluded: true,
                      },
                      getToken
                    )
                )
              )
                .then(() => {
                  newEventList.splice(index, 1, newEv)
                  setMyEvents(newEventList)
                })
                .catch((err) => {
                  setToastMessage(ErrorMessage.ErrorInDeletion)
                  setToastOpen(true)
                  throw err
                })
            }
          })
          .catch((err) => {
            throw err
          })
        break
      }
      case 'following': {
        let exceptions = tempEvent.recurringException || []
        exceptions = [...exceptions, tempEvent.start]

        const newE = {
          ...originalRecurringEvent,
          recurringException: exceptions,
        }
        newE.recurring.until = tempEvent.start
        const i = myEvents.findIndex((x) => x.id === originalRecurringEvent.id)
        const newEvList = [...myEvents]

        getAppointmentsByGroupId(tempEvent.id, getToken)
          .then((res) => {
            const filtered = res?.filter(
              (item: { appointmentStartDate: string | number | Date }) =>
                isAfter(new Date(item.appointmentStartDate), tempEvent.start) ||
                isSameDay(new Date(item.appointmentStartDate), tempEvent.start)
            )
            if (filtered.length === 0) {
              setToastMessage(ErrorMessage.ApptNotExisting)
              setToastOpen(true)
            } else {
              Promise.all(
                filtered.map(
                  async (item: any) =>
                    await updateRecurringAppointment(
                      item.id,
                      {
                        ...item,
                        patientId: null,
                        patient: null,
                        isExcluded: true,
                      },
                      getToken
                    )
                )
              )
                .then(() => {
                  newEvList.splice(i, 1, newE)
                  setMyEvents(newEvList)
                })
                .catch((err) => {
                  setToastMessage(ErrorMessage.ErrorInDeletion)
                  setToastOpen(true)
                  throw err
                })
            }
          })
          .catch((err) => {
            throw err
          })
        break
      }
      case 'all':
        deleteAppointmentsByGroupId(tempEvent.id, getToken)
          .then(() => {
            setMyEvents(myEvents.filter((item) => item.id !== tempEvent.id))
          })
          .catch((err) => {
            setToastMessage(ErrorMessage.ErrorInDeletion)
            setToastOpen(true)
            throw err
          })

        break
    }
    setOpen(false)
    setRecurringEditOpen(false)
  }, [myEvents, originalRecurringEvent, recurringEditMode, tempEvent])

  const getCustomRule = React.useCallback(() => {
    let recurringRule: any
    const d = editFromPopup ? popupEventDate[0] : new Date(tempEvent?.start)
    const weekday = d.getDay()
    const monthday = d.getDate()
    const weekNr = getWeekDayNum(d)

    if (editFromPopup && tempEvent.start && tempEvent.recurring) {
      switch (selectedRepeat) {
        case 'daily':
          recurringRule = {
            repeat: 'daily',
          }
          break
        case 'weekly':
          recurringRule = {
            repeat: 'weekly',
            weekDays: days[weekday].short,
          }
          break
        case 'monthly':
          recurringRule = {
            repeat: 'monthly',
            day: monthday,
          }
          break
        case 'monthly-pos':
          recurringRule = {
            repeat: 'monthly',
            weekDays: days[weekday].short,
            pos: weekNr,
          }
          break
        case 'yearly':
          recurringRule = {
            repeat: 'yearly',
            day: monthday,
            month: (d.getMonth() as number) + 1,
          }
          break
        case 'yearly-pos':
          tempEvent.recurring = {
            repeat: 'yearly',
            month: (d.getMonth() as number) + 1,
            weekDays: days[weekday].short,
            pos: weekNr,
          }
          break
        default:
      }
    } else {
      switch (selectedRepeat) {
        case 'daily':
          recurringRule = { repeat: 'daily' }
          break
        case 'weekly':
          recurringRule = {
            repeat: 'weekly',
            weekDays: days[d.getDay()].short,
          }
          break
        case 'monthly':
          recurringRule = { repeat: 'monthly', day: d.getDate() }
          break
        case 'monthly-pos':
          recurringRule = {
            repeat: 'monthly',
            weekDays: days[weekday].short,
            pos: weekNr,
          }
          break
        case 'yearly':
          recurringRule = {
            repeat: 'yearly',
            month: (d.getMonth() as number) + 1,
          }
          break
        case 'yearly-pos':
          recurringRule = {
            repeat: 'yearly',
            month: (d.getMonth() as number) + 1,
            weekDays: days[weekday].short,
            pos: weekNr,
          }
          break
        case 'weekday':
          recurringRule = { repeat: 'weekly', weekDays: 'MO,TU,WE,TH,FR' }
          break
        case 'custom':
        case 'custom-value':
          recurringRule = {
            repeat: repeatType,
            interval: repeatNr,
          }

          switch (repeatType) {
            case 'weekly':
              recurringRule.weekDays = weekDays.join(',')
              break
            case 'monthly':
              recurringRule.day = monthlyDay
              break
            case 'yearly':
              recurringRule.day = yearlyDay
              recurringRule.month = selectedMonth
              break

            default:
          }

          switch (condition) {
            case 'until':
              recurringRule.until = untilDate
              break
            case 'count':
              recurringRule.count = occurrences
              break
            default:
          }
          break
        default:
      }
    }
    return recurringRule
  }, [
    selectedRepeat,
    deleteRecurringEvent,
    editFromPopup,
    repeatType,
    weekDays,
    eventOccurrence,
    myEvents,
    newEvent,
    originalRecurringEvent,
    recurringDelete,
    recurringEditMode,
    tempEvent,
    condition,
    untilDate,
    occurrences,
  ])

  const generateRRuleString = (recurringRule: any): string => {
    if (!recurringRule || typeof recurringRule.repeat === 'undefined') {
      console.error('recurringRule is undefined or missing repeat property')
      return ''
    }
    const options: any = {}

    const RRuleWithIndex = RRule as unknown as RRuleWithIndexSignature

    interface RRuleWithIndexSignature extends RRule {
      [key: string]: any;
    }

    switch (recurringRule.repeat) {
      case 'daily':
        options.freq = RRule.DAILY
        break
      case 'weekly':
        options.freq = RRule.WEEKLY
        if (recurringRule.weekDays) {
          options.byweekday = recurringRule.weekDays
            .split(',')
            .map((day: string | number) => RRuleWithIndex[day])
        }
        break
      case 'monthly':
        options.freq = RRule.MONTHLY
        if (recurringRule.day) {
          options.bymonthday = recurringRule.day
        }
        if (recurringRule.weekDays && recurringRule.pos) {
          options.byweekday = RRuleWithIndex[recurringRule.weekDays]
          options.bysetpos = recurringRule.pos
        }
        break
      case 'yearly':
        options.freq = RRule.YEARLY
        if (recurringRule.month) {
          options.bymonth = recurringRule.month
        }
        if (recurringRule.day) {
          options.bymonthday = recurringRule.day
        }
        if (recurringRule.weekDays && recurringRule.pos) {
          options.byweekday = RRuleWithIndex[recurringRule.weekDays]
          options.bysetpos = recurringRule.pos
        }
        break
      default:
        options.freq = recurringRule.repeat
        break
    }

    if (recurringRule.interval) {
      options.interval = recurringRule.interval
    }
    if (recurringRule.until) {
      options.until = new Date(recurringRule.until)
    }
    if (recurringRule.count) {
      options.count = recurringRule.count
    }

    return new RRule(options).toString().slice(6)
  }

  const navigateTo = React.useCallback(() => {
    const rec = tempEvent?.recurring
    const d = new Date(tempEvent?.start)
    let nextYear = 0

    // navigate the calendar to the correct view
    if (rec && rec.repeat === 'yearly') {
      if (d.getMonth() + 1 > +rec.month && d.getDay() > +rec.day) {
        nextYear = 1
      }
      setSelectedDate(
        new Date(d.getFullYear() + nextYear, rec.month - 1, rec.day)
      )
    } else {
      setSelectedDate(d)
    }
  }, [tempEvent])

  const saveEvent = React.useCallback(() => {
    const customRule = getCustomRule()
    const recurrenceRule = generateRRuleString(customRule)
    const appointment: RecurringAppointment = {
      visitTypeId: popupEventVisitType,
      providerId: popupEventProvider,
      appointmentStartDate: formatInUTC(formatISO(new Date(popupEventDate[0]))),
      appointmentEndDate: formatInUTC(formatISO(new Date(popupEventDate[1]))),
      patientId: currentPatient?.id,
      notes: popupEventNotes,
      status: popupEventStatus as 0 | 1 | 2 | 3 | 4,
      statusString: getStatusString(popupEventStatus ?? 0),
      clinicId,
      recurrenceRule,
    }
    if (!currentPatient || isEmpty(currentPatient)) {
      setSubmitError(ErrorMessage.PatientNotSelected)
      return
    }
    if (isEdit) {
      if (selectedRepeat === 'norepeat') {
        deleteAppointmentById(tempEvent?.id, getToken).catch((err) => {
          throw err
        })
      } else {
        deleteAppointmentsByGroupId(tempEvent?.id, getToken).catch((err) => {
          throw err
        })
      }
    }
    createRecurringAppointment(appointment, getToken)
      .then((appt) => {
        const newEv = {
          id: appt[0]?.id,
          title: `${currentPatient?.firstName!} ${currentPatient?.lastName!}`,
          visitTypeId: popupEventVisitType,
          providerId: popupEventProvider,
          description: popupEventNotes,
          start: new Date(popupEventDate[0]),
          end: new Date(popupEventDate[1]),
          allDay: false,
          color: stringToColor(
            `${currentPatient?.firstName!} ${currentPatient?.lastName!}`
          ),
          clinicId,
          status: popupEventStatus,
          statusString: getStatusString(popupEventStatus!),
          notes: popupEventNotes,
          patientId: currentPatient?.id,
          recurring: getCustomRule(),
        }

        if (isEdit) {
          const index = myEvents.findIndex((x) => x.id === tempEvent?.id)
          const newEventList = [...myEvents]

          newEventList.splice(index, 1, newEv)
          setMyEvents(newEventList)
        } else {
          setMyEvents([...myEvents, newEv])
        }
        navigateTo()
        setOpen(false)
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        setTitle('')
        setNotes('')
        setVisitType(undefined)
        setProvider(undefined)
        setStatus(0)
        setSelectedDate(new Date(popupEventDate[0]))
        setDate([])
        setCurrentPatient(undefined)
      })
  }, [
    currentPatient,
    tempEvent,
    popupEventTitle,
    popupEventNotes,
    popupEventDate,
    popupEventVisitType,
    popupEventProvider,
    popupEventStatus,
    getCustomRule,
    isEdit,
    navigateTo,
    myEvents,
  ])

  const popupButtons = React.useMemo<any>(() => {
    if (isEdit) {
      return [
        'cancel',
        {
          handler: () => {
            if (Object.keys(originalRecurringEvent).length !== 0) {
              setEditFromPopup(true)
              setRecurringText('Edit')
              setRecurringDelete(false)
              setRecurringEditOpen(true)
            } else {
              saveEvent()
            }
          },
          text: 'Save',
          cssClass: 'mbsc-popup-button-primary',
        },
      ]
    } else {
      return [
        'cancel',
        {
          handler: () => {
            saveEvent()
          },
          text: 'Add',
          cssClass: 'mbsc-popup-button-primary',
        },
      ]
    }
  }, [isEdit, originalRecurringEvent, saveEvent, currentPatient])

  const onPopupClose = React.useCallback(() => {
    setRepeatData(repeatData.filter((item) => item.value !== 'custom-value'))
    if (!isEdit) {
      setMyEvents([...myEvents])
    }
    setEditFromPopup(false)
    setOpen(false)
    setSubmitError(null)
  }, [isEdit, myEvents, repeatData])

  const resetCustomValues = React.useCallback(() => {
    setRepeatType('daily')
    setRepeatNr('1')
    setCondition('never')
    setOccurrences('10')
    setMonth(1)
    setMonthlyDay(1)
    setYearlyDay(1)
    setWeekDays(['SU'])
    setSelectedRepeat('norepeat')
    setRepeatData(repeatData.filter((item) => item.value !== 'custom-value'))
  }, [repeatData])

  const loadPopupForm = React.useCallback(
    (event: any) => {
      const startDate = new Date(event.start)
      setHasQRCode(event.hasQRCode)
      setId(event.id)
      setTitle(event.title)
      setNotes(event.notes)
      setDate([startDate, event.end])
      setVisitType(event.visitTypeId)
      setProvider(event.providerId)
      setStatus(event.status ?? 0)
      setSubmitError(null)
      setUntilDate(
        formatDate(
          'YYYY-MM-DD',
          new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() + 1
          )
        )
      )

      const d = new Date(event.start)
      const weekday = d.getDay()
      const monthday = d.getDate()
      const newData = repeatData.slice(0)
      const weekNr = getWeekDayNum(d)

      for (let i = 0; i < newData.length; ++i) {
        const item = newData[i]
        switch (item.value) {
          case 'weekly':
            item.text = 'Weekly on ' + days[weekday].name
            break
          case 'monthly':
            item.text = 'Monthly on day ' + `${monthday}`
            break
          case 'monthly-pos':
            item.text =
              'Monthly on the ' +
              `${ordinalList[weekNr as keyof typeof ordinalList]}` +
              ' ' +
              days[weekday].name
            break
          case 'yearly':
            item.text =
              'Annually on ' +
              `${months[d.getMonth()].text}` +
              ' ' +
              `${monthday}`
            break
          case 'yearly-pos':
            item.text =
              'Annually on the ' +
              `${ordinalList[weekNr as keyof typeof ordinalList]}` +
              ' ' +
              days[weekday].name +
              ' of ' +
              `${months[d.getMonth()].text}`
            break
          default:
        }
      }

      setRepeatData(newData)

      const rec = event.recurring

      if (rec) {
        setRepeatType(rec.repeat)
        setWeekDays(rec.repeat === 'weekly' ? rec.weekDays.split(',') : ['SU'])
        if (rec.interval) {
          let customText = ''
          const nr = rec.interval

          setRepeatNr(nr)

          switch (rec.repeat) {
            case 'daily':
              customText =
                nr > 1 ? 'Every ' + `${Number(nr)}` + ' days' : 'Daily'
              break
            case 'weekly':
              customText =
                nr > 1 ? 'Every ' + `${Number(nr)}` + ' weeks' : 'Weekly'
              customText += ' on ' + `${Number(rec.weekDays)}`
              break
            case 'monthly':
              setMonthlyDay(rec.day)
              customText =
                nr > 1 ? 'Every ' + `${Number(nr)}` + ' months' : 'Monthly'
              customText += ' on day ' + `${rec.day as number}`
              break
            case 'yearly':
              setYearlyDay(rec.day)
              setMonth(rec.month)
              customText =
                nr > 1 ? 'Every ' + `${Number(nr)}` + ' years' : 'Annualy'
              customText +=
                ' on ' +
                months[rec.month - 1].text +
                ' ' +
                `${rec.day as number}`
              break
            default:
          }

          if (rec.until) {
            setCondition('until')
            setUntilDate(rec.until)
            customText +=
              ' until ' + formatDate('MMMM D, YYYY', new Date(rec.until))
          } else if (rec.count) {
            setCondition('count')
            setOccurrences(rec.count)
            customText += ', ' + `${Number(rec.count)}` + ' times'
          } else {
            setCondition('never')
          }

          setRepeatData([
            ...repeatData,
            { value: 'custom-value', text: customText },
          ])
          setSelectedRepeat('custom-value')
        } else if (rec.weekDays === 'MO,TU,WE,TH,FR') {
          setSelectedRepeat('weekday')
        } else {
          setSelectedRepeat(`${Number(rec.repeat)}` + (rec.pos ? '-pos' : ''))
        }
      } else {
        resetCustomValues()
      }
    },
    [repeatData, weekDays, resetCustomValues]
  )

  const value: ScheduleContextState = {
    isOpen,
    setOpen,
    isEdit,
    setEdit,
    currentPatient,
    setCurrentPatient,
    anchor,
    submitError,
    setAnchor,
    originalRecurringEvent,
    setOriginalRecurringEvent,
    recurringEditMode,
    setRecurringEditMode,
    editFromPopup,
    setEditFromPopup,
    recurringText,
    setRecurringText,
    recurringDelete,
    setRecurringDelete,
    isRecurringEditOpen,
    setRecurringEditOpen,
    mySelectedDate,
    setSelectedDate,
    popupEventVisitType,
    setVisitType,
    popupEventProvider,
    setProvider,
    popupEventStatus,
    setStatus,
    popupEventTitle,
    setTitle,
    popupEventNotes,
    setNotes,
    popupEventDate,
    setDate,
    popupEventId,
    setId,
    tempEvent,
    setTempEvent,
    selectedRepeat,
    setSelectedRepeat,
    repeatType,
    setRepeatType,
    repeatNr,
    setRepeatNr,
    selectedMonth,
    setMonth,
    monthlyDays,
    setMonthlyDays,
    monthlyDay,
    setMonthlyDay,
    yearlyDays,
    setYearlyDays,
    yearlyDay,
    setYearlyDay,
    weekDays,
    setWeekDays,
    condition,
    setCondition,
    untilDate,
    setUntilDate,
    occurrences,
    setOccurrences,
    popupEventOpenQRCode,
    setOpenQRCode,
    popupEventHasQRCode,
    setHasQRCode,
    start,
    startRef,
    end,
    endRef,
    myEvents,
    setMyEvents,
    eventOccurrence,
    setEventOccurrence,
    newEvent,
    setNewEvent,
    toastOpen,
    setToastOpen,
    toastMessage,
    setToastMessage,
    descriptionChange,
    visitTypeChange,
    providerChange,
    statusChange,
    repeatData,
    setRepeatData,
    updateOptionDates,
    dateChange,
    repeatChange,
    repeatTypeChange,
    repeatNrChange,
    conditionChange,
    untilDateChange,
    occurrencesChange,
    populateMonthDays,
    controls,
    monthsChange,
    monthlyDayChange,
    yearlyDayChange,
    weekDayChange,
    respSetting,
    deleteEvent,
    onDeleteClick,
    headerText,
    deleteRecurringEvent,
    getCustomRule,
    generateRRuleString,
    navigateTo,
    saveEvent,
    popupButtons,
    onPopupClose,
    resetCustomValues,
    loadPopupForm,
  }

  return (
    <ScheduleContext.Provider value={value}>
      {children}
    </ScheduleContext.Provider>
  )
}

export const useScheduleState = (): ScheduleContextState =>
  useContext(ScheduleContext)

type RecurringEditMode = 'all' | 'current' | 'following';

interface ScheduleContextState {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  isEdit: boolean;
  setEdit: (value: boolean) => void;
  currentPatient: Patient | undefined;
  setCurrentPatient: (value: Patient) => void;
  anchor: any;
  submitError: string | null;
  setAnchor: (value: any) => void;
  originalRecurringEvent: any;
  setOriginalRecurringEvent: (value: any) => void;
  recurringEditMode: RecurringEditMode;
  setRecurringEditMode: (value: RecurringEditMode) => void;
  editFromPopup: boolean;
  setEditFromPopup: (value: boolean) => void;
  recurringText: string | undefined;
  setRecurringText: (value: string) => void;
  recurringDelete: boolean | undefined;
  setRecurringDelete: (value: boolean) => void;
  isRecurringEditOpen: boolean | undefined;
  setRecurringEditOpen: (value: boolean) => void;
  mySelectedDate: any;
  setSelectedDate: (value: any) => void;
  popupEventVisitType: number | undefined;
  setVisitType: (value: number) => void;
  popupEventProvider: number | undefined;
  setProvider: (value: number) => void;
  popupEventStatus: number | undefined;
  setStatus: (value: number) => void;
  popupEventTitle: string | undefined;
  setTitle: (value: string) => void;
  popupEventNotes: string;
  setNotes: (value: string) => void;
  popupEventDate: any;
  setDate: (value: any) => void;
  popupEventId: number | undefined;
  setId: (value: number) => void;
  tempEvent: any;
  setTempEvent: (value: any) => void;
  selectedRepeat: string;
  setSelectedRepeat: (value: string) => void;
  repeatType: string;
  setRepeatType: (value: string) => void;
  repeatNr: string;
  setRepeatNr: (value: string) => void;
  selectedMonth: number;
  setMonth: (value: number) => void;
  monthlyDays: number[];
  setMonthlyDays: (value: number[]) => void;
  monthlyDay: number;
  setMonthlyDay: (value: number) => void;
  yearlyDays: number[];
  setYearlyDays: (value: number[]) => void;
  yearlyDay: number;
  setYearlyDay: (value: number) => void;
  weekDays: any;
  setWeekDays: (value: any) => void;
  condition: string;
  setCondition: (value: string) => void;
  untilDate: string | undefined;
  setUntilDate: (value: string) => void;
  occurrences: string;
  setOccurrences: (value: string) => void;
  popupEventOpenQRCode: boolean;
  setOpenQRCode: (value: boolean) => void;
  popupEventHasQRCode: boolean;
  setHasQRCode: (value: boolean) => void;
  start: any;
  startRef: any;
  end: any;
  endRef: any;
  myEvents: MbscCalendarEvent[];
  setMyEvents: (value: MbscCalendarEvent[]) => void;
  eventOccurrence: any;
  setEventOccurrence: (value: any) => void;
  newEvent: MbscCalendarEvent | undefined;
  setNewEvent: (value: MbscCalendarEvent) => void;
  toastOpen: boolean;
  setToastOpen: (value: boolean) => void;
  toastMessage: string;
  setToastMessage: (value: string) => void;
  descriptionChange: (ev: any) => void;
  visitTypeChange: (ev: any) => void;
  providerChange: (ev: any) => void;
  statusChange: (ev: any) => void;
  repeatData: any;
  setRepeatData: (value: any) => void;
  updateOptionDates: (d: Date) => void;
  dateChange: (args: any) => void;
  repeatChange: (ev: any) => void;
  repeatTypeChange: (ev: any) => void;
  repeatNrChange: (ev: any) => void;
  conditionChange: (ev: any) => void;
  untilDateChange: (ev: any) => void;
  occurrencesChange: (ev: any) => void;
  populateMonthDays: (month: any, type: any) => void;
  controls: any;
  monthsChange: (ev: any) => void;
  monthlyDayChange: (ev: any) => void;
  yearlyDayChange: (ev: any) => void;
  weekDayChange: (ev: any) => void;
  respSetting: any;
  deleteEvent: (event: any) => Promise<void>;
  onDeleteClick: () => void;
  headerText: string;
  deleteRecurringEvent: () => void;
  getCustomRule: () => any;
  generateRRuleString: (recurringRule: any) => string;
  navigateTo: () => void;
  saveEvent: () => void;
  popupButtons: any;
  onPopupClose: () => void;
  resetCustomValues: () => void;
  loadPopupForm: (event: any) => void;
}
