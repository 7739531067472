import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type DocumoFax } from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

const setUpFax = fetcher
  .path('/api/DocumoFax/SourceDocument/{documentId}')
  .method('post')
  .create({ 'api-version': undefined })

export const sendFax = async (
  documentId: number,
  fax: DocumoFax,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setUpFax({ documentId, ...fax })
  } catch (error) {
    if (error instanceof setUpFax.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetClinicDocuments = fetcher
  .path('/api/DocumoFax/Clinic/{clinicId}')
  .method('get')
  .create()

export const getFaxes = async (
  clinicId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetClinicDocuments({ clinicId })).data
  } catch (error) {
    if (error instanceof setupGetClinicDocuments.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupRetry = fetcher
  .path('/api/DocumoFax/Retry/{documoFaxId}')
  .method('put')
  .create({ 'api-version': undefined })

export const retryFax = async (
  documoFaxId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupRetry({ documoFaxId })).data
  } catch (error) {
    if (error instanceof setupRetry.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

export const sendMultipleFaxes = async (
  documentIds: number[],
  fax: DocumoFax,
  getToken: any
): Promise<any> => {
  const formData = new FormData()

  documentIds.forEach((id) => { formData.append('DocumentIdList', id.toString()) })

  for (const key in fax) {
    if (Object.prototype.hasOwnProperty.call(fax, key)) {
      const element = fax[key as keyof DocumoFax]
      if (element !== null && element !== undefined) {
        formData.append(key, element.toString())
      }
    }
  }

  try {
    const response = await fetch(`${process.env.API_URL ?? ''}/api/DocumoFax/Attachment`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${(await getToken({ template: 'UrgentIQ' })) ?? ''}`,
      },
      body: formData,
    })

    if (!response.ok) {
      const errorData = await response.json()
      if (response.status === 400) {
        throw errorData.validationErrors
      } else if (response.status === 500) {
        throw errorData.errorMessage
      } else {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
    }
    return await response.json()
  } catch (error) {
    console.error('Error sending multiple faxes:', error)
    throw error
  }
}