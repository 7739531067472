import { createTheme } from '@mui/material/styles'

export default createTheme({
  typography: {
    fontFamily: 'Lato',
  },
  palette: {
    text: {
      primary: '#000000DE',
      secondary: '#686868',
      disabled: '#A7A7A7',
    },
    primary: {
      main: '#0543B0',
      light: '#043995',
      dark: '#4888FA',
    },
    secondary: {
      main: '#00C7C3',
      light: '#AEF2F1',
      dark: '#00A3A0',
      contrast: '#141414',
    },
    error: {
      main: '#DD1311',
      light: '#8F0A0A',
      dark: '#EF3533',
      contrast: '#FFFFFF',
    },
    warning: {
      main: '#EF6C00',
      light: '#FF9800',
      dark: '#EF6C00',
      contrast: '#FFFFFF',
    },
    info: {
      main: '#0288D1',
      light: '#03A9F4',
      dark: '#01579B',
      contrast: '#FFFFFF',
    },
    success: {
      main: '#2E7D32',
      light: '#4CAF50',
      dark: '#1B5E20',
      contrast: '#FFFFFF',
    },
    action: {
      active: '#707070',
      hover: '#F5F5F5',
      selected: '#EBEBEB',
      disabledBackground: '#E0E0E0',
      focus: '#E0E0E0',
      disabled: '#9E9E9E',
    },
    white: '#FFF',
    black: '#000',
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F6F8FD',
    },
    divider: '#DDDFE8',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          border: '1px solid #0543B0',
          fontWeight: 500,
          fontSize: '18px',
          lineHeight: '20px',
          height: '40px',
          width: 'fit-content',
          textTransform: 'capitalize',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '20px',
          color: '#717899',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '36px !important',
          borderStyle: 'none',
          overflow: 'hidden',
          '& > *': {
            borderRadius: '6px',
          },

          '& > fieldset': {
            borderRadius: '6px',
            border: '1px solid #DDDFE8',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          width: '16px',
          height: '16px',
          fontSize: '12px',
          lineHeight: '20px',
          marginRight: '10px',
          marginLeft: '13px',
          '&.Mui-checked': {
            color: '#0543B0',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          display: 'flex',
          minHeight: '44px',
          justifyContent: 'flex-start',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontStyle: 'normal',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          lineHeight: '16px',
          backgroundColor: '#2E3353',
        },
        arrow: {
          '&::before': {
            backgroundColor: '#2E3353',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          border: '1px solid #DDDFE8',
          borderRadius: '3px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '12px !important',
          paddingRight: '0 !important',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: 'rgb(235, 240, 249) !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
  },
})
