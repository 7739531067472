import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type PatientSocialHistory } from '../types/PatientSocialHistory'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetPatientSocialHistory = fetcher
  .path('/api/PatientSocialHistory/Patient/{patientId}')
  .method('get')
  .create()

export const getPatientSocialHistory = async (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientSocialHistory({ patientId, visitId })).data
  } catch (error) {
    if (error instanceof setupGetPatientSocialHistory.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createUser = fetcher
  .path('/api/PatientSocialHistory')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewPatientSocialHistory = async (
  user: PatientSocialHistory,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await createUser(user)).data
  } catch (error) {
    if (error instanceof createUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeletePatientSocialHistory = fetcher
  .path('/api/PatientSocialHistory/{patientSocialHistoryId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deletePatientSocialHistory = async (
  patientSocialHistoryId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeletePatientSocialHistory({ patientSocialHistoryId })
  } catch (error) {
    if (error instanceof setupGetPatientSocialHistory.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdatePatientSocialHistory = fetcher
  .path('/api/PatientSocialHistory/{patientSocialHistoryId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updatePatientSocialHistory = async (
  patientSocialHistoryId: number,
  patientSocialHistory: PatientSocialHistory,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupUpdatePatientSocialHistory({
      patientSocialHistoryId,
      ...patientSocialHistory,
    })
  } catch (error) {
    if (error instanceof setupGetPatientSocialHistory.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
