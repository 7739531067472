import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import { getIQSearch } from '@/services'
import { type IQBarValue } from '@/types'

interface IQBarState {
  iqResults: IQBarValue[] | [];
  setIQResults: (iqResults: IQBarValue[]) => void;
}

export const useIQBarStore = create<IQBarState>()(
  devtools((set) => ({
    iqResults: [],
    setIQResults: (results: IQBarValue[]) => set({ iqResults: results }),
  }))
)

export const useIQBar = (
  query: string,
  getToken: any,
  fieldName?: string | undefined
): QueryObserverResult<IQBarValue[], unknown> => {
  const { setIQResults } = useIQBarStore()

  return useQuery<IQBarValue[]>(
    ['iq-bar', query],
    async () => await getIQSearch(query, getToken, fieldName),
    {
      enabled: !!query && query.length > 2,
      onSuccess: (data) => {
        setIQResults(data)
      },
      initialData: [],
    }
  )
}
