import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import { type DocumoFax } from '@/types/DocumoFax'
import { sendFax, getFaxes, retryFax, sendMultipleFaxes } from '@/services'

interface DocumoFaxState {
  faxes: DocumoFax[] | [];
  setFaxes: (faxes: DocumoFax[]) => void;
}

export const useDocumoFaxStore = create<DocumoFaxState>()(
  devtools((set) => ({
    faxes: [],
    setFaxes: (faxes: DocumoFax[]) => set({ faxes }),
  }))
)

export const useQueryGetFaxes = (
  clinicId: number,
  getToken: any
): QueryObserverResult<DocumoFax[], unknown> => {
  const { setFaxes } = useDocumoFaxStore()

  return useQuery<DocumoFax[]>(
    ['faxes', clinicId],
    async () => await getFaxes(clinicId, getToken),
    {
      enabled: !!clinicId,
      onSuccess: (data) => {
        setFaxes(data)
      },
      initialData: [],
    }
  )
}

export const useMutateSendFax = (
  getToken: any
): UseMutationResult<any, unknown, { documentId: number; fax: DocumoFax }> => {
  const { faxes, setFaxes } = useDocumoFaxStore()

  return useMutation<any, unknown, { documentId: number; fax: DocumoFax }>(
    async ({ documentId, fax }) => await sendFax(documentId, fax, getToken),
    {
      onSuccess: (data) => {
        setFaxes([...(faxes as DocumoFax[]), data])
        return data
      },
    }
  )
}

export const useMutateRetryFax = (
  getToken: any
): UseMutationResult<any, unknown, { documoFaxId: number }> => {
  const { faxes, setFaxes } = useDocumoFaxStore()

  return useMutation<any, unknown, { documoFaxId: number }>(
    async ({ documoFaxId }) => await retryFax(documoFaxId, getToken),
    {
      onSuccess: (data) => {
        const newFaxes = faxes.map((fax: DocumoFax): DocumoFax => {
          if (fax?.id === data?.id) {
            return data
          }
          return fax
        })
        setFaxes(newFaxes)
      },
    }
  )
}

export const useMutateSendMultipleFaxes = (
  getToken: any
): UseMutationResult<any, unknown, { documentIds: number[]; fax: DocumoFax }> => {
  const { faxes, setFaxes } = useDocumoFaxStore()

  return useMutation<any, unknown, { documentIds: number[]; fax: DocumoFax }>(
    async ({ documentIds, fax }) => await sendMultipleFaxes(documentIds, fax, getToken),
    {
      onSuccess: (data) => {
        setFaxes([...(faxes as DocumoFax[]), data])
        return data
      },
    }
  )
}
