import { type paths } from '@/generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

const setupGetIQSuggestion = fetcher
  .path('/api/IqModel/{visitId}')
  .method('get')
  .create()

export const getIQSuggestion = async (
  visitId: number,
  getToken: any,
  hpiComplaintId?: number,
  hpiSymptomId?: number,
  hpiLocationId?: number,
  hpiSeverity?: number
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (
      await setupGetIQSuggestion({
        visitId,
        hpiComplaintId,
        hpiSymptomId,
        hpiLocationId,
        hpiSeverity,
      })
    ).data
  } catch (error) {
    if (error instanceof setupGetIQSuggestion.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
