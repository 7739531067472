export const convertLockingFailureMessage = (message: string): string => {
  const errorMessageMap = [
    [
      'Cannot lock visits without diagnosis code(s).',
      'Cannot lock visits without diagnosis code(s).\nPlease enter at least one diagnosis code before locking the chart.',
    ],
    [
      'No ClerkId found.',
      'Something went wrong, please refresh the page to continue.',
    ],
    [
      'No ClerkUser found.',
      'Something went wrong, please refresh the page to continue.',
    ],
    [
      'Only administrators can lock visits',
      'Only administrators can lock visits.',
    ],
    [
      'No Visit found.',
      "This can happen when multiple users working on the same visit.\nYou are on the visit page and some other user might delete it and it's not reflected on UI real-time.\nInstead of crashing the entire application we throw an exception.",
    ],
    [
      'Unable to create pin',
      'Sorry something went wrong.\nPlease try again or contact customer support.',
    ],
    [
      'Unable to update pin',
      'Sorry something went wrong.\nPlease try again or contact customer support.',
    ],
    [
      'Unable to delete pin',
      'Sorry something went wrong.\nPlease try again or contact customer support.',
    ],
    [
      'Existing Pin salt is null',
      'Try again with a different pin.\nStill fails contact UrgentIQ',
    ],
    [
      'Existing Pin hash is null',
      'Try again with a different pin.\nStill fails contact UrgentIQ',
    ],
    ['Pin must be', 'Please enter a pin that is at least', true],
    [
      'Cannot lock visits without EM code.',
      'Cannot lock visits without EM code.\nPlease enter EM code before locking the chart.',
    ],
    [
      'Cannot lock visits without setting provider.',
      'Cannot lock visits without setting provider.\nPlease set provider before locking the chart.',
    ],
  ]
  const len = errorMessageMap.length
  for (let i = 0; i < len; i++) {
    if (message.includes(errorMessageMap[i][0] as string)) {
      if (errorMessageMap[i][2] === true) {
        return message.replace(
          errorMessageMap[i][0] as string,
          errorMessageMap[i][1] as string
        )
      }
      return errorMessageMap[i][1] as string
    }
  }
  return message
}
