import { isEmpty } from '@/utility'
import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type GetToken } from '@clerk/types'
import { type VisitCategory } from '@/types/VisitCategory'
import { type PatientType } from '@/types'
import { type RosSymptom } from '../types/RosSymptom'

const fetcher = Fetcher.for<paths>()

const setupGetStates = fetcher
  .path('/api/ReferenceData/State')
  .method('get')
  .create()

export const getStates = async (getToken: any): Promise<any> => {
  const token = (await getToken({ template: 'UrgentIQ' })) ?? ''

  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token as string}`,
      },
    },
  })

  try {
    const states = await setupGetStates({})
    return states.data.sort((a, b) =>
      (a.name ?? '').localeCompare(b.name ?? '')
    )
  } catch (error) {
    if (error instanceof setupGetStates.Error) {
      const errorType = error.getActualType()
      switch (errorType.status) {
        case 400:
          throw new Error(errorType.data.validationErrors) // 400 response data
        case 500:
          throw new Error(errorType.data.errorMessage) // 500 response data
        default:
          throw error // re-throw the original error if it's not one of the known error types
      }
    } else {
      throw error // re-throw the original error
    }
  }
}

export const getPharmacies = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  return []
}

const setupGetVisitCategories = fetcher
  .path('/api/ReferenceData/VisitCategory')
  .method('get')
  .create()

export const getVisitCategories = async (
  clinicId: number,
  getToken: GetToken
): Promise<VisitCategory[]> => {
  const token = (await getToken({ template: 'UrgentIQ' }))! ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })

  return (await setupGetVisitCategories({ clinicId })).data
}

const setupGetPatientTypes = fetcher
  .path('/api/ReferenceData/PatientType')
  .method('get')
  .create()

export const getPatientTypes = async (
  getToken: GetToken
): Promise<PatientType[]> => {
  const token = (await getToken({ template: 'UrgentIQ' }))! ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })

  return (await setupGetPatientTypes({})).data
}

const setupGetVisitTypes = fetcher
  .path('/api/ReferenceData/VisitType')
  .method('get')
  .create()

export const getVisitTypes = async (
  clinicId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    const visitTypesResponse = await setupGetVisitTypes({ clinicId })
    const visitTypes = visitTypesResponse.data.sort((a: any, b: any) =>
      (a.name ?? '').localeCompare(b.name ?? '')
    )
    return visitTypes
  } catch (error) {
    if (error instanceof setupGetVisitTypes.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetInformationSources = fetcher
  .path('/api/ReferenceData/InformationSource')
  .method('get')
  .create()

export const getInformationSources = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetInformationSources({})).data
  } catch (error) {
    if (error instanceof setupGetInformationSources.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetComplaints = fetcher
  .path('/api/ReferenceData/Complaint')
  .method('get')
  .create()

export const getComplaints = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetComplaints({})).data
  } catch (error) {
    if (error instanceof setupGetComplaints.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetInsuranceType = fetcher
  .path('/api/ReferenceData/InsuranceType')
  .method('get')
  .create()

export const getInsuranceType = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetInsuranceType({})).data
  } catch (error) {
    if (error instanceof setupGetInsuranceType.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetExamSystem = fetcher
  .path('/api/ReferenceData/ExamSystem')
  .method('get')
  .create()

export const getExamSystem = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetExamSystem({})).data
  } catch (error) {
    if (error instanceof setupGetExamSystem.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetExamSystemComponent = fetcher
  .path('/api/ReferenceData/ExamSystemComponent')
  .method('get')
  .create()

export const getExamSystemComponent = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetExamSystemComponent({})).data
  } catch (error) {
    if (error instanceof setupGetExamSystemComponent.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupAllergen = fetcher
  .path('/api/ReferenceData/Allergen')
  .method('get')
  .create()

export const getAllergen = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupAllergen({})).data
  } catch (error) {
    if (error instanceof setupAllergen.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupAllergenReaction = fetcher
  .path('/api/ReferenceData/AllergyReactions')
  .method('get')
  .create()

export const getAllergenReaction = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupAllergenReaction({})).data
  } catch (error) {
    if (error instanceof setupAllergenReaction.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetMedication = fetcher
  .path('/api/ReferenceData/Medication')
  .method('get')
  .create()

export const getMedication = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetMedication({})).data
  } catch (error) {
    if (error instanceof setupGetMedication.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetSymptoms = fetcher
  .path('/api/ReferenceData/IllnessSymptom')
  .method('get')
  .create()

export const getSymptoms = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetSymptoms({})).data
  } catch (error) {
    if (error instanceof setupGetSymptoms.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupGetDXCodes = fetcher
  .path('/api/ReferenceData/DiagnosisCode')
  .method('get')
  .create()

export const getDXCodes = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetDXCodes({})).data
  } catch (error) {
    if (error instanceof setupGetDXCodes.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupGetDXModifiers = fetcher
  .path('/api/ReferenceData/DiagnosisModifier')
  .method('get')
  .create()

export const getDXModifiers = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetDXModifiers({})).data
  } catch (error) {
    if (error instanceof setupGetDXModifiers.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupGetCodingModifiers = fetcher
  .path('/api/ReferenceData/CodingModifier')
  .method('get')
  .create()

export const getCodingModifiers = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetCodingModifiers({})).data
  } catch (error) {
    if (error instanceof setupGetCodingModifiers.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupGetOrders = fetcher
  .path('/api/ReferenceData/Order')
  .method('get')
  .create()

export const getOrders = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetOrders({})).data
  } catch (error) {
    if (error instanceof setupGetOrders.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupGetOrderStatus = fetcher
  .path('/api/ReferenceData/OrderStatus')
  .method('get')
  .create()

export const getOrderStatus = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetOrderStatus({})).data
  } catch (error) {
    if (error instanceof setupGetOrderStatus.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupGetProcedures = fetcher
  .path('/api/ReferenceData/ClinicalProcedure')
  .method('get')
  .create()

export const getProcedureTypes = async (
  clinicId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetProcedures({ clinicId })).data.map((item) => {
      const code: string = !isEmpty(item.cptCode)
        ? item.cptCode ?? ''
        : !isEmpty(item.hcpcsCode)
        ? item.hcpcsCode ?? ''
        : !isEmpty(item.customCode)
        ? item.customCode ?? ''
        : ''
      return {
        ...item,
        code,
        cptCode: undefined,
        hcpcsCode: undefined,
        customCode: undefined,
      }
    })
  } catch (error) {
    if (error instanceof setupGetProcedures.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetRosSystems = fetcher
  .path('/api/ReferenceData/RosSystem')
  .method('get')
  .create()

export const getRosSystems = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetRosSystems({})).data.sort(
      (prev, next) => prev.name?.localeCompare(next.name ?? '') ?? 0
    )
  } catch (error) {
    if (error instanceof setupGetRosSystems.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupGetRosSymptons = fetcher
  .path('/api/ReferenceData/RosSymptom')
  .method('get')
  .create()

export const getRosSymptoms = async (getToken: any, date: Date): Promise<RosSymptom[]> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  let rosSymptoms: RosSymptom[] = []
  try {
    rosSymptoms = (await setupGetRosSymptons({ visitDate: date.toLocaleDateString() })).data
  } catch (error) {
    if (error instanceof setupGetRosSymptons.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }

  return rosSymptoms
}

const setupGetTimezones = fetcher
  .path('/api/ReferenceData/TimeZone')
  .method('get')
  .create()

export const getTimezone = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetTimezones({})).data
  } catch (error) {
    if (error instanceof setupGetTimezones.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetCPTModifiers = fetcher
  .path('/api/ReferenceData/CptModifierCode')
  .method('get')
  .create()

export const getCPTModifiers = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetCPTModifiers({})).data
  } catch (error) {
    if (error instanceof setupGetCPTModifiers.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetImmunizations = fetcher
  .path('/api/ReferenceData/Immunization')
  .method('get')
  .create()

export const getImmunization = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetImmunizations({})).data
  } catch (error) {
    if (error instanceof setupGetImmunizations.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetInsurance = fetcher
  .path('/api/PVerifyInsurance')
  .method('get')
  .create()

export const getInsurance = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetInsurance({})).data
  } catch (error) {
    if (error instanceof setupGetInsurance.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetSurgeryType = fetcher
  .path('/api/ReferenceData/Surgery')
  .method('get')
  .create()

export const getSurgeryType = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetSurgeryType({})).data
  } catch (error) {
    if (error instanceof setupGetSurgeryType.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetMedicalConditionsType = fetcher
  .path('/api/ReferenceData/MedicalCondition')
  .method('get')
  .create()

export const getMedicalConditionsType = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetMedicalConditionsType({})).data
  } catch (error) {
    if (error instanceof setupGetMedicalConditionsType.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetLocationTypes = fetcher
  .path('/api/ReferenceData/IllnessLocation')
  .method('get')
  .create()

export const getLocationTypes = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetLocationTypes({})).data
  } catch (error) {
    if (error instanceof setupGetLocationTypes.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetIllnessLocations = fetcher
  .path('/api/ReferenceData/IllnessLocation/{complaintId}')
  .method('get')
  .create()

export const getIllnessLocations = async (
  complaintId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    if (complaintId === -1) {
      return []
    }
    return (await setupGetIllnessLocations({ complaintId })).data
  } catch (error) {
    if (error instanceof setupGetIllnessLocations.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupGetModifyingFactorTypes = fetcher
  .path('/api/ReferenceData/IllnessModifyingFactors')
  .method('get')
  .create()

export const getModifyingFactorTypes = async (getToken: any): Promise<any> => {
  const token = (await getToken({ template: 'UrgentIQ' })) as string ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`
      }
    }
  })
  try {
    return (await setupGetModifyingFactorTypes({})).data
  } catch (error) {
    if (error instanceof setupGetModifyingFactorTypes.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
const setupGetIllnessModifyingFactors = fetcher
  .path('/api/ReferenceData/IllnessModifyingFactors/{complaintId}')
  .method('get')
  .create()

export const getIllnessModifyingFactors = async (complaintId: number, getToken: any): Promise<any> => {
  const token = (await getToken({ template: 'UrgentIQ' })) as string ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`
      }
    }
  })
  try {
    if (complaintId === -1) {
      return []
    }
    return (await setupGetIllnessModifyingFactors({ complaintId })).data
  } catch (error) {
    if (error instanceof setupGetIllnessModifyingFactors.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetRelationships = fetcher
  .path('/api/ReferenceData/Relationship')
  .method('get')
  .create()

export const getRelationships = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetRelationships({})).data
  } catch (error) {
    if (error instanceof setupGetRelationships.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetUserTaskTypes = fetcher
  .path('/api/ReferenceData/UserTaskType')
  .method('get')
  .create()

export const getUserTaskTypes = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetUserTaskTypes({})).data
  } catch (error) {
    if (error instanceof setupGetUserTaskTypes.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetUserTaskStatus = fetcher
  .path('/api/ReferenceData/UserTaskStatus')
  .method('get')
  .create()

export const getUserTaskStatus = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetUserTaskStatus({})).data
  } catch (error) {
    if (error instanceof setupGetUserTaskStatus.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetUserTaskPriority = fetcher
  .path('/api/ReferenceData/UserTaskPriority')
  .method('get')
  .create()

export const getUserTaskPriority = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetUserTaskPriority({})).data
  } catch (error) {
    if (error instanceof setupGetUserTaskPriority.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 00 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetExamResultFactors = fetcher
  .path('/api/ReferenceData/ExamResultFactor')
  .method('get')
  .create()

export const getExamResultFactors = async (getToken: any): Promise<any> => {
  const token = (await getToken({ template: 'UrgentIQ' })) ?? ''

  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token as string}`,
      },
    },
  })

  try {
    return (await setupGetExamResultFactors({})).data
  } catch (error) {
    if (error instanceof setupGetExamResultFactors.Error) {
      const errorType = error.getActualType()
      switch (errorType.status) {
        case 400:
          throw new Error(errorType.data.validationErrors) // 400 response data
        case 500:
          throw new Error(errorType.data.errorMessage) // 500 response data
        default:
          throw error // re-throw the original error if it's not one of the known error types
      }
    } else {
      throw error // re-throw the original error
    }
  }
}

const setupGetRosResultFactors = fetcher
  .path('/api/ReferenceData/RosResultFactor')
  .method('get')
  .create()

export const getRosResultFactors = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetRosResultFactors({})).data
  } catch (error) {
    if (error instanceof setupGetRosResultFactors.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetClinicalProcedureField = fetcher
  .path('/api/ReferenceData/ClinicalProcedureField')
  .method('get')
  .create()

export const getClinicalProcedureFields = async (
  clinicalProcedureId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetClinicalProcedureField({ clinicalProcedureId })).data
  } catch (error) {
    if (error instanceof setupGetClinicalProcedureField.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetMeasurementUnits = fetcher
  .path('/api/ReferenceData/MeasurementUnit')
  .method('get')
  .create()

export const getMeasurementUnits = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''

  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetMeasurementUnits({})).data
  } catch (error) {
    if (error instanceof setupGetMeasurementUnits.Error) {
      const errorType = error.getActualType()
      switch (errorType.status) {
        case 400:
          throw new Error(errorType.data.validationErrors) // 400 response data
        case 500:
          throw new Error(errorType.data.errorMessage) // 500 response data
        default:
          throw error // re-throw the original error if it's not one of the known error types
      }
    } else {
      throw error // re-throw the original error
    }
  }
}

const setupGetTemperatureMethods = fetcher
  .path('/api/ReferenceData/TemperatureMethods')
  .method('get')
  .create()

export const getTemperatureMethods = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''

  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetTemperatureMethods({})).data
  } catch (error) {
    if (error instanceof setupGetTemperatureMethods.Error) {
      const errorType = error.getActualType()
      switch (errorType.status) {
        case 400:
          throw new Error(errorType.data.validationErrors) // 400 response data
        case 500:
          throw new Error(errorType.data.errorMessage) // 500 response data
        default:
          throw error // re-throw the original error if it's not one of the known error types
      }
    } else {
      throw error // re-throw the original error
    }
  }
}

const setupGetMenstruationDetails= fetcher
  .path('/api/ReferenceData/MenstruationDetails')
  .method('get')
  .create()

export const getMenstruationDetails = async (getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''

  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetMenstruationDetails({})).data
  } catch (error) {
    if (error instanceof setupGetMenstruationDetails.Error) {
      const errorType = error.getActualType()
      switch (errorType.status) {
        case 400:
          throw new Error(errorType.data.validationErrors) // 400 response data
        case 500:
          throw new Error(errorType.data.errorMessage) // 500 response data
        default:
          throw error // re-throw the original error if it's not one of the known error types
      }
    } else {
      throw error // re-throw the original error
    }
  }
}
