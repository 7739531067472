import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getPatientAllergy,
  addNewPatientAllergy,
  deletePatientAllergy,
  updatePatientAllergy,
} from '@/services'
import { type PatientAllergy } from '@/types/PatientAllergy'

interface PatientAllergyState {
  patientAllergy: PatientAllergy[] | [];
  setPatientAllergy: (patientAllergy: PatientAllergy[]) => void;
}

export const usePatientAllergyStore = create<PatientAllergyState>()(
  devtools((set) => ({
    patientAllergy: [],
    setPatientAllergy: (patientAllergy: PatientAllergy[]) =>
      set({ patientAllergy }),
  }))
)

export const useQueryGetPatientAllergy = (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): QueryObserverResult<PatientAllergy[], unknown> => {
  const { setPatientAllergy } = usePatientAllergyStore()
  const key = visitId
    ? ['patient-allergy', patientId, visitId]
    : ['patient-allergy', patientId]
  return useQuery<PatientAllergy[]>(
    key,
    async () => await getPatientAllergy(patientId, getToken, visitId),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        setPatientAllergy(data)
      },
      initialData: [],
    }
  )
}

export const useMutateAddPatientAllergy = (
  getToken: any
): UseMutationResult<
  PatientAllergy,
  unknown,
  { patientAllergy: PatientAllergy }
> => {
  const { patientAllergy, setPatientAllergy } = usePatientAllergyStore()

  return useMutation<
    PatientAllergy,
    unknown,
    { patientAllergy: PatientAllergy }
  >(
    async ({ patientAllergy: pAllergy }) =>
      await addNewPatientAllergy(pAllergy, getToken),
    {
      onSuccess: (data) => {
        setPatientAllergy([...(patientAllergy as PatientAllergy[]), data])
      },
    }
  )
}

export const useMutateDeletePatientAllergy = (
  getToken: any
): UseMutationResult<PatientAllergy, unknown, { patientAllergyId: number }> => {
  const { patientAllergy, setPatientAllergy } = usePatientAllergyStore()

  return useMutation<PatientAllergy, unknown, { patientAllergyId: number }>(
    async ({ patientAllergyId }) =>
      await deletePatientAllergy(patientAllergyId, getToken),
    {
      onSuccess: (data) => {
        const newPatientAllergies = patientAllergy.filter(
          (patientAllergy: PatientAllergy): boolean =>
            patientAllergy?.id !== data?.id
        )
        setPatientAllergy(newPatientAllergies)
      },
    }
  )
}

export const useMutateUpdatePatientAllergy = (
  getToken: any
): UseMutationResult<
  PatientAllergy,
  unknown,
  { patientAllergyId: number; pAllergy: PatientAllergy }
> => {
  const { patientAllergy, setPatientAllergy } = usePatientAllergyStore()

  return useMutation<
    PatientAllergy,
    unknown,
    { patientAllergyId: number; pAllergy: PatientAllergy }
  >(
    async ({ patientAllergyId, pAllergy }) =>
      await updatePatientAllergy(patientAllergyId, pAllergy, getToken),
    {
      onSuccess: (data) => {
        const newPatientAllergies = patientAllergy.map(
          (existingPatientAllergy: PatientAllergy): PatientAllergy => {
            if (existingPatientAllergy?.id === data?.id) {
              return data
            }
            return existingPatientAllergy
          }
        )
        setPatientAllergy(newPatientAllergies)
      },
    }
  )
}
