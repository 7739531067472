import { type SetState, create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import { type VisitExamination } from '@/types/Exam'
import {
  addVisitExamination,
  deleteVisitExamination,
  getVisitExaminations,
  updateVisitExamination,
} from '@/services/ExaminationService'

type VisitExaminationCallback = (
  prev: VisitExamination[]
) => VisitExamination[];

interface ExaminationState {
  examinations: VisitExamination[];
  setExaminations: (
    param: VisitExamination[] | VisitExaminationCallback
  ) => void;
}

export const useExaminationStore = create<ExaminationState>()(
  devtools((set: SetState<ExaminationState>) => ({
    examinations: [] as VisitExamination[],
    setExaminations: (param: VisitExamination[] | VisitExaminationCallback) =>
      set((state) => ({
        examinations:
          typeof param === 'function' ? param(state.examinations) : param,
      })),
  }))
)

export const useQueryGetVisitExaminations = (
  vId: number,
  getToken: any
): QueryObserverResult<VisitExamination[], unknown> => {
  const { setExaminations } = useExaminationStore()

  return useQuery<VisitExamination[]>(
    ['examinations', vId],
    async () => await getVisitExaminations(vId, getToken),
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setExaminations(data)
      },
      onError: () => {
        setExaminations([])
      },
      initialData: [],
    }
  )
}

export const useMutateUpdateExamination = (
  getToken: any
): UseMutationResult<
  VisitExamination,
  unknown,
  { vId: number; visitExamination: VisitExamination }
> => {
  const { setExaminations } = useExaminationStore()
  return useMutation<
    VisitExamination,
    unknown,
    { vId: number; visitExamination: VisitExamination }
  >(
    async ({ vId, visitExamination }) =>
      await updateVisitExamination(vId, visitExamination, getToken),
    {
      onSuccess: (data) => {
        setExaminations((prev) =>
          prev.map((exam) => (exam.id === data?.id ? data : exam))
        )
      },
      onError: (error) => {
        throw error
      },
    }
  )
}

export const useMutateDeleteExamination = (
  getToken: any
): UseMutationResult<number, unknown, { vId: number }> => {
  const { setExaminations } = useExaminationStore()
  return useMutation<number, unknown, { vId: number }>(
    async ({ vId }) => await deleteVisitExamination(vId, getToken),
    {
      onSuccess: (data) => {
        setExaminations((prev) => [...prev.filter((exam) => exam.id !== data)])
      },
      onError: (error) => {
        throw error
      },
    }
  )
}

export const useMutateAddExamination = (
  getToken: any
): UseMutationResult<
  VisitExamination,
  unknown,
  { visitExamination: VisitExamination }
> => {
  const { setExaminations } = useExaminationStore()
  return useMutation<
    VisitExamination,
    unknown,
    { visitExamination: VisitExamination }
  >(
    async ({ visitExamination }) =>
      await addVisitExamination(visitExamination, getToken),
    {
      onSuccess: (data) => {
        setExaminations((prev) => [...prev, data])
      },
      onError: (error) => {
        throw error
      },
    }
  )
}
