import { type VisitExamination } from '@/types'
import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

const fetcher = Fetcher.for<paths>()

const setupGetExaminations = fetcher
  .path('/api/ReferenceData/Examination')
  .method('get')
  .create()

export const getExaminations = async (getToken: any, date: Date): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetExaminations({ date: date.toLocaleDateString() })).data
  } catch (error) {
    if (error instanceof setupGetExaminations.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetVisitExaminations = fetcher
  .path('/api/VisitExamination/Visit/{visitId}')
  .method('get')
  .create()

export const getVisitExaminations = async (
  visitId: number,
  getToken: any,
  handleError?: (error: Error) => void
): Promise<VisitExamination[]> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetVisitExaminations({ visitId })).data
  } catch (error) {
    handleError?.(error as Error)
    return []
  }
}

const setupGetVisitExamination = fetcher
  .path('/api/VisitExamination/{visitExaminationId}')
  .method('get')
  .create()

export const getVisitExamination = async (
  visitExaminationId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetVisitExamination({ visitExaminationId })).data
  } catch (error) {
    if (error instanceof setupGetVisitExamination.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateVisitExamination = fetcher
  .path('/api/VisitExamination/{visitExaminationId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateVisitExamination = async (
  visitExaminationId: number,
  visitExamination: VisitExamination,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    await setupUpdateVisitExamination({
      visitExaminationId,
      ...visitExamination,
    })
    return visitExamination
  } catch (error) {
    if (error instanceof setupUpdateVisitExamination.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeleteVisitExamination = fetcher
  .path('/api/VisitExamination/{visitExaminationId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteVisitExamination = async (
  visitExaminationId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    await setupDeleteVisitExamination({ visitExaminationId })
    return visitExaminationId
  } catch (error) {
    if (error instanceof setupDeleteVisitExamination.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupAddVisitExamination = fetcher
  .path('/api/VisitExamination')
  .method('post')
  .create({ 'api-version': undefined })

export const addVisitExamination = async (
  visitExamination: VisitExamination,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    const response = (await setupAddVisitExamination({ ...visitExamination }))
      .data
    return { ...visitExamination, id: (response as any).id }
  } catch (error) {
    if (error instanceof setupAddVisitExamination.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
