import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface GlobalState {
  currentDashboardTab: string;
  dashboardFilterList: DashboardFilter[];
  previousNavOpen: boolean;
  isEditing: boolean;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  isUnsavedDataPopupOpen: boolean;
  nextLocation: string | null;
  hasUnsavedData: boolean;
  dashboardFromDate: Date;
  dashboardToDate: Date;
  globalClinicId: number | null;
  lastDateUpdate: Date;
  updateDatesAtMidnight: () => void;
  setCurrentDashboardTab: (currentDashboardTab: string) => void;
  setDashboardFilterList: (dashboardFilterList: DashboardFilter[]) => void;
  setDashboardPagination: (pageSize: number, pageIndex: number) => void;
  setPreviousNavOpen: (previousNavOpen: boolean) => void;
  setIsEditing: (isEditing: boolean) => void;
  setIsUnsavedDataPopupOpen: (isUnsavedDataPopupOpen: boolean) => void;
  setNextLocation: (nextLocation: string | null) => void;
  setHasUnsavedData: (hasUnsavedData: boolean) => void;
  setDashboardFromDate: (dashboardFromDate: Date) => void;
  setDashboardToDate: (dashboardToDate: Date) => void;
  setGlobalClinicId: (globalClinicId: number) => void;
  setLastDateUpdate: (lastDateUpdate: Date) => void;
}

export const useGlobalStore = create<GlobalState>()(
  devtools(
    persist(
      (set) => ({
        currentDashboardTab: 'Waiting Room',
        previousNavOpen: false,
        dashboardFilterList: [],
        isEditing: false,
        isUnsavedDataPopupOpen: false,
        nextLocation: null,
        hasUnsavedData: false,
        pagination: {
          pageIndex: 0,
          pageSize: 50,
        },
        dashboardFromDate: new Date(),
        dashboardToDate: new Date(),
        lastDateUpdate: new Date(Date.now()),
        updateDatesAtMidnight: () => {
          const now = new Date()
          const tonightMidnight = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1
          )
          const msUntilMidnight = tonightMidnight.getTime() - now.getTime()

          setTimeout(() => {
            set({
              dashboardFromDate: new Date(),
              dashboardToDate: new Date(),
            })
            useGlobalStore.getState().updateDatesAtMidnight() // Reschedule the next update
          }, msUntilMidnight)
        },
        globalClinicId: 0,
        setCurrentDashboardTab: (currentDashboardTab: string) =>
          set({ currentDashboardTab }),
        setDashboardFilterList: (dashboardFilterList: DashboardFilter[]) =>
          set({ dashboardFilterList }),
        setDashboardPagination: (pageSize: number, pageIndex: number) =>
          set({ pagination: { pageSize, pageIndex } }),
        setPreviousNavOpen: (previousNavOpen: boolean) =>
          set({ previousNavOpen }),
        setIsEditing: (isEditing: boolean) => set({ isEditing }),
        setIsUnsavedDataPopupOpen: (isUnsavedDataPopupOpen: boolean) =>
          set({ isUnsavedDataPopupOpen }),
        setNextLocation: (nextLocation: string | null) => set({ nextLocation }),
        setHasUnsavedData: (hasUnsavedData: boolean) => set({ hasUnsavedData }),
        setDashboardFromDate: (dashboardFromDate: Date) =>
          set({ dashboardFromDate }),
        setDashboardToDate: (dashboardToDate: Date) => set({ dashboardToDate }),
        setGlobalClinicId: (globalClinicId: number) => set({ globalClinicId }),
        setLastDateUpdate: (lastDateUpdate: Date) => set({ lastDateUpdate }),
      }),
      {
        name: 'global-store',
      }
    )
  )
)

interface DashboardFilter {
  category: string;
  value: string;
}
