import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetInsuranceEligibility = fetcher
  .path('/api/InsuranceEligibility')
  .method('post')
  .create({ 'api-version': undefined })

export const getInsuranceEligibility = async (
  insurance: any,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetInsuranceEligibility(insurance)).data
  } catch (error) {
    if (error instanceof setupGetInsuranceEligibility.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        return errorType // 400 response data
      } else if (errorType.status === 500) {
        return errorType // 500 response data
      }
    }
  }
}

const setupGetPatientInsurance = fetcher
  .path('/api/PatientInsurance/Patient/{patientId}')
  .method('get')
  .create()

export const getPatientInsurance = async (
  patientId: any,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientInsurance({ patientId })).data
  } catch (error) {
    if (error instanceof setupGetInsuranceEligibility.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        return errorType // 400 response data
      } else if (errorType.status === 500) {
        return errorType // 500 response data
      }
    }
  }
}

const setupUpdatePatientInsurance = fetcher
  .path('/api/PatientInsurance/{patientInsuranceId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updatePatientInsurance = async (
  patientInsuranceId: any,
  insurance: any,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (
      await setupUpdatePatientInsurance({ patientInsuranceId, ...insurance })
    ).data
  } catch (error) {
    if (error instanceof setupGetInsuranceEligibility.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        return errorType // 400 response data
      } else if (errorType.status === 500) {
        return errorType // 500 response data
      }
    }
  }
}
