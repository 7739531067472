import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getPatientFamilyHistory,
  addNewPatientFamilyHistory,
  deletePatientFamilyHistory,
  updatePatientFamilyHistory,
} from '@/services'
import { type PatientFamilyHistory } from '@/types/PatientFamilyHistory'

interface PatientFamilyHistoryState {
  patientFamilyHistories: PatientFamilyHistory[] | [];
  setPatientFamilyHistories: (
    patientFamilyHistories: PatientFamilyHistory[]
  ) => void;
}

export const usePatientFamilyHistoryStore = create<PatientFamilyHistoryState>()(
  devtools((set) => ({
    patientFamilyHistories: [],
    setPatientFamilyHistories: (histories: PatientFamilyHistory[]) =>
      set({ patientFamilyHistories: histories }),
  }))
)

export const useQueryGetPatientFamilyHistory = (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): QueryObserverResult<PatientFamilyHistory[], unknown> => {
  const { setPatientFamilyHistories } = usePatientFamilyHistoryStore()
  const key = visitId
    ? ['patient-family-history', patientId, visitId]
    : ['patient-family-history', patientId]
  return useQuery<PatientFamilyHistory[]>(
    key,
    async () => await getPatientFamilyHistory(patientId, getToken, visitId),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        setPatientFamilyHistories(data)
      },
      initialData: [],
    }
  )
}

export const useMutateAddPatientFamilyHistory = (
  getToken: any
): UseMutationResult<
  PatientFamilyHistory,
  unknown,
  { patientFamilyHistories: PatientFamilyHistory }
> => {
  const { patientFamilyHistories, setPatientFamilyHistories } =
    usePatientFamilyHistoryStore()

  return useMutation<
    PatientFamilyHistory,
    unknown,
    { patientFamilyHistories: PatientFamilyHistory }
  >(
    async ({ patientFamilyHistories }) =>
      await addNewPatientFamilyHistory(patientFamilyHistories, getToken),
    {
      onSuccess: (data) => {
        setPatientFamilyHistories([
          ...(patientFamilyHistories as PatientFamilyHistory[]),
          data,
        ])
      },
    }
  )
}

export const useMutateDeletePatientFamilyHistory = (
  getToken: any
): UseMutationResult<
  PatientFamilyHistory,
  unknown,
  { patientFamilyHistoryId: number }
> => {
  const { patientFamilyHistories, setPatientFamilyHistories } =
    usePatientFamilyHistoryStore()

  return useMutation<
    PatientFamilyHistory,
    unknown,
    { patientFamilyHistoryId: number }
  >(
    async ({ patientFamilyHistoryId }) =>
      await deletePatientFamilyHistory(patientFamilyHistoryId, getToken),
    {
      onSuccess: (data) => {
        const newPatientFamilyHistories = patientFamilyHistories.filter(
          (history) => history?.id !== data?.id
        )
        setPatientFamilyHistories(newPatientFamilyHistories)
      },
    }
  )
}

export const useMutateUpdatePatientFamilyHistory = (
  getToken: any
): UseMutationResult<
  PatientFamilyHistory,
  unknown,
  {
    patientFamilyHistoryId: number;
    patientFamilyHistories: PatientFamilyHistory;
  }
> => {
  const { patientFamilyHistories, setPatientFamilyHistories } =
    usePatientFamilyHistoryStore()

  return useMutation<
    PatientFamilyHistory,
    unknown,
    {
      patientFamilyHistoryId: number;
      patientFamilyHistories: PatientFamilyHistory;
    }
  >(
    async ({ patientFamilyHistoryId, patientFamilyHistories }) =>
      await updatePatientFamilyHistory(
        patientFamilyHistoryId,
        patientFamilyHistories,
        getToken
      ),
    {
      onSuccess: (data) => {
        const newPatientFamilyHistories = patientFamilyHistories.map(
          (history) => {
            if (history?.id === data?.id) {
              return data
            }
            return history
          }
        )
        setPatientFamilyHistories(newPatientFamilyHistories)
      },
    }
  )
}
