import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type ChartTemplate } from '../types/ChartTemplate'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetChartTemplate = fetcher
  .path('/api/ChartTemplate/{chartTemplateId}')
  .method('get')
  .create()

export const getChartTemplate = async (
  chartTemplateId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetChartTemplate({ chartTemplateId })).data
  } catch (error) {
    if (error instanceof setupGetChartTemplate.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetChartTemplates = fetcher
  .path('/api/ChartTemplate')
  .method('get')
  .create()

export const getChartTemplates = async (getToken: any): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetChartTemplates({})).data
  } catch (error) {
    if (error instanceof setupGetChartTemplates.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createUser = fetcher
  .path('/api/ChartTemplate')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewChartTemplate = async (
  user: ChartTemplate,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await createUser(user)).data
  } catch (error) {
    if (error instanceof createUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeleteChartTemplate = fetcher
  .path('/api/ChartTemplate/{chartTemplateId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteChartTemplate = async (
  chartTemplateId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeleteChartTemplate({ chartTemplateId })
  } catch (error) {
    if (error instanceof setupGetChartTemplate.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateChartTemplate = fetcher
  .path('/api/ChartTemplate/{chartTemplateId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateChartTemplate = async (
  chartTemplateId: number,
  chartTemplate: ChartTemplate,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupUpdateChartTemplate({ chartTemplateId, ...chartTemplate })
  } catch (error) {
    if (error instanceof setupGetChartTemplate.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
