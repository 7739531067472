import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getRoom,
  getRooms,
  addNewRoom,
  deleteRoom,
  updateRoom,
} from '@/services'
import { type Room } from '@/types/Room'

interface AccountUserState {
  room: Room | null;
  rooms: Room[] | [];
  setRoom: (room: Room) => void;
  setRooms: (rooms: Room[]) => void;
}

export const useRoomStore = create<AccountUserState>()(
  devtools(
    persist(
      (set) => ({
        room: null,
        rooms: [],
        setRoom: (room: Room) => set({ room }),
        setRooms: (rooms: Room[]) => set({ rooms }),
      }),
      {
        name: 'room',
      }
    )
  )
)

export const useQueryGetRoom = (
  roomId: number,
  getToken: any
): QueryObserverResult<Room, unknown> => {
  const { setRoom } = useRoomStore()

  return useQuery<Room>(
    ['room', roomId],
    async () => await getRoom(roomId, getToken),
    {
      enabled: !!roomId,
      onSuccess: (data) => {
        setRoom(data)
      },
    }
  )
}

export const useQueryGetRooms = (
  getToken: any
): QueryObserverResult<Room[], unknown> => {
  const { setRooms } = useRoomStore()

  return useQuery<Room[]>(['rooms'], async () => await getRooms(getToken), {
    onSuccess: (data) => {
      setRooms(data)
    },
    initialData: undefined,
  })
}

export const useMutateAddNewRoom = (
  getToken: any
): UseMutationResult<Room[], unknown, { room: Room }> => {
  const { rooms, setRooms } = useRoomStore()

  return useMutation<Room[], unknown, { room: Room }>(
    async ({ room }) => await addNewRoom(room, getToken),
    {
      onSuccess: (data) => {
        setRooms([...(rooms as Room[]), data as Room])
      },
    }
  )
}

export const useMutateDeleteRoom = (
  getToken: any
): UseMutationResult<Room[], unknown, number> => {
  const { rooms, setRooms } = useRoomStore()

  return useMutation<Room[], unknown, number>(async (roomId: number) => {
    const data = await deleteRoom(roomId, getToken)
    const newRooms = rooms.filter((room: Room) => room?.id !== data?.id)
    setRooms(newRooms)
    return newRooms
  })
}

export const useMutateUpdateRoom = (
  getToken: any
): UseMutationResult<Room, unknown, { roomId: number; room: Room }> => {
  const { rooms, setRooms } = useRoomStore()

  return useMutation<Room, unknown, { roomId: number; room: Room }>(
    async ({ roomId, room }) => {
      const data = await updateRoom(roomId, room, getToken)
      const newRooms = rooms.map((r: Room) => (r.id === data?.id ? data : r))
      setRooms(newRooms)
      return data
    }
  )
}
