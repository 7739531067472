import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type OccMed } from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetOccMed = fetcher
  .path('/api/VisitOccMed/{vId}')
  .method('get')
  .create()

export const getOccMed = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetOccMed({ vId: visitId })).data
  } catch (error) {
    if (error instanceof setupGetOccMed.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupAddOccMed = fetcher
  .path('/api/VisitOccMed/{vId}')
  .method('put')
  .create({ 'api-version': undefined })

export const addOccMed = async (
  visitId: number,
  occMed: OccMed,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupAddOccMed({ vId: visitId, ...occMed })
  } catch (error) {
    if (error instanceof setupAddOccMed.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
