import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type Vital } from '@/types/Vital'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetVital = fetcher.path('/api/Vital/{vId}').method('get').create()

export const getVital = async (vId: number, getToken: any): Promise<Vital> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetVital({ vId })).data
  } catch (error) {
    if (error instanceof setupGetVital.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }

  return {} as Vital
}

const setupGetVitals = fetcher.path('/api/Vital').method('get').create()

export const getVitals = async (getToken: any): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetVitals({})).data
  } catch (error) {
    if (error instanceof setupGetVitals.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdateVital = fetcher
  .path('/api/Vital/{vId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updateVital = async (
  vId: number,
  chiefVital: Vital,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })

  await setupUpdateVital({ vId, ...chiefVital })
}
