import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations
const setupGetHealthGorillaLabsUrl = fetcher
  .path('/api/IFrameURL/VisitId/{visitId}')
  .method('get')
  .create()

export const getHealthGorillaLabsUrl = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetHealthGorillaLabsUrl({ visitId })).data
  } catch (error) {
    if (error instanceof setupGetHealthGorillaLabsUrl.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetHealthGorillaLabs = fetcher
  .path(
    '/api/HealthGorillaLabRequestList/PatientId/{patientId}/ExamDate/{examDate}'
  )
  .method('get')
  .create()

export const getHealthGorillaLabs = async (
  patientId: number,
  examDate: string,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    const response = await setupGetHealthGorillaLabs({ patientId, examDate })
    if (!response.data) {
      console.error('No data field in response:', response)
      return null
    }
    return response.data
  } catch (error) {
    if (error instanceof setupGetHealthGorillaLabs.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
