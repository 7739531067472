import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface VersionState {
  releaseVersion: string | undefined;
  setReleaseVersion: (releaseVersion: string) => void;
}

export const useReleaseVersionStore = create<VersionState>()(
  devtools(
    persist(
      (set) => ({
        releaseVersion: 'initial-release',
        setReleaseVersion: (releaseVersion: string) => set({ releaseVersion }),
      }),
      {
        name: 'release-version',
      }
    )
  )
)
