import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type ExternalOrder } from '@/types/ExternalOrder'
import { type ExternalOrderType } from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations
const setupGetVital = fetcher
  .path('/api/VisitExternalOrder/Visit/{visitId}')
  .method('get')
  .create()

export const getExternalOrder = async (
  visitId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetVital({ visitId })).data
  } catch (error) {
    if (error instanceof setupGetVital.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupGetExternalOrderTypes = fetcher
  .path('/api/ReferenceData/ExternalOrderType')
  .method('get')
  .create()

export const getExternalOrderTypes = async (
  getToken: any
): Promise<ExternalOrderType[]> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })

  return (await setupGetExternalOrderTypes({})).data as ExternalOrderType[]
}

const setupUpdateExternalOrders = fetcher
  .path('/api/VisitExternalOrder')
  .method('post')
  .create({ 'api-version': undefined })

export const updateExternalOrders = async (
  externalOrder: ExternalOrder,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupUpdateExternalOrders({ ...externalOrder })
  } catch (error) {
    if (error instanceof setupUpdateExternalOrders.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeleteExternalOrders = fetcher
  .path('/api/VisitExternalOrder/{visitExternalOrderId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteExternalOrders = async (
  visitExternalOrderId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeleteExternalOrders({ visitExternalOrderId })
  } catch (error) {
    if (error instanceof setupDeleteExternalOrders.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
