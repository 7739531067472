import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import { getROS, updateROS } from '../services'
import { type ReviewOfSystem } from '../types'

type VisitROSCallback = (prev: ReviewOfSystem[]) => ReviewOfSystem[];
interface ROSState {
  ros: ReviewOfSystem[] | [];
  setROS: (ros: ReviewOfSystem[] | VisitROSCallback) => void;
}

export const useROSStore = create<ROSState>()(
  devtools((set, get) => ({
    ros: [],
    setROS: (param: ReviewOfSystem[] | VisitROSCallback) => {
      const currentState = get().ros
      if (typeof param === 'function') {
        set({ ros: param(currentState) })
      } else {
        if (JSON.stringify(currentState) !== JSON.stringify(param)) {
          set({ ros: param })
        }
      }
    },
  }))
)

export const useQueryGetROS = (
  vId: number,
  getToken: any
): QueryObserverResult<ReviewOfSystem[], unknown> => {
  const { setROS } = useROSStore()
  return useQuery<ReviewOfSystem[]>(
    ['ros', vId],
    async () => await getROS(vId, getToken),
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setROS(data)
      },
      initialData: [],
    }
  )
}

export const useMutateUpdateROS = (
  getToken: any
): UseMutationResult<any, unknown, { vId: number; ros: ReviewOfSystem[] }> => {
  return useMutation<any, unknown, { vId: number; ros: ReviewOfSystem[] }>(
    async ({ vId, ros }) => await updateROS(vId, ros, getToken),
    {
      onSuccess: () => {},
      onError: (error) => {
        throw error
      },
    }
  )
}
