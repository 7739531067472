import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getChiefComplaint,
  updateChiefComplaint,
} from '@/services'
import { type ChiefComplaint } from '@/types'

interface ChiefComplaintState {
  chiefComplaint: ChiefComplaint | null;
  setChiefComplaint: (chiefComplaint: ChiefComplaint) => void;
}

export const useChiefComplaintStore = create<ChiefComplaintState>()(
  devtools(
    persist(
      (set) => ({
        chiefComplaint: null,
        setChiefComplaint: (chiefComplaint: ChiefComplaint) =>
          set({ chiefComplaint }),
      }),
      {
        name: 'chief-complaint',
      }
    )
  )
)

export const useQueryGetChiefComplaint = (
  vId: number,
  getToken: any
): QueryObserverResult<ChiefComplaint, unknown> => {
  const { setChiefComplaint } = useChiefComplaintStore()

  return useQuery<ChiefComplaint>(
    ['chief-complaint', vId],
    async () => await getChiefComplaint(vId, getToken),
    {
      enabled: !!vId,
      onSuccess: (data) => {
        setChiefComplaint(data)
      },
    }
  )
}

export const useMutateUpdateChiefComplaint = (
  getToken: any
): UseMutationResult<
  ChiefComplaint,
  unknown,
  { vId: number; chiefComplaint: ChiefComplaint }
> => {
  const { setChiefComplaint } = useChiefComplaintStore()

  return useMutation<
    ChiefComplaint,
    unknown,
    { vId: number; chiefComplaint: ChiefComplaint }
  >(
    async ({ vId, chiefComplaint }) =>
      await updateChiefComplaint(vId, chiefComplaint, getToken),
    {
      onSuccess: (data) => {
        setChiefComplaint(data)
      },
    }
  )
}
