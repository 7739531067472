import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export interface ToastMessage {
  message: string;
  status: 'success' | 'error' | 'info';
}

interface ToastState {
  pendingToasts: ToastMessage[];
  currentToast: ToastMessage | null;
  addToast: (newMessage: ToastMessage) => void;
  nextToast: () => void;
  clearMessages: () => void;
}

export const useToastStore = create<ToastState>()(
  devtools(
    persist(
      (set) => ({
        pendingToasts: [],
        currentToast: null,
        addToast: (newMessage: ToastMessage) =>
          set((state) => {
            if (state.currentToast) {
              return { pendingToasts: [...state.pendingToasts, newMessage] }
            }
            return { currentToast: newMessage }
          }),
        nextToast: () =>
          set((state) => {
            return {
              currentToast: state.pendingToasts[0] ?? null,
              pendingToasts: state.pendingToasts.slice(1),
            }
          }),
        clearMessages: () => set({ pendingToasts: [], currentToast: null }),
      }),
      {
        name: 'toast',
      }
    )
  )
)
