import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getPatientImmunization,
  addNewPatientImmunization,
  deletePatientImmunization,
  updatePatientImmunization,
} from '@/services'
import { type PatientImmunization } from '@/types/PatientImmunization'

interface PatientImmunizationState {
  patientImmunizations: PatientImmunization[] | [];
  setPatientImmunizations: (patientImmunization: PatientImmunization[]) => void;
}

export const usePatientImmunizationStore = create<PatientImmunizationState>()(
  devtools((set) => ({
    patientImmunizations: [],
    setPatientImmunizations: (immunization: PatientImmunization[]) =>
      set({ patientImmunizations: immunization }),
  }))
)

export const useQueryGetPatientImmunization = (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): QueryObserverResult<PatientImmunization[], unknown> => {
  const { setPatientImmunizations } = usePatientImmunizationStore()
  const key = visitId
    ? ['patient-immunization', patientId, visitId]
    : ['patient-immunization', patientId]
  return useQuery<PatientImmunization[]>(
    key,
    async () => await getPatientImmunization(patientId, getToken, visitId),
    {
      enabled: !!patientId,
      onSuccess: (data) => {
        setPatientImmunizations(data)
      },
      initialData: [],
    }
  )
}

export const useMutateAddPatientImmunization = (
  getToken: any
): UseMutationResult<
  PatientImmunization,
  unknown,
  { immunization: PatientImmunization }
> => {
  const { patientImmunizations, setPatientImmunizations } =
    usePatientImmunizationStore()

  return useMutation<
    PatientImmunization,
    unknown,
    { immunization: PatientImmunization }
  >(
    async ({ immunization }) =>
      await addNewPatientImmunization(immunization, getToken),
    {
      onSuccess: (data) => {
        setPatientImmunizations([
          ...(patientImmunizations as PatientImmunization[]),
          data,
        ])
      },
    }
  )
}

export const useMutateDeletePatientImmunization = (
  getToken: any
): UseMutationResult<
  PatientImmunization,
  unknown,
  { patientImmunizationId: number }
> => {
  const { patientImmunizations, setPatientImmunizations } =
    usePatientImmunizationStore()

  return useMutation<
    PatientImmunization,
    unknown,
    { patientImmunizationId: number }
  >(
    async ({ patientImmunizationId }) =>
      await deletePatientImmunization(patientImmunizationId, getToken),
    {
      onSuccess: (data) => {
        const newPatientImmunizations = patientImmunizations?.filter(
          (patientImmunization: PatientImmunization): boolean =>
            patientImmunization?.id !== data?.id
        )
        setPatientImmunizations(newPatientImmunizations)
      },
    }
  )
}

export const useMutateUpdatePatientImmunization = (
  getToken: any
): UseMutationResult<
  PatientImmunization,
  unknown,
  { patientImmunizationId: number; immunization: PatientImmunization }
> => {
  const { patientImmunizations, setPatientImmunizations } =
    usePatientImmunizationStore()

  return useMutation<
    PatientImmunization,
    unknown,
    { patientImmunizationId: number; immunization: PatientImmunization }
  >(
    async ({ patientImmunizationId, immunization }) =>
      await updatePatientImmunization(
        patientImmunizationId,
        immunization,
        getToken
      ),
    {
      onSuccess: (data) => {
        const newPatientImmunizations = patientImmunizations.map(
          (immunization: PatientImmunization): PatientImmunization => {
            if (immunization?.id === data?.id) {
              return data
            }
            return immunization
          }
        )
        setPatientImmunizations(newPatientImmunizations)
      },
    }
  )
}
