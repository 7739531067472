import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getAccountUser,
  getAccountUsers,
  addNewUser,
  deleteAccountUser,
  updateAccountUser,
  updateAccountUserSelf,
} from '@/services'
import { type AccountUser } from '@/types/AccountUser'

interface AccountUserState {
  accountUser: AccountUser | null;
  accountUsers: AccountUser[] | [];
  setAccountUser: (accountUser: AccountUser) => void;
  setAccountUsers: (accountUsers: AccountUser[]) => void;
}

export const useAccountUserStore = create<AccountUserState>()(
  devtools(
    persist(
      (set) => ({
        accountUser: null,
        accountUsers: [],
        setAccountUser: (user: AccountUser) => set({ accountUser: user }),
        setAccountUsers: (users: AccountUser[]) => set({ accountUsers: users }),
      }),
      {
        name: 'account-user',
      }
    )
  )
)

export const useQueryGetAccountUser = (
  accountUserId: number,
  getToken: any
): QueryObserverResult<AccountUser, unknown> => {
  const { setAccountUser } = useAccountUserStore()

  return useQuery<AccountUser>(
    ['account-user', accountUserId],
    async () => await getAccountUser(accountUserId, getToken),
    {
      enabled: !!accountUserId,
      onSuccess: (data) => {
        setAccountUser(data)
      },
    }
  )
}

export const useQueryGetAccountUsers = (
  getToken: any
): QueryObserverResult<AccountUser[], unknown> => {
  const { setAccountUsers } = useAccountUserStore()

  return useQuery<AccountUser[]>(
    ['account-users'],
    async () => await getAccountUsers(getToken),
    {
      onSuccess: (data) => {
        setAccountUsers(data)
      },
      initialData: undefined,
    }
  )
}

export const useMutateAddAccountUser = (
  getToken: any
): UseMutationResult<AccountUser, unknown, { user: AccountUser }> => {
  const { accountUsers, setAccountUsers } = useAccountUserStore()

  return useMutation<AccountUser, unknown, { user: AccountUser }>(
    async ({ user }) => await addNewUser(user, getToken),
    {
      onSuccess: (data) => {
        setAccountUsers([...(accountUsers as AccountUser[]), data])
      },
    }
  )
}

export const useMutateDeleteAccountUser = (
  getToken: any
): UseMutationResult<AccountUser, unknown, { accountUserId: number }> => {
  const { accountUsers, setAccountUsers } = useAccountUserStore()

  return useMutation<AccountUser, unknown, { accountUserId: number }>(
    async ({ accountUserId }) =>
      await deleteAccountUser(accountUserId, getToken),
    {
      onSuccess: (data) => {
        const newAccountUsers = accountUsers.filter(
          (accountUser: AccountUser): boolean => accountUser?.id !== data?.id
        )
        setAccountUsers(newAccountUsers)
      },
    }
  )
}

export const useMutateUpdateAccountUser = (
  getToken: any
): UseMutationResult<
  AccountUser,
  unknown,
  { accountUserId: number; user: AccountUser }
> => {
  const { accountUsers, setAccountUsers } = useAccountUserStore()

  return useMutation<
    AccountUser,
    unknown,
    { accountUserId: number; user: AccountUser }
  >(
    async ({ accountUserId, user }) =>
      await updateAccountUser(accountUserId, user, getToken),
    {
      onSuccess: (data) => {
        const newAccountUsers = accountUsers.map(
          (accountUser: AccountUser): AccountUser => {
            if (accountUser?.id === data?.id) {
              return data
            }
            return accountUser
          }
        )
        setAccountUsers(newAccountUsers)
      },
      onError: (error) => {
        throw error
      },
    }
  )
}
export const useMutateUpdateAccountUserSelf = (
  getToken: any
): UseMutationResult<
  AccountUser,
  unknown,
  { accountUserId: number; user: AccountUser }
> => {
  return useMutation<
    AccountUser,
    unknown,
    { accountUserId: number; user: AccountUser }
  >(
    async ({ accountUserId, user }) =>
      await updateAccountUserSelf(
        accountUserId,
        [{ value: user.password, op: 'replace', path: '/Password' }],
        getToken
      ),
    {
      onError: (error) => {
        throw error
      },
    }
  )
}
