import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

const fetcher = Fetcher.for<paths>()

const setupGetStates = fetcher
  .path('/api/RolePermission/Role')
  .method('get')
  .create()

export const getRolePermissions = async (getToken: any): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetStates({})).data
  } catch (error) {
    if (error instanceof setupGetStates.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
