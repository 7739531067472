import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type ReviewOfSystem } from '../types/ReviewOfSystems'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations
const setupGetVital = fetcher
  .path('/api/Visit/{vId}/VisitRosSystem')
  .method('get')
  .create()

export const getROS = async (vId: number, getToken: any): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetVital({ vId })).data
  } catch (error) {
    if (error instanceof setupGetVital.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

export const updateROS = async (
  vId: number,
  hpi: ReviewOfSystem[],
  getToken: any
): Promise<any> => {
  const url = `${process.env.API_URL ?? ''}/api/Visit/${vId}/VisitRosSystem`
  await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${
        (await getToken({ template: 'UrgentIQ' })) as string
      }`,
    },
    body: JSON.stringify(hpi),
  })
  return hpi
}
