import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type PVerifyInsurance } from '@/types'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetInsurancePayers = fetcher
  .path('/api/PVerifyInsurance')
  .method('get')
  .create()

export const getInsurancePayers = async (getToken: any): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetInsurancePayers({})).data
  } catch (error) {
    if (error instanceof setupGetInsurancePayers.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createPVerifyInsurance = fetcher
  .path('/api/PVerifyInsurance')
  .method('post')
  .create({ 'api-version': undefined })
const createAccountToInsurance = fetcher
  .path('/api/AccountToInsurance')
  .method('post')
  .create({ 'api-version': undefined })
const updatePVerifyInsurance = fetcher
  .path('/api/PVerifyInsurance')
  .method('put')
  .create({ 'api-version': undefined })
const updateAccountToInsurance = fetcher
  .path('/api/AccountToInsurance')
  .method('put')
  .create({ 'api-version': undefined })
const getAccountToInsurance = fetcher
  .path('/api/AccountToInsurance/{pVerifyInsuranceId}')
  .method('get')
  .create()

export const addNewInsuranceEntity = async (
  payer: PVerifyInsurance,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await createPVerifyInsurance(payer)).data
  } catch (error) {
    if (error instanceof createPVerifyInsurance.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

export const addNewInsurancePayer = async (
  pVerifyInsuranceId: number,
  globalFeeEnabled: boolean | undefined,
  inNetwork: boolean | undefined,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (
      await createAccountToInsurance({
        pVerifyInsuranceId,
        globalFeeEnabled,
        inNetwork,
      })
    ).data
  } catch (error) {
    if (error instanceof createPVerifyInsurance.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeleteInsurancePayer = fetcher
  .path('/api/AccountToInsurance/{pVerifyInsuranceId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deleteInsurancePayer = async (
  pVerifyInsuranceId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeleteInsurancePayer({ pVerifyInsuranceId })
  } catch (error) {
    if (error instanceof setupGetInsurancePayers.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

export const updateInsuranceEntity = async (
  pVerifyInsuranceId: number,
  insurancePayer: PVerifyInsurance,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await updatePVerifyInsurance({ ...insurancePayer, id: pVerifyInsuranceId })
  } catch (error) {
    if (error instanceof setupGetInsurancePayers.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

export const updateInsurancePayer = async (
  pVerifyInsuranceId: number,
  globalFeeEnabled: boolean | undefined,
  inNetwork: boolean | undefined,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    const id = await (
      await getAccountToInsurance({ pVerifyInsuranceId })
    ).data.id
    if (id) {
      await updateAccountToInsurance({
        id,
        pVerifyInsuranceId,
        globalFeeEnabled,
        inNetwork,
      })
    }
  } catch (error) {
    if (error instanceof setupGetInsurancePayers.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
