import { getPatient } from '@/services'
import {
  type AccountUser,
  type ExamNode,
  type Examination,
  type Patient,
  type RosSymptom,
  type RosSystem,
  type DateOnly,
  type DayOfWeek,
  type Medication,
  type PatientMedication,
} from '@/types'
import { differenceInDays, differenceInMonths, format } from 'date-fns'
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input'

export const isEmpty = (value: any): boolean => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (Array.isArray(value) && value.length === 0)
  )
}

export const formatGender = (gender: string): string => {
  switch (gender) {
    case 'Male':
      return 'M'
    case 'Female':
      return 'F'
    case 'NoResponse':
      return 'NR'
    default:
      return ''
  }
}

export const getFullName = (
  account: AccountUser | Patient | undefined
): string | null => {
  if (!account) {
    return null
  }
  const { firstName = '', lastName = '' } = account
  return `${firstName ?? ''} ${lastName ?? ''}`
}

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match !== null) {
    const intlCode = match[1] === null ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return ''
}

export const formatPhoneNumberForIntake = (
  phoneNumberString: string
): string => {
  const cleaned = ('' + phoneNumberString)
    .replace(/x\d*/g, '')
    .replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match !== null) {
    return ['+1', match[2], match[3], match[4]].join('')
  }
  return ''
}

export const formatAge = (date: DateOnly | undefined): string => {
  if (!isDateOnlyValid(date)) return ''

  const diffMs =
    Date.now() -
    new Date(
      date?.year ?? 2000,
      (date?.month ?? 2) - 1 ?? 2,
      date?.day
    ).getTime()
  const ageDt = new Date(diffMs)

  return `${Math.abs(ageDt.getUTCFullYear() - 1970)}y`
}

export const isInputValid = (phoneNumber: string | undefined): boolean =>
  isEmpty(phoneNumber) ||
  (isPossiblePhoneNumber(phoneNumber ?? '') &&
    isValidPhoneNumber(phoneNumber ?? ''))

export const convertToSpaceSeparated = (str: string): string => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const clearIndexedDB = async (dbName: string): Promise<void> => {
  try {
    await indexedDB.deleteDatabase(dbName)
    console.log('IndexedDB data cleared successfully.')
  } catch (error) {
    console.error('Error clearing IndexedDB data:', error)
  }
}

export const convertToTree = (data: Examination[]): ExamNode[] => {
  const tree: any = []
  const nodeMap = new Map()

  const newData = data.sort((prev, next) => (prev.id ?? 0) - (next.id ?? 0))

  newData.forEach((node) => {
    nodeMap.set(node.id, {
      name: node.name,
      id: node.id,
      children: [],
      normalDescription: node.normalDescription,
      abnormalDescription: node.abnormalDescription,
    })
  })

  newData.forEach((node) => {
    const parentNode = nodeMap.get(node.parentId)
    if (parentNode) {
      parentNode.children.push(nodeMap.get(node.id))
    } else {
      tree.push(nodeMap.get(node.id))
    }
  })

  return tree
}

export const convertToRoSTree = (
  systems: RosSystem[],
  components: RosSymptom[]
): ExamNode[] => {
  const tree: any = []
  const nodeMap = new Map()

  const sortedSystems = systems.sort(
    (prev, next) => (prev.id ?? 0) - (next.id ?? 0)
  )
  const sortedComponents = components.sort((prev, next) =>
    (prev.name ?? '').localeCompare(next.name ?? '')
  )

  sortedSystems.forEach((node) => {
    nodeMap.set(-(node.id ?? 0), {
      name: node.name,
      id: -(node.id ?? 0),
      children: [],
    })
    tree.push(nodeMap.get(-(node.id ?? 0)))
  })

  sortedComponents.forEach((node) => {
    nodeMap.set(node.id, { name: node.name, id: node.id, children: [] })
    const parentNode = nodeMap.get(-(node.rosSystemId ?? 0))
    if (parentNode) {
      parentNode.children.push(nodeMap.get(node.id))
    } else {
      tree.push(nodeMap.get(node.id))
    }
  })
  return tree
}

export const stringToColor = (str: string): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const r = (hash & 0xff0000) >> 16
  const g = (hash & 0x00ff00) >> 8
  const b = hash & 0x0000ff
  return `rgb(${r}, ${g}, ${b})`
}

export const parseError = (error: unknown, section: string): string => {
  // Again, perform type checking or type assertion here
  let errorMessage = `Error updating ${section}`
  if (error instanceof Error) {
    errorMessage += `: ${error.message}`
  } else if (typeof error === 'string') {
    errorMessage += `: ${error}`
  } else {
    errorMessage += `: ${JSON.stringify(error)}`
  }
  console.error(errorMessage)
  return errorMessage
}

export const getSexId = (gender: string): number | undefined => {
  switch (gender) {
    case 'Male':
      return 1
    case 'Female':
      return 2
    case 'Other':
      return 3
    case 'No Response':
      return 4
  }
}

export const getGenderBySexId = (sexId: number): string => {
  switch (sexId) {
    case 1:
      return 'Male'
    case 2:
      return 'Female'
    case 3:
      return 'Other'
    case 4:
      return 'No Response'
    default:
      return 'Male'
  }
}

export const parseTableDataFromAppointment = async (
  data: any,
  getToken: any,
  isDashboard: boolean = false
): Promise<any> => {
  if (!data) {
    return []
  }
  const promises = data.map(async (item: any, index: number) => {
    const {
      id,
      firstName,
      lastName,
      dateOfBirth,
      patientId,
      appointmentStartDate,
      appointmentEndDate,
      phone,
      email,
      statusString,
      status,
      notes,
    } = item
    let patientDetails: PatientDetails = {}
    if (isDashboard) {
      try {
        if (!patientId) {
          console.error(`Patient ID is missing for item ${index}`)
        } else {
          patientDetails = await getPatient(patientId, getToken)
        }
      } catch (error) {
        console.error(
          `Error fetching patient details for item ${index}:`,
          error
        )
      }
    }
    const patientFirstName = firstName ?? patientDetails?.firstName
    const patientLastName = lastName ?? patientDetails?.lastName
    const patientDateOfBirth = dateOfBirth ?? patientDetails?.dateOfBirth
    return {
      id,
      patientId,
      patientDetails: {
        firstName: patientFirstName,
        lastName: patientLastName,
        dateOfBirth: patientDateOfBirth,
        age: formatAge(patientDateOfBirth),
      },
      appointmentStartDate,
      appointmentEndDate,
      providerId: item.providerId,
      phone,
      email,
      statusString,
      status,
      notes,
      isClinicAppointment: 'visitTypeId' in item,
    }
  })
  return await Promise.all(promises)
}

interface PatientDetails {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: DateOnly | null;
}

export const parseDateToDateOnly = (date: Date): DateOnly => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1, // JavaScript months are 0-indexed
    day: date.getDate(),
    dayOfWeek: date.getDay() as DayOfWeek, // Assuming DayOfWeek is compatible with Date.getDay() 0 (Sunday) through 6 (Saturday)
    dayOfYear: Math.floor(
      (date.getTime() - new Date(date.getFullYear(), 0, 0).getTime()) /
        1000 /
        60 /
        60 /
        24
    ),
    dayNumber: undefined, // This field's purpose is unclear without further context
  }
}

export const dateOnlyToDate = (
  dateOnly: DateOnly | undefined | null
): Date | undefined => {
  if (dateOnly === undefined || dateOnly === null) {
    return undefined
  } else if (
    dateOnly.year === undefined ||
    dateOnly.month === undefined ||
    dateOnly.day === undefined
  ) {
    // Ensure year, month, and day are provided. If not, return a default date or throw an error.
    throw new Error('Year, month, and day are required to create a date.')
  } else {
    // JavaScript months are 0-indexed, so subtract 1.
    return new Date(dateOnly.year, dateOnly.month - 1, dateOnly.day)
  }
}

export const isDateOnlyValid = (
  dateOnly:
  DateOnly |
  undefined |
  null |
  string |
  number
): boolean => {
  if (
    dateOnly === undefined ||
    dateOnly === null ||
    typeof dateOnly === 'string' ||
    typeof dateOnly === 'number'
  ) {
    return false
  }
  try {
    dateOnlyToDate(dateOnly)
    return true
  } catch (error) {
    return false
  }
}

export const formatDateOnly = (dateOnly: DateOnly | undefined): string => {
  if (!isDateOnlyValid(dateOnly)) {
    return ''
  } else {
    return format(dateOnlyToDate(dateOnly)!, 'MM/dd/yyyy')
  }
}

export const getDoseForm = (med: Medication): string => {
  const matches = med?.lexiProductNameRouteDoseForm?.match(/\((.*?)\)/)
  return matches ? matches[1] : ''
}

export const toTitleCase = (str: string): string => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const generateMedicationId = (medication: PatientMedication): number => {
  return (
    (medication.dispensableDrugId ?? 0)
  )
}

export const formatAgeWithRules = (
  dateOfBirth: DateOnly | undefined
): string => {
  if (!dateOfBirth || !isDateOnlyValid(dateOfBirth)) return ''

  const birthDate = dateOnlyToDate(dateOfBirth)
  if (!birthDate) return ''

  const today = new Date()
  const daysOld = differenceInDays(today, birthDate)
  const monthsOld = differenceInMonths(today, birthDate)
  let yearsOld = today.getFullYear() - birthDate.getFullYear()

  if (
    birthDate.getMonth() > today.getMonth() ||
    (birthDate.getMonth() === today.getMonth() &&
      birthDate.getDate() > today.getDate())
  ) {
    yearsOld--
  }

  if (daysOld < 30) {
    return `${daysOld} day${daysOld !== 1 ? 's' : ''}`
  } else if (monthsOld < 24) {
    return `${monthsOld} month${monthsOld !== 1 ? 's' : ''}`
  } else {
    return `${yearsOld} year${yearsOld !== 1 ? 's' : ''}`
  }
}

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text
  return text.slice(0, maxLength) + '...'
}
