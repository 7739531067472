import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'
import { type PatientAllergy } from '../types/PatientAllergy'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations

const setupGetPatientAllergy = fetcher
  .path('/api/PatientAllergy/Patient/{patientId}')
  .method('get')
  .create()

export const getPatientAllergy = async (
  patientId: number,
  getToken: any,
  visitId?: number | undefined
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await setupGetPatientAllergy({ patientId, visitId })).data
  } catch (error) {
    if (error instanceof setupGetPatientAllergy.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const createUser = fetcher
  .path('/api/PatientAllergy')
  .method('post')
  .create({ 'api-version': undefined })

export const addNewPatientAllergy = async (
  user: PatientAllergy,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    return (await createUser(user)).data
  } catch (error) {
    if (error instanceof createUser.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupDeletePatientAllergy = fetcher
  .path('/api/PatientAllergy/{patientAllergyId}')
  .method('delete')
  .create({ 'api-version': undefined })

export const deletePatientAllergy = async (
  patientAllergyId: number,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  try {
    await setupDeletePatientAllergy({ patientAllergyId })
  } catch (error) {
    if (error instanceof setupGetPatientAllergy.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

const setupUpdatePatientAllergy = fetcher
  .path('/api/PatientAllergy/{patientAllergyId}')
  .method('put')
  .create({ 'api-version': undefined })

export const updatePatientAllergy = async (
  patientAllergyId: number,
  patientAllergy: PatientAllergy,
  getToken: any
): Promise<any> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({ template: 'UrgentIQ' })) as string
        }`,
      },
    },
  })
  await setupUpdatePatientAllergy({ patientAllergyId, ...patientAllergy })
}
