import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import { getInsuranceEligibility } from '@/services'
import { type Insurance } from '@/types/Insurance'

interface InsuranceState {
  insuranceEligibility: any | null;
  setInsuranceEligibility: (insuranceEligibility: any) => void;
}

export const useInsuranceStore = create<InsuranceState>()(
  devtools((set) => ({
    insuranceEligibility: null,
    setInsuranceEligibility: (eligibility: any) =>
      set({ insuranceEligibility: eligibility }),
  }))
)

export const useQueryGetInsuranceEligibility = (
  insurance: Insurance,
  getToken: any
): QueryObserverResult<any, unknown> => {
  const { setInsuranceEligibility } = useInsuranceStore()

  return useQuery<any>(
    ['insurance-eligibility'],
    async () => await getInsuranceEligibility(insurance, getToken),
    {
      onSuccess: (data) => {
        setInsuranceEligibility(data)
      },
    }
  )
}
